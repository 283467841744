// Load dependencies
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

// Load styles
import { defaults } from './../../components/styles';

export default class YAPrivacyPolicy extends Component {

  // Styles for the feed alert page
  static styles = {
    branding: {
      textAlign: 'center',
      logo: {
        width: 280,
        margin: `0 auto ${defaults.gutter}px auto`
      }
    },
    push: {
      marginBottom: `${(defaults.gutter / 2)}px`
    },
    listItem: {
      marginBottom: `${(defaults.gutter / 5)}px`
    },
    scroller: {
      overflow: 'auto'
    },
    wrapper: {
      height: '100%',
      flex: 1,
      paddingTop: `${defaults.gutter}px`,
      paddingBottom: `${defaults.gutter}px`,
      overflow: 'auto'
    }
  };

  /**
   * Handle the logic that should run before the component is completely mounted.
   */
  componentWillMount() {

    // Hide the feedback button on the privacy policy screen
    const feedbackInterval = setInterval(() => {
      if (window.UserSnap) {
        window.UserSnap.toggleButton(false);
        clearInterval(feedbackInterval);
      }
    }, 25);
  }

  /**
   * Handle the logic that should run before the component is completely unmounted.
   */
  componentWillUnmount() {

    // Show the feedback button again when the user navigates away
    if (window.UserSnap) {
      window.UserSnap.toggleButton(true);
    }
  }

  /**
   * Render the static privacy policy.
   *
   * @return {object} Node that should be rendered
   */
  render() {
    return (
      <div className="canvas">
        <div style={{ overflow: 'auto' }}>
          <header className="text-page-header">
            <NavLink
              to="/"
              title="Back to YMCA Administration Portal"
            >
              <img
                src={`${window.__configuration.s3}/logos/logo.png`}
                alt="Point B - You are here."
              />
            </NavLink>
          </header>

          <div className="container text-page">

            {/*  Privacy Policy */}
            <h1
              style={YAPrivacyPolicy.styles.push}
            >
              Privacy Notice
            </h1>

            <p>
              Your personal data may be transferred to, processed and stored outside Canada. It may be accessed by law enforcement and national security authorities in those jurisdictions.
            </p>

            <hr />

            {/*  Terms of Use */}
            <h1
              style={YAPrivacyPolicy.styles.push}
            >
              Terms of Use
            </h1>

            <ol>
              <li style={YAPrivacyPolicy.styles.listItem}>
                This software was developed by the YMCA of Hamilton|Burlington|Brantford and is used under license by other independently operated members of the
                Canadian YMCA Federation (collectively the “YMCA”).
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                The YMCA has done its best to make this software a great application to use, but cannot guarantee that it will always be
                accessible or operate as expected. This software is provided by the YMCA on an “as is” basis without any technical support or
                warranty of any kind. In no event shall the YMCA be liable for any direct or indirect damages, including but not limited to any loss of data,
                invasion of privacy or computer failure, arising out of the use or inability to use the software.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                The license granted to you to download and use the software is non-exclusive, non-transferable and subject to the laws of Canada.
                The YMCA reserves the right to terminate licenses which are subject to abuse. We ask you to respect the terms of the license by not sharing the license key that was issued to you.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                You may not redistribute or modify the software without permission from the YMCA of Hamilton|Burlington|Brantford which retains all proprietary rights over the software, including copyright in the software, database structure and user interfaces. No third-party or agency is entitled to sell, modify or repackage the software without its prior consent and written permission.
                The YMCA responsible for operating the Immigrant Services program you attend shall be the sole owner of all the data collected by the software and recorded in your account.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                During registration, you will be required to select a password for your account. The password is used to protect the privacy of your account and should not be shared with others.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                You agree to provide truthful and complete information to the YMCA at all times. All communication and documentation recorded associated with your account will be protected by YMCA as confidential (subject to exceptions discussed below). You may update the personal information associated with your account or close the account at any time. The YMCA reserves the right to close accounts which are dormant for more than one year.
                When an account is closed, all material recorded in the account may be removed and permanently destroyed by on regulations of the YMCA.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                You acknowledge that translation services within the app are provided by Google or an alternate third party. The YMCA cannot be held responsible for third party translations of the original text.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                You agree to indemnify and hold harmless the YMCA from any and all claims resulting from your use of the software.
              </li>
            </ol>

            <hr />

            {/* Privacy Policy */}
            <h1
              style={YAPrivacyPolicy.styles.push}
            >
              Privacy
            </h1>

            <ol>
              <li style={YAPrivacyPolicy.styles.listItem}>
                We take your privacy seriously and, when you provide the YMCA with your personal information,
                we endeavour to keep it safe. We will not share your personal information with third parties unless compelled to do so by law and will take all reasonable precautions to protect it from
                inadvertent disclosure.
                YMCA and its suppliers will only use your personal information for purposes related to the delivery of the Immigrant Services program services to you.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                We use trusted suppliers to host and manage the database which supports the operation of this software. You can find more information respecting their privacy policies and security measures via these hyperlinks: { }
                <a
                  href="https://aws.amazon.com/security/"
                  target="_blank"
                  title="Amazon Privacy Policy"
                >
                  https://aws.amazon.com/security/
                </a>
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                The YMCA and its suppliers may track user’s activity when using the software. This information is anonymous and provides information that will assist the YMCA to correct errors and develop improvements for the software.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                You may not attempt to, nor assist or encourage others to, circumvent, disable, defeat, reverse engineer, decompile or tamper with any of the security features or components of the software for any reason.
                You may not access or attempt to access any account that you are not authorized to access.
                Security violations and the unauthorized access of private accounts may result in civil or criminal liability.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                You agree to promptly notify the YMCA of any misuse, misappropriation, unauthorized disclosure, display or copying of personal data or the software that come to your attention.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                You have a right to challenge the accuracy and completeness of your personal information and to have it amended, as appropriate. You also have a right to request access to your personal information and receive an accounting of how that information has been used and disclosed, subject to certain exceptions prescribed by law.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                If your personal information changes or you no longer wish to use this software, you may correct, update or delete/deactivate your account through your profile settings in the software. In the alternative, this can be done by contacting the Privacy Officer appointed by the YMCA for your program at kyla.kumar@ymcahbb.ca. We will respond within forty-eight (48) hours of receipt of your written request.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                The YMCA will also respond to inquiries about its policies and practices relating to the handling of your personal information. Inquiries should be directed to the Privacy Officer appointed by the YMCA for your program at kyla.kumar@ymcahbb.ca or by using the contact information provided below. We will investigate all complaints and will respond within 10 days of receipt of a written inquiry. If the complaint is found to be justified, we will take appropriate measures to resolve it, including, if necessary, amending our practices
                <br /><br />
                <dl>
                  <dt>
                    Contact:
                  </dt>
                  <dd>
                    Privacy Officer<br />
                    YMCA of Hamilton/Burlington/Brantford<br />
                    79 James Street, South<br />
                    Hamilton, ON<br />
                    L8P 2Z1<br /><br />
                    or<br /><br />
                    905.529.7102 x 7311
                  </dd>
                </dl>
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                To ensure superior functionality at a reasonable cost, your personal data may be transferred to, processed and stored in data centers located outside Canada. This means that it may be accessed by law enforcement and national security authorities in those jurisdictions. This may sometimes occur without our knowledge or prior consent. If you do not indicate your consent below, we regret that the YMCA cannot make this software available to you.
              </li>

              <li style={YAPrivacyPolicy.styles.listItem}>
                When we receive positive feedback from our users, we like to share it with others, and may use comments we’ve received by email, Facebook, Twitter or other communication mediums for the promotion of this software and the YMCA. The YMCA will never show your email address, will never use your comments out of context and will respect your privacy by disclosing only your first name in association with the feedback.
              </li>
            </ol>

            <hr />

            {/* Privacy Policy Agreement */}
            <h1
              style={YAPrivacyPolicy.styles.push}
            >
              Agreement
            </h1>

            <p>
              By using this software, you agree that you have read, understood and will comply with these terms and conditions.
            </p>
          </div>

          <div className="container text-page">
            <div className="row">
              <div className="col-md-offset-4 col-md-4">
                <img
                  src={`${window.__configuration.s3}/logos/IRCC_Funded_banner.png`}
                  alt="Point B - You are here."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
