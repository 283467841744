/* global __CONTENT__ */

// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, Map } from 'immutable';
import moment from 'moment';
import Radium from 'radium';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

import _ from 'lodash';

// Load custom components
import YAButton from './../../components/form/button';
import YATable from './../../components/table/table';
import YALoader from './../../components/loader/loader';
import YAInput from './../../components/form/input';

import {
  GET_ACTIVITIES,
  UPDATE_ACTIVITIES,
} from './../../actions/activity';

// Load selectors
import { getActivities, areActivitiesReady } from './../../selectors/activity';
import { getProfile } from './../../selectors/authenticator';

class YAActivities extends Component {
  static propTypes = {
    getActivities: PropTypes.func.isRequired,
    updateActivities: PropTypes.func.isRequired,
    ready: PropTypes.bool,
    history: PropTypes.object.isRequired,
    activities: PropTypes.instanceOf(Map).isRequired,
    profile: PropTypes.instanceOf(Map).isRequired,
  };

  static styles = {
    center: {
      textAlign: 'center'
    },
  };
  /**
  * Determines which items the container needs from the store for rendering this
  * page.  These items are injected into the properties of the page.
  */
  static mapStateToProps(state) {
    return {
      activities: getActivities(state),
      ready: areActivitiesReady(state),
      profile: getProfile(state)

    };
  }

  /**
  * Determines which actions this page requires and those action dispatchers will
  * be injected into the properties of the page.
  */
  static mapDispatchToProps(dispatch) {
    return {
      getActivities: options => dispatch({ type: GET_ACTIVITIES, ...options }),
      updateActivities: options => dispatch({ type: UPDATE_ACTIVITIES, ...options }),
    };
  }

  state = {
    search: '',
    toArchive: [],
    toActivate: [],
    disabled: true,
    sortBy: null,
    sort: false,
    page: 0
  };

  /**
  * Loads in the initial list of activity. Redirects to dashboard if request fails.
  */

  componentDidMount() {
    this.props.getActivities();
  }

  handleBulkUpdate(id, deleted) {
    let disable;
    if (deleted === null) {
      let tagsList = this.state.toArchive;
      if (tagsList.indexOf(id) !== -1 && this.state.toActivate.length === 0) {
        tagsList = tagsList.filter(removeFromList => removeFromList !== id);
        disable = true;
      }
      else {
        if (this.state.toActivate.length === 0) {
          tagsList.push(id);
          disable = false;
        }

      }
      this.setState({
        toArchive: tagsList,
        disabled: disable
      });
    }

    else {
      let tagsList = this.state.toActivate;
      if (tagsList.indexOf(id) !== -1 && this.state.toArchive.length === 0) {
        tagsList = tagsList.filter(removeFromList => removeFromList !== id);
        disable = true;
      }
      else {
        if (this.state.toArchive.length === 0) {
          tagsList.push(id);
          disable = false;
        }
      }
      this.setState({
        toActivate: tagsList,
        disabled: disable
      });
    }
  }

  /**
  * Sets the given property to the state with the value that is passed from the
  * input box change event.
  *
  * @param {string} property
  * @param {object} event
  */
  handleChange(property, event) {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      // If running on an iOS device we will have to make sure that the input is
      // refocused.  For some reason iOS browsers have a bug with persisting changes
      // to the actual input values.
      event.persist();
    }
    this.setState(
      {
        [property]: event.target.value
      },
      () => {
        if (iOS) {
          event.target.focus();
        }
      }
    );
  }

  /**
   * Link to the activity currently stored at the given index in the list of posts
   * loaded in the properties.
   *
   * @param {number} index
   */
  handleUpdateActivityClick(index) {
    this.props.history.push(`/tag?updating=${index}`); //this doesnt lead anywhere yet!
  }

  handleAddActivity() {
    //redirect to page to create new activity
    this.props.history.push(`/tag`);
  }

  /**
   * Refetches items based on the search input after more than 3 characters have been entered
   */
  handleSearch(event = { target: {} }) {
    this.setState({ search: event.target.value });
    if (!event.target.value) {
      this.props.getActivities();
      return;
    }
    if (event.target.value.length >= 3) {
      const searchOptions = { name: event.target.value };
      this.props.getActivities(searchOptions);
    }
  }

  handleArchive() {
    const r = window.confirm("Are you sure you want to update the selected tags?");
    if (this.state.toActivate.length === 0) {
      if (r == true) {
        this.props.updateActivities({ ids: this.state.toArchive });
      }
      //clear the list here
      this.setState({
        toArchive: []
      });
    }
    else {
      if (r == true) {
        this.props.updateActivities({ ids: this.state.toActivate, activate: true });
      }
      this.setState({
        toActivate: []
      });
    }
  }

  /**
  * Paginates the list of news with the page passed from the pagination
  * widget.  Reloads the list of news.
  */
  paginate({ selected }) {
    // Calculate the page selected
    const page = selected + 1;
    this.setState({
      page: selected
    });
    // Set the filters
    const options = this.state.queryOptions || {};
    if (this.state.search) {
      options.search = this.state.search;
    }
    if (this.state.sort && this.state.sortBy) {
      options.orderBy = this.state.sortBy + " DESC";
    }
    if (!this.state.sort && this.state.sortBy) {
      options.orderBy = this.state.sortBy + " ASC";
    }
    return this.props.getActivities({
      ...options,
      page
    });
  }

  handleSortClick(event, order) {
    this.setState({
      sort: !this.state.sort,
      sortBy: order,
      page: 0
    });
    if (!this.state.sort) {
      this.props.getActivities({ orderBy: order + " DESC", page: 1 });
    }
    if (this.state.sort) {
      this.props.getActivities({ orderBy: order + " ASC", page: 1 });
    }
  }
  /**
  * Returns the header list that should be passed to the table component.
  */
  getHeader() {
    return List([
      // Render the header column to create a new parent
      (this.props.profile.get("type") === 'SA' ? <span key={1} /> : ''),

      <span key={2} onClick={() => this.handleSortClick(this, "name")}>Name
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretDown : faCaretUp}
          style={{ fontSize: 16, marginRight: 5 }} />
      </span>,

      <span key={3} onClick={() => this.handleSortClick(this, "description")}>Description
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretDown : faCaretUp}
          style={{ fontSize: 16, marginRight: 5 }} /></span>,
      <span key={4} onClick={() => this.handleSortClick(this, "deletedAt")}>Status
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretDown : faCaretUp}
          style={{ fontSize: 16, marginRight: 5 }} /></span>,

      <span key={5}>Created By</span>,

      <span style={{ textAlign: 'center' }} key={5} onClick={() => this.handleSortClick(this, "createdAt")}>
        Created At
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretDown : faCaretUp}
          style={{ fontSize: 16, marginRight: 5 }} />
      </span>,
    ]);
  }

  /**
  * Gets the body of the table by parsing the currently loaded activity and
  * mapping them to a list of column contents to pass through.
  */
  getBody() {

    return this.props.activities.get('results').map(activity => [
      // Render the name of the resource
      (this.props.profile.get("type") === 'SA' ? <span key={1} >

        <YAInput
          checked={this.state.toArchive.indexOf(activity.get('id')) !== -1 || this.state.toActivate.indexOf(activity.get('id')) !== -1}
          //checked={this.isPostCategory(category)}
          className="form-control"
          onChange={(event) => this.handleBulkUpdate(activity.get('id'), activity.get('deletedAt'))}
          type="checkbox"
        />
      </span> : ''),

      <span onClick={this.handleUpdateActivityClick.bind(this, activity.get('id'))} key={2}> {activity.get('name')}</span>,

      <span key={3}>{activity.get('description')}</span>,
      <span key={4}>
        {activity.get('deletedAt') ? (
          <p className="nowrap mb-0">
            <span className="status-dot offline" /> Inactive
          </p>
        ) : (
          <p className="nowrap mb-0">
            <span className="status-dot" /> Active
          </p>
        )}
      </span>,
      <span key={4}>{activity.get('creator') && activity.get('creator').get('emailAddress')}</span>,
      <span key={5} style={{ textAlign: 'center' }} className="nowrap">{moment(activity.get('createdAt')).format('YYYY-MM-DD')}</span>,
    ]);
  }

  /**
  * Gets the keys that should be passed to each row so that the renderer can index
  * the rows for better performance.
  */
  getKeys() {
    return this.props.activities.get('results').map(activity => activity.get('id'));
  }

  render() {
    // Get the required items to pass to the table component
    const header = this.getHeader();
    const body = this.getBody();
    const keys = this.getKeys();

    let addNewBtn;
    window.innerWidth > 959 ?
      addNewBtn = (
        <YAButton onClick={this.handleAddActivity.bind(this)} type="primary">
          Add New Tag +
        </YAButton>
      ) : (
        addNewBtn = (
          <button
            className="mobile-add-btn"
            onClick={this.handleAddActivity.bind(this)}
          >
            <img src={`${window.__configuration.s3}/icons/plus.svg`} />
          </button>
        )
      );

    let btnTitle = this.state.toActivate.length === 0 ? "Make Inactive" : "Activate";

    return (
      <div className="main activities">
        <aside>
          {/* Render Add New button */}
          {addNewBtn}
          {/* {//onClick={this.handleArchive.bind(this) */}
          {this.props.profile.get("type") === 'SA' ?
            <div>
              <YAButton disabled={this.state.disabled} onClick={this.handleArchive.bind(this)} type="primary">
                {btnTitle}
              </YAButton>
            </div> :
            null
          }
          <div className="search">
            <input
              type="text"
              onChange={this.handleSearch.bind(this)}
              className="form-control"
              placeholder="Search"
            />

            <FontAwesomeIcon
              icon={faSearch}
              fixedWidth
              className="form-control-feedback"
            />
          </div>

        </aside>

        <section className="column">
          <YATable
            ref="table"
            body={body}
            header={header}
            keys={keys}
            onPaginationClick={this.paginate.bind(this)}
            initialPage={this.props.activities.getIn(['meta', 'page'])}
            pageCount={this.props.activities.getIn(['meta', 'pageCount'])}
            showPagination={this.props.activities.getIn(['meta', 'pageCount']) > 1}
            className={this.props.profile.get("type") === 'A' ? 'hide-col-1' : ''}
            forcePage={this.state.page}
          />

          {/* Render loading indicator if get activities request is pending */}
          <YALoader
            alternate="Loading..."
            image={`${window.__configuration.s3}/icons/loader.gif`}
            show={!this.props.ready}
          />
        </section>
      </div>
    );
  }
}

export default connect(YAActivities.mapStateToProps, YAActivities.mapDispatchToProps)(
  withRouter(Radium(YAActivities))
);
