// Load dependencies
import Immutable, { List, Map } from 'immutable';
import * as actions from './../actions/post';
import { LOGOUT } from './../actions/authenticator';
import moment from 'moment';

// Set the initial state
const initialState = Map({
  types: Map(),

  posts: Map({
    meta: null,
    results: List()
  }),

  post: Map(),

  getPostTypes: Map({
    pending: false,
    error: null
  }),

  getPost: Map({
    id: null,
    pending: false,
    error: null,
    orderBy: null
  }),

  getNews: Map({
    pending: false,
    params: Map({
      id: null,
      deleted: false,
      limit: null,
      page: null,
      type: null,
      orderBy: null
    }),
    error: null
  }),

  getResources: Map({
    pending: false,
    params: Map({
      id: null,
      category: null,
      deleted: false,
      limit: null,
      page: null,
      type: null,
      orderBy: null
    }),
    error: null
  }),

  createPost: Map({
    pending: false,
    params: Map({
      description: null,
      documents: null,
      title: null,
      notify: false,
      url: null,
      pictures: null,
      categories: [],
      translations: [],
      postType: null,
      tags: []
    }),
    error: null
  }),

  updatePost: Map({
    pending: false,
    postId: null,
    params: Map({
      description: null,
      documents: null,
      documentsToDelete: null,
      name: null,
      notify: false,
      pictures: null,
      picturesToDelete: null,
      translations: [],
      tagsToDelete: null
    }),
    error: null
  }),

  createAttachment: Map({
    pending: false,
    postId: null,
    params: Map({
      language: null,
      attachments: List()
    }),
    error: null
  }),

  createAttachments: Map({
    pending: false,
    postId: null,
    params: Map({
      attachmentForms: List()
    }),
    error: null
  }),

  deletePost: Map({
    pending: false,
    postId: null,
    error: null
  }),

  undeletePost: Map({
    pending: false,
    postId: null,
    error: null
  }),
});

/**
* Handles the management of data relating to the post and post categories.
* Stores pagination information for the post so that
* more data can be loaded using the data from the meta information.
*
* @param {object} state
* @param {object} action
*
* @return {object} New state after applying the action
*/
export default function post(state = initialState, action) {
  switch (action.type) {
    // Request to retrieve the list of posts types has come in
    case actions.GET_POST_TYPES_REQUEST:
      return state
        .setIn(['getPostTypes', 'pending'], true)
        .setIn(['getPostTypes', 'error'], null);

    // Request to retrieve the list of post types has succeeded
    case actions.GET_POST_TYPES_SUCCESS:
      return state
        .set('types', Immutable.fromJS(action.payload))
        .setIn(['getPostTypes', 'pending'], false);

    // Getting the post types has failed, store the reason why
    case actions.GET_POST_TYPES_FAILURE:
      return state
        .setIn(['getPostTypes', 'pending'], false)
        .setIn(['getPostTypes', 'error'], null);

    // Get request for the posts has come in, set the parameters to the store
    case actions.GET_RESOURCES_REQUEST:
      return state
        .setIn(['getResources', 'pending'], true)
        .setIn(['getResources', 'params'], Map(action.params))
        .setIn(['getResources', 'error'], null);

    // Get request for the posts has succeeded, set the post to the store
    case actions.GET_RESOURCES_SUCCESS:
      return state
        .setIn(['posts', 'meta'], Map(action.meta))

        .setIn(['posts', 'results'], Immutable.fromJS(action.payload))
        .setIn(['getResources', 'pending'], false);

    // Get request for the posts has failed, set the error to the store
    case actions.GET_RESOURCES_FAILURE:
      return state
        .setIn(['getResources', 'pending'], false)
        .setIn(['getResources', 'error'], action.error);

    // Get request for the posts has come in, set the parameters to the store
    case actions.GET_NEWS_REQUEST:
      return state
        .setIn(['getNews', 'pending'], true)
        .setIn(['getNews', 'params'], Map(action.params))
        .setIn(['getNews', 'error'], null);

    // Get request for the posts has succeeded, set the post to the store
    case actions.GET_NEWS_SUCCESS:
      return state

        .setIn(['posts', 'meta'], Map(action.meta))
        .setIn(['posts', 'results'], Immutable.fromJS(action.payload))
        .setIn(['getNews', 'pending'], false);

    // Get request for the posts has failed, set the error to the store
    case actions.GET_NEWS_FAILURE:
      return state
        .setIn(['getNews', 'pending'], false)
        .setIn(['getNews', 'error'], action.error);

    // Get request for the post has come in, set the parameters to the store
    case actions.GET_POST_REQUEST:
      return state
        .setIn(['getPost', 'pending'], true)
        .setIn(['getPost', 'id'], action.id)
        .setIn(['getPost', 'error'], null);

    // Get request for the post has succeeded, set the post to the store
    case actions.GET_POST_SUCCESS:
      return state
        .set('post', Immutable.fromJS(action.post))
        .setIn(['getPost', 'pending'], false);

    // Get request for the post has failed, set the error to the store
    case actions.GET_POST_FAILURE:
      return state
        .setIn(['getPost', 'pending'], false)
        .setIn(['getPost', 'error'], action.error);

    // Clears out the selected post so that it is no longer selected
    case actions.CLEAR_POST:
      return state.set('post', null);

    // Clear out the current posts
    case actions.INVALIDATE_POSTS:
      return state
        .setIn(['posts', 'meta'], null)
        .setIn(['posts', 'results'], List());

    // Request to create a post has come in, store the parameters
    case actions.CREATE_POST_REQUEST:
      return state
        .setIn(['createPost', 'pending'], true)
        .setIn(['createPost', 'params'], Immutable.fromJS(action.params))
        .setIn(['createPost', 'error'], null);

    // Request to create a post has succeeded, set the flags back to normal
    case actions.CREATE_POST_SUCCESS:
      return state
        .setIn(['createPost', 'pending'], false)
        .set('post', Immutable.fromJS(action.post));

    // Request to create a post has failed, set the error to the store
    case actions.CREATE_POST_FAILURE:
      return state
        .setIn(['createPost', 'pending'], false)
        .setIn(['createPost', 'error'], action.error);

    // Clears out the current error for creating a post
    case actions.CLEAR_CREATE_POST_ERROR:
      return state.setIn(['createPost', 'error'], null);

    // Clears out the current error for updating a post
    case actions.CLEAR_UPDATE_POST_ERROR:
      return state.setIn(['updatePost', 'error'], null);

    // Clears out the current error for creating post attachments
    case actions.CLEAR_CREATE_ATTACHMENTS_ERROR:
      return state.setIn(['createAttachments', 'error'], null);

    // Request to update a post has come in, store the parameters
    case actions.UPDATE_POST_REQUEST:
      return state
        .setIn(['updatePost', 'pending'], true)
        .setIn(['updatePost', 'postId'], action.postId)
        .setIn(['updatePost', 'params'], Immutable.fromJS(action.params))
        .setIn(['updatePost', 'error'], null);

    // Request to update a post was successful, clear the flags
    case actions.UPDATE_POST_SUCCESS:
      return state
        .setIn(['updatePost', 'pending'], false)
        .setIn(['updatePost', 'postId'], null)
        .set('post', Immutable.fromJS(action.post))
        .setIn(
          ['updatePost', 'params'],
          initialState.getIn(['updatePost', 'params'])
        );

    // Request to update a post has failed, store the error message
    case actions.UPDATE_POST_FAILURE:
      return state
        .setIn(['updatePost', 'pending'], false)
        .setIn(['updatePost', 'postId'], null)
        .setIn(
          ['updatePost', 'params'],
          initialState.getIn(['updatePost', 'params'])
        )
        .setIn(['updatePost', 'error'], action.error);

    // Request to delete a post has come in, store the parameters
    case actions.DELETE_POST_REQUEST:
      return state
        .setIn(['deletePost', 'pending'], true)
        .setIn(['deletePost', 'postId'], action.postId)
        .setIn(['deletePost', 'error'], null);

    // Successfully removed a post, remove it from the post if it's there
    case actions.DELETE_POST_SUCCESS:
      return state
        .updateIn(['posts', 'results'], posts => {
          // Post doesn't exist or is empty, short circuit and exit
          if (!posts || !posts.size) {
            return posts;
          }

          // Search for the deleted post in the list of posts
          const postId = state.getIn(['deletePost', 'postId']);
          const index = posts.findIndex(post => post.get('id') === postId);

          // Post was found in the list, add soft delete
          if (index !== -1) {
            return posts.update(index, post => post.set('deletedAt', moment()));
          }

          // Post was not found in the post, do nothing
          return posts;
        })
        .setIn(['deletePost', 'pending'], false)
        .setIn(['deletePost', 'postId'], null);

    // Unable to delete the post, store the error message from the server
    case actions.DELETE_POST_FAILURE:
      return state
        .setIn(['deletePost', 'pending'], false)
        .setIn(['deletePost', 'postId'], null)
        .setIn(['deletePost', 'error'], action.error);

    // Request to undelete a post has come in, store the parameters
    case actions.UNDELETE_POST_REQUEST:
      return state
        .setIn(['undeletePost', 'pending'], true)
        .setIn(['undeletePost', 'postId'], action.postId)
        .setIn(['undeletePost', 'error'], null);

    // Successfully removed a post, remove it from the post if it's there
    case actions.UNDELETE_POST_SUCCESS:
      return state
        .updateIn(['posts', 'results'], posts => {
          // Post doesn't exist or is empty, short circuit and exit
          if (!posts || !posts.size) {
            return posts;
          }

          // Search for the deleted post in the list of posts
          const postId = state.getIn(['undeletePost', 'postId']);
          const index = posts.findIndex(post => post.get('id') === postId);

          // Post was found in the list, remove soft delete
          if (index !== -1) {
            return posts.update(index, post => post.set('deletedAt', null));
          }

          // Post was not found in the post, do nothing
          return posts;
        })
        .setIn(['undeletePost', 'pending'], false)
        .setIn(['undeletePost', 'postId'], null);

    // Unable to delete the post, store the error message from the server
    case actions.UNDELETE_POST_FAILURE:
      return state
        .setIn(['undeletePost', 'pending'], false)
        .setIn(['undeletePost', 'postId'], null)
        .setIn(['undeletePost', 'error'], action.error);


    // Request to create an attachment has come in, store the parameters
    case actions.CREATE_ATTACHMENT_REQUEST:
      return state
        .setIn(['createAttachment', 'pending'], true)
        .setIn(['createAttachment', 'postId'], action.postId)
        .setIn(['createAttachment', 'params'], Immutable.fromJS(action.params))
        .setIn(['createAttachment', 'error'], null);

    // Request to create an attachment has succeeded, set the flags back to normal
    case actions.CREATE_ATTACHMENT_SUCCESS:
      return state.setIn(['createAttachment', 'pending'], false);

    // Request to create an attachment has failed, set the error to the store
    case actions.CREATE_ATTACHMENT_FAILURE:
      return state
        .setIn(['createAttachment', 'pending'], false)
        .setIn(['createAttachment', 'error'], action.error);

    // Request to create an attachment has come in, store the parameters
    case actions.CREATE_ATTACHMENTS_REQUEST:
      return state
        .setIn(['createAttachments', 'pending'], true)
        .setIn(['createAttachments', 'postId'], action.postId)
        .setIn(['createAttachments', 'params'], Immutable.fromJS(action.params))
        .setIn(['createAttachments', 'error'], null);

    // Request to create an attachment has succeeded, set the flags back to normal
    case actions.CREATE_ATTACHMENTS_SUCCESS:
      return state.setIn(['createAttachments', 'pending'], false);

    // Request to create an attachment has failed, set the error to the store
    case actions.CREATE_ATTACHMENTS_FAILURE:
      return state
        .setIn(['createAttachments', 'pending'], false)
        .setIn(['createAttachments', 'error'], action.error);

    // Reset the state when the user logs out
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
