// Load dependencies
import request from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { call, put, select } from 'redux-saga/effects';

export const CREATE_TRANSLATION = 'CREATE_TRANSLATION';
export const CREATE_TRANSLATION_REQUEST = 'CREATE_TRANSLATION_REQUEST';
export const CREATE_TRANSLATION_SUCCESS = 'CREATE_TRANSLATION_SUCCESS';
export const CREATE_TRANSLATION_FAILURE = 'CREATE_TRANSLATION_FAILURE';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';

export const CLEAR_CREATE_TRANSLATION_ERROR = 'CLEAR_CREATE_TRANSLATION_ERROR';

export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION';
export const UPDATE_TRANSLATION_REQUEST = 'UPDATE_TRANSLATION_REQUEST';
export const UPDATE_TRANSLATION_SUCCESS = 'UPDATE_TRANSLATION_SUCCESS';
export const UPDATE_TRANSLATION_FAILURE = 'UPDATE_TRANSLATION_FAILURE';

/**
* Submits a request to the server to create a new translation based on the parameters
* that are passed through.Returns when creating the translation is successful.
*
* @param {number} itemId
* @param {string} itemType
* @param {number} options.language
* @param {object} [options.translations]
*
* @return {object} Translation item that was created or an error object
*/
export function* createTranslation(itemId, itemType, {
  language, translations
}) {
  try {
    // Place the queries into the store, make the request
    const params = { language, translations };
    yield put({ type: CREATE_TRANSLATION_REQUEST, itemId, itemType, params });
    const { data } = yield call(request.post, `${window.__configuration.api}/translation/${itemType}/${itemId}`, params);
    yield put({ type: CREATE_TRANSLATION_SUCCESS, payload: data.payload }); // TODO: put new translation in here
    return data;
  } catch({ response: { data } }) {
    // Creating a new translation has failed, return the error
    yield put({ type: CREATE_TRANSLATION_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
 * Submits a request to the server to update existing translations for an item based on the
 * parameters that are passed through. Returns when updating the translations is successful.
 *
 * @param {number} translationId
 * @param {object} parameters
 */
export function* updateTranslation(itemId, itemType, {
  language, translations
}) {
  try {
    // Place the queries into the store, make the request
    const params = { language, translations };
    yield put({ type: UPDATE_TRANSLATION_REQUEST, itemId, itemType, params });
    const { data } = yield call(request.put, `${window.__configuration.api}/translation/${itemType}/${itemId}`, params);

    yield put({ type: UPDATE_TRANSLATION_SUCCESS, payload: data.payload }); // TODO: put new translation in here
    return data;
  } catch({ response: { data } }) {
    // Creating a new translation has failed, return the error
    yield put({ type: UPDATE_TRANSLATION_FAILURE, error: data.message });
    throw data.message;
  }
}