/* global __NOOP__ */

// Load dependencies
import Color from 'color';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import { brand, button, defaults, font } from './../styles';

/**
* Render a button which is styled based on the `type` property that is passed
* to the component.
*/
class YAButton extends Component {

  // Properties for the button component
  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    noPadding: PropTypes.bool,
    noUppercase: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['small', 'tiny']),
    style: PropTypes.object,
    textColor: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary', 'black', 'danger', 'text', 'cancel']).isRequired
  };

  // Default properties for the button component
  static defaultProps = {
    disabled: false,
    noPadding: false,
    noUppercase: false,
    onClick: __NOOP__,
    style: {}
  };

  // Styles for the different button elements
  static styles = {
    base: {
      boxSizing: 'border-box',
      border: 1,
      borderTopRightRadius: 15,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      fontFamily: font.family.sansSerif,
      color: brand.primary.base,
      cursor: 'pointer',
      fontSize: font.size.base,
      opacity: '1.0',
      outline: 'none',
      padding: '6px 18px',
      fontWeight: font.weight.normal,
      transition: 'all .25s linear'
    },
    small: button.small,
    tiny: button.tiny,
    noPadding: {
      padding: 0
    },
    noUppercase: {
      textTransform: 'none'
    },
    primary: {
      border: `solid ${brand.primary.base} 1px`,
      // borderColor:       brand.primary.base,
      backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: 'brand.primary.darken',
        color: 'white'
      },
      ':focus': {
        backgroundColor: 'brand.primary.darken',
        color: 'white'
      },
      ':active': {
        backgroundColor: brand.primary.darken,
        color: 'white'
      }
    },
    secondary: {
      border: 'solid #EEE 1px',
      // borderColor:       '#EEE',
      backgroundColor: 'white',
      ':hover': {
        backgroundColor: brand.secondary.darken,
        color: 'white'

      },
      ':focus': {
        backgroundColor: brand.secondary.darken,
        color: 'white'

      },
      ':active': {
        backgroundColor: brand.secondary.darken,
        color: 'white'

      }
    },
    black: {
      backgroundColor: brand.black.base,
      ':hover': {
        backgroundColor: brand.black.darken
      },
      ':focus': {
        backgroundColor: brand.black.darken
      },
      ':active': {
        backgroundColor: brand.black.darken
      }
    },
    danger: {
      border: `solid ${brand.danger.base} 1px`,
      // borderColor:       brand.danger.base,
      backgroundColor: brand.danger.base,
      color: 'white',
      ':hover': {
        backgroundColor: 'brand.darken.darken',
        color: 'white'
      },
      ':focus': {
        backgroundColor: 'brand.danger.darken',
        color: 'white'
      },
      ':active': {
        backgroundColor: brand.danger.darken,
        color: 'white'
      }
    },
    text: {
      backgroundColor: 'transparent',
      color: brand.black.base
    },
    cancel: {
      backgroundColor: 'transparent',
      color: '#888'
    },
    disabled: {
      // opacity:           '0.5',
      cursor: 'not-allowed'
    }
  };

  /**
  * Handles a click event if the button is currently not disabled.  Otherwise this
  * function is a no-op.
  */
  handleClick() {
    if (!this.props.disabled) {
      this.props.onClick();
    }
  }

  render() {
    return (
      <button
        key="button"
        onClick={this.handleClick.bind(this)}
        type="button"
        style={[
          YAButton.styles.base,
          YAButton.styles[this.props.type],
          YAButton.styles[this.props.size] || {},
          this.props.disabled ? YAButton.styles.disabled : {},
          this.props.noPadding ? YAButton.styles.noPadding : {},
          this.props.noUppercase ? YAButton.styles.noUppercase : {},
          this.props.type === 'text' && this.props.textColor ? {
            color: this.props.textColor,
            ':hover': {
              color: Color(this.props.textColor).darken(0.35).hex()
            }
          } : {},
          this.props.style
        ]}
      >
        {this.props.children}
      </button>
    );
  }
}

// Export component, connected to Radium
export default Radium(YAButton);