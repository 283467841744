// Load dependencies
import { call, put, fork, take, all, select } from 'redux-saga/effects';
import * as actions from './../actions/post';
import _ from 'lodash';
import * as translationActions from './../actions/translation';

// Load selectors
import { getPost as _getCreatedPost } from './../selectors/post';

/**
* Gets the types of posts that are available to use to create on the server
* and sets them to the store.
*/
function* getPostTypes() {
  while (true) {
    try {
      yield take(actions.GET_POST_TYPES);
      yield call(actions.getPostTypes);
    } catch (error) {
      console.error(error);
    }
  }
}

/**
* Gets the news posts from the server
* and sets it to the store so that it can be displayed.  Sets the meta information
* to the store as well so that the posts can be paginated through.
*/
function* getNews() {
  while (true) {
    // Get the request parameters from the caller
    const { invalidate, deleted, all, search, limit, page, orderBy, onFailure, onSuccess } = yield take(
      actions.GET_NEWS
    );

    try {
      // Send a request to get the posts from the server
      yield call(actions.getNews, { invalidate, search, deleted, all, limit, page, orderBy });

      // Call the success handler if one is provided
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      // Call the failure handler if one is provided
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
  }
}

/**
* Gets the news posts from the server
* and sets it to the store so that it can be displayed.  Sets the meta information
* to the store as well so that the posts can be paginated through.
*/
function* getResources() {
  while (true) {
    // Get the request parameters from the caller
    const { invalidate, deleted, category, search, all, limit, page, orderBy, onFailure, onSuccess } = yield take(
      actions.GET_RESOURCES
    );

    try {
      // Send a request to get the posts from the server
      yield call(actions.getResources, { invalidate, search, category, deleted, all, limit, page, orderBy });

      // Call the success handler if one is provided
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the failure handler if one is provided
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
  }
}

/**
* Gets a post from the server with the specific post id.
*/
function* getPost() {
  while (true) {
    // Get the request parameters from the caller
    const { postId, onFailure, onSuccess } = yield take(actions.GET_POST);

    try {
      // Send a request to get the posts from the server
      yield call(actions.getPost, postId);

      // Call the success handler if one is provided
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      if (error.statusCode === undefined) {
        window.location.reload();
      }
      // Call the failure handler if one is provided
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
  }
}

/**
* Creates a new post on the server. This post is not
* added to the store, but can be added by navigating to the post to find it.
*/
function* createPost() {
  while (true) {
    const params = yield take(actions.CREATE_POST);

    try {
      yield call(actions.createPost, params);

      // Call the on success handler if there is one
      if (typeof params.onSuccess === 'function') {
        // Create translations for this post
        const post = yield select(_getCreatedPost);
        const translations = params.translations || [];

        yield all(
          translations.map(translation => {
            const payload = {
              translations: _.omit(translation, 'languageId'),
              language: translation.languageId
            };
            try {
              return call(
                translationActions.createTranslation,
                post.get('id'),
                'post',
                payload
              );
            } catch (err) {
              if (typeof params.onFailure === 'function') {
                params.onFailure();
              }
            }
          })
        );
        params.onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Set error for create post action if at least one add translation fails
      yield put({ type: actions.CREATE_POST_FAILURE, error });
      // Call the on failure handler if there is one
      if (typeof params.onFailure === 'function') {
        params.onFailure();
      }
    }
  }
}

/**
 * Updates an existing post on the server with any new information and stores
 * the updates in the state if the request is successful.
 */
function* updatePost() {
  while (true) {
    // Extract the parameters from the action
    const parameters = yield take(actions.UPDATE_POST);

    try {
      // Attempt to update the post on the server with the parameters
      yield call(actions.updatePost, parameters.postId, parameters);

      const translations = parameters.translations || [];

      yield all(
        translations.map(translation => {
          const editing = translation.editing;
          const payload = {
            translations: _.omit(translation, ['languageId']),
            language: translation.languageId
          };
          try {
            const action = editing
              ? translationActions.updateTranslation
              : translationActions.createTranslation;

            return call(action, parameters.postId, 'post', payload);
          } catch (err) {
            if (typeof parameters.onFailure === 'function') {
              parameters.onFailure();
            }
          }
        })
      );

      // Call the success handler if there is one provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Set error for update post action if at least one add translation fails
      yield put({ type: actions.UPDATE_POST_FAILURE, error });
      // Call the failure handler if there is one provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
 * Undeletes a soft deleted post
 */
function* undeletePost() {
  while (true) {
    // Extract the parameters from the action
    const parameters = yield take(actions.UNDELETE_POST);

    try {
      // Attempt to update the post on the server with the parameters
      yield call(actions.undeletePost, parameters.postId);

      // Call the success handler if there is one provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the failure handler if there is one provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
 * Creates language-specific post attachments for a specific post on the server
 */
function* createAttachments() {
  while (true) {
    // Extract the parameters from the action
    const parameters = yield take(actions.CREATE_ATTACHMENTS);

    const attachmentForms = parameters.attachmentForms;

    try {
      yield all(
        attachmentForms.map(form => {
          try {
            // Attempt to create the attachment on the server with the parameters
            return call(actions.createAttachment, parameters.postId, form);
          } catch (err) {
            if (typeof parameters.onFailure === 'function') {
              return parameters.onFailure();
            }
          }
        })
      );
      // Call the success handler if there is one provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Set error for create attachments action if at least one create attachment action fails
      yield put({ type: actions.CREATE_ATTACHMENTS_FAILURE, error });
      // Call the failure handler if there is one provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
 * Creates a post attachment on the server
 */
function* createAttachment() {
  while (true) {
    // Extract the parameters from the action
    const parameters = yield take(actions.CREATE_ATTACHMENT);

    try {
      // Attempt to create the attachment on the server with the parameters
      yield call(actions.createAttachment, parameters.postId, parameters);

      // Call the success handler if there is one provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the failure handler if there is one provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
* Deletes a post from the server and removes it from the store in the list
* if the request is successful.
*/
function* deletePost() {
  while (true) {
    // Grab the parameters for the request from the action
    const { onFailure, onSuccess, postId } = yield take(actions.DELETE_POST);

    try {
      // Send the request to remove the post on the server
      yield call(actions.deletePost, postId);

      // Call the on success handler if there is one
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the on failure handler if there is one
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
  }
}

/**
* Start all of the sagas in this module.
*/
export default function* root() {
  yield all([
    fork(getPostTypes),
    fork(createPost),
    fork(updatePost),
    fork(getNews),
    fork(getResources),
    fork(createAttachments),
    fork(getPost),
    fork(deletePost),
    fork(undeletePost),
    fork(createAttachment)
  ]);
}
