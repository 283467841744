// Load dependencies
import { isImmutable, List, Map, filter } from 'immutable';
import moment from 'moment';
import Radium from 'radium';
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { defaults } from './../../components/styles';

// Load actions
import {
  CLEAR_CREATE_POST_ERROR,
  CLEAR_CREATE_ATTACHMENTS_ERROR,
  CLEAR_UPDATE_POST_ERROR,
  CREATE_POST,
  CLEAR_POST,
  GET_POST,
  CREATE_ATTACHMENTS,
  UPDATE_POST
} from './../../actions/post';
import { GET_CATEGORIES } from './../../actions/category';
import { GET_LANGUAGES } from './../../actions/language';
import {
  GET_ACTIVITIES,
  CREATE_TAG,
  GET_TAGS,
  DELETE_TAG
} from './../../actions/activity';

// Load selectors
import { getProfile } from './../../selectors/authenticator';
import {
  getPost,
  getCreatePostError,
  getUpdatePostError,
  getCreateAttachmentsError,
  getCreateAttachmentsPending,
  getCreatePostPending,
  getUpdatePostPending,
  getCreateAttachmentPending
} from './../../selectors/post';
import { getCategories } from './../../selectors/category';
import { getLanguages } from './../../selectors/language';

import {
  getCreateTagError,
  getActivities,
  getTags,
} from './../../selectors/activity';

// Load utilities
import { setState } from './../../util/state';
import { getTranslation } from './../../util/translation';

// Load components
import YAAlert from './../../components/form/alert';
import YALoader from './../../components/loader/loader';
import YAAutocomplete from './../../components/form/autocomplete';
import YAButton from './../../components/form/button';
import YAInput from './../../components/form/input';
import YAAttachmentDropzone from './../../components/dropzone/attachment';
import YAPictureDropzone from './../../components/dropzone/picture';
import YATooltip from './../../components/post/tooltip';
import YAModal from './../../components/modal/modal';
import LanguageModal from './../../components/modal/language-modal';
import queryString from 'query-string';

const Promise = require('bluebird');

/**
* Renders the form to create a new post.
* Returns to the dashboard if the request is
* successful.
*/
class YAPost extends Component {
  // Properties that are passed to the post creation page
  static propTypes = {
    clearCreatePostError: PropTypes.func.isRequired,
    clearUpdatePostError: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    clearCreateAttachmentsError: PropTypes.func.isRequired,
    getPost: PropTypes.func.isRequired,
    getLanguages: PropTypes.func.isRequired,
    createPost: PropTypes.func.isRequired,
    createPostPending: PropTypes.bool.isRequired,
    createAttachments: PropTypes.func.isRequired,
    createAttachmentsError: PropTypes.string,
    createAttachmentsPending: PropTypes.bool.isRequired,
    createAttachmentPending: PropTypes.bool.isRequired,
    updatePostPending: PropTypes.bool.isRequired,
    clearPost: PropTypes.func.isRequired,
    languages: PropTypes.instanceOf(List),
    createPostError: PropTypes.string,
    getCategories: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    post: PropTypes.instanceOf(Map),
    availableCategories: PropTypes.instanceOf(Map),
    profile: PropTypes.instanceOf(Map).isRequired,
    match: PropTypes.object.isRequired,
    updatePost: PropTypes.func.isRequired,
    updatePostError: PropTypes.string,
    getActivities: PropTypes.func.isRequired,
    activities: PropTypes.instanceOf(Map).isRequired,
    createTag: PropTypes.func.isRequired,
    getCreateTagError: PropTypes.string,
    getTags: PropTypes.func.isRequired,
    deleteTag: PropTypes.func.isRequired,
    tags: PropTypes.instanceOf(Map).isRequired,
  };

  // Styles for the post creation page
  static styles = {
    button: {
      float: 'right',
      marginTop: '10px'
    },
    cancelButton: {
      color: defaults.white
    },
    hidden: {
      display: 'none'
    },
    languageBar: {
      height: '50px'
    },
    attachmentContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'auto',
      justifyContent: 'flex-start'
    },
    dropzoneWrapper: {
      marginBottom: 15,
      marginLeft: '-10px',
      marginRight: '-10px'
    },
    dropzoneLabel: {
      marginLeft: 10,
      marginRight: 10
    },
    pictureContainer: {
      flexDirection: 'column',
      overflowY: 'auto',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    picture: {
      height: 200
    },
    editor: {
      backgroundColor: defaults.white,
      height: 250,
      marginBottom: 20
    },
    row: {
      marginTop: `${defaults.gutter}px`
    },
  };

  /**
  * Determines which items the container needs from the store for rendering this
  * page.  These items are injected into the properties of the page.
  */
  static mapStateToProps(state) {
    return {
      createPostError: getCreatePostError(state),
      post: getPost(state),
      profile: getProfile(state),
      availableCategories: getCategories(state),
      createPostPending: getCreatePostPending(state),
      createAttachmentPending: getCreateAttachmentPending(state),
      createAttachmentsPending: getCreateAttachmentsPending(state),
      createAttachmentsError: getCreateAttachmentsError(state),
      updatePostPending: getUpdatePostPending(state),
      languages: getLanguages(state),
      updatePostError: getUpdatePostError(state),
      activities: getActivities(state),
      getCreateTagError: getCreateTagError(state),
      tags: getTags(state),
    };
  }

  /**
  * Determines which actions this page requires and those action dispatchers will
  * be injected into the properties of the page.
  */
  static mapDispatchToProps(dispatch) {
    return {
      deleteTag: options =>
        dispatch({ type: DELETE_TAG, ...options }),
      getActivities: options => dispatch({ type: GET_ACTIVITIES, ...options }),
      createTag: options => dispatch({ type: CREATE_TAG, ...options }),
      getTags: options => dispatch({ type: GET_TAGS, ...options }),
      clearCreatePostError: () => dispatch({ type: CLEAR_CREATE_POST_ERROR }),
      clearCreateAttachmentsError: () =>
        dispatch({ type: CLEAR_CREATE_ATTACHMENTS_ERROR }),
      clearUpdatePostError: () => dispatch({ type: CLEAR_UPDATE_POST_ERROR }),
      clearPost: () => dispatch({ type: CLEAR_POST }),
      getPost: (postId, options) =>
        dispatch({ type: GET_POST, postId, ...options }),
      createPost: (options) =>
        dispatch({
          type: CREATE_POST,
          ...options
        }),
      createAttachments: options =>
        dispatch({
          type: CREATE_ATTACHMENTS,
          ...options
        }),
      getCategories: options => dispatch({ type: GET_CATEGORIES, ...options }),
      getLanguages: options => dispatch({ type: GET_LANGUAGES, ...options }),
      updatePost: (options) =>
        dispatch({
          type: UPDATE_POST,
          ...options
        })
    };
  }

  static DEFAULT_LANGUAGE_CODE = window.__configuration.defaultLanguageCode;
  static DEFAULT_LANGUAGE_ID = window.__configuration.defaultLanguageId; // TODO: get from api
  static BLANK_TRANSLATION_FORM = {
    description: '',
    title: '',
    attachments: List()
  };

  // Initial state of the post container
  state = {
    activity: null,
    description: '',
    title: '',
    url: '',
    type: null, // Either a Resource or a News post
    notify: false,
    publishDate: '',
    categories: [],
    tags: [],
    untagged: [],
    featured: false,
    postTranslations: [],
    categoryTranslations: [],
    addLanguagesModalOpen: false,
    enabledLanguages: [{ id: 1, name: 'English', code: 'en' }],
    selectedLanguage: {
      // TODO: get default lang
      id: 1,
      name: 'English',
      code: 'en'
    },
    ready: false,
    error: null,
    attachments: List(),
    attachmentsToDelete: List(),
    pictures: List(),
    filterTag: "",
  };

  componentDidUpdate(prevProps) {
    if (this.props.post) {
      if (prevProps.post !== this.props.post && this.props.post.get('attachments')) {
        this.storePostInState();
      }
    }
    if (prevProps.updatePostPending && !this.props.updatePostPending) {
      this.submitNewAttachments();
    }
    if (prevProps.createPostPending && !this.props.createPostPending) {
      this.prepareCreatedPostForEdits();
      this.submitNewAttachments();
    }
    if (prevProps.createAttachmentPending && !this.props.createAttachmentPending) {
      this.cancel();
    }
    if (prevProps.activities !== this.props.activities) {
      this.handleUntagged();
    }
  }

  /**
   * Handle the logic that should run before the component is completely mounted.
   */
  async componentWillMount() {
    this.setState({
      type: this.props.location.pathname.split('/')[2] // Set whether this is a resource or news post
    });
    // TODO: set default selected language
    await this.clearErrors();
    await this.props.getCategories({ invalidate: true });
    // Clear any post that is selected if not updating
    if (!queryString.parse(this.props.location.search).updating) {
      await this.props.clearPost();
      await this.props.getActivities({
        paranoid: true
      });

    } else {
      // Get the requested post from the server
      await this.props.getPost(queryString.parse(this.props.location.search).updating, {
        onFailure: () => { this.cancel.bind(this); }
      });
    }
    if (queryString.parse(this.props.location.search).updating) {
      await this.props.getActivities({ postId: queryString.parse(this.props.location.search).updating });
      await this.props.getTags({ postId: queryString.parse(this.props.location.search).updating });
    }
    await this.props.getLanguages();

    // Add default language to enabled languages
    const defaultLanguage = {
      // TODO: get from server
      id: 1,
      code: 'en',
      name: 'English',
      default: true
    };

    this.setState({
      enabledLanguages: [defaultLanguage],
      ready: true
    });
  }

  /**
   * Stores the currently selected post and its translations in state. Enable
   * the languages that this post is translated in.
   */
  storePostInState() {
    // Get the post attachments in the default language
    try {
      if (!this.props.post.get('attachments')) {
        return;
      }
      const attachments = this.props.post
        .get('attachments')
        .filter(
          attachment =>
            attachment.getIn(['language', 'code']) ===
            YAPost.DEFAULT_LANGUAGE_CODE &&
            attachment.get('type') !== 'P'
        );

      const pictures = this.props.post
        .get('attachments')
        .filter(attachement => attachement.get('type') === 'P');

      this.setState({
        description: this.props.post.get('description'),
        title: this.props.post.get('title'),
        url: this.props.post.get('url') || '',
        notify: this.props.post.get('notify') || false,
        publishDate:
          this.props.post.get('publishDate') &&
          moment.utc(this.props.post.get('publishDate')),
        attachments: attachments || List(),
        featured: this.props.post.get('featured'),
        pictures: pictures,
        categories: this.props.post.get('categories')
          ? this.props.post.get('categories').toJS()
          : []
      });

      const translations = this.props.post.get('translations') || [];

      const translationForms = translations.map(translation => {
        // Get the attachments in this translation's language
        const translatedAttachments = this.props.post
          .get('attachments')
          .filter(
            attachment =>
              attachment.get('languageId') === translation.get('languageId') &&
              attachment.get('type') !== 'P'
          );

        const translationForm = {
          editing: true,
          languageId: translation.get('languageId'),
          description: translation.get('description'),
          title: translation.get('title'),
          attachments: translatedAttachments || List()
        };
        return translationForm;
      });

      // Add the languages that this post is translated in to the enabled languages for this form
      let languages =
        translations
          .filter(
            translation =>
              translation.getIn(['language', 'code']) !==
              YAPost.DEFAULT_LANGUAGE_CODE
          )
          .map(translation => translation.get('language')) || List();

      languages = languages.toJS();

      this.setState({
        enabledLanguages: [...this.state.enabledLanguages, ...languages],
        postTranslations: translationForms.toArray() || []
      });
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Clears all errors in state that are associated with creating or updating a post
   */
  async clearErrors() {
    await this.props.clearCreatePostError();
    await this.props.clearUpdatePostError();
    await this.props.clearCreateAttachmentsError();
  }

  /**
  * Adds a new attachment to the list of attachments to upload for the post.
  *
  * @param {object} attachment
  * @param {object[]} attachmentsArray
  */
  addNewAttachment(attachment, attachmentsArray) {
    if (
      this.state.selectedLanguage.code !== YAPost.DEFAULT_LANGUAGE_CODE
    ) {
      // Get the form for the current selected language
      let index = this.state.postTranslations.findIndex(
        translationForm =>
          translationForm.languageId == this.state.selectedLanguage.id
      );

      // Update the translations form with the new values
      let translationForm = this.state.postTranslations[index] || {};

      translationForm['attachments'] = translationForm['attachments'].concat(
        List(attachmentsArray)
      );

      const updatedTranslations = this.state.postTranslations;

      updatedTranslations[index] = translationForm;

      this.setState({
        postTranslations: updatedTranslations
      });
    } else {
      this.setState(({ attachments }) => ({
        attachments: attachments.concat(List(attachmentsArray))
      }));
    }
  }
  /*
  Updates state for untagged activities on new post
  */
  handleUntagged() {
    if (this.props.activities.get('results').size > 0) {
      let temp = this.props.activities.get('results').map((activity) => (
        { id: activity.get('id'), name: activity.get('name') }
      ));
      //get list of untagged and filter out ones that are in the this.state.tags (we will make those tags after the post is created)
      let tagsList = temp._tail.array.filter(untagged => !this.state.tags.some(obj => obj.name === untagged.name));
      this.setState({
        untagged: tagsList
      });
    }
  }

  handleSelectActivity(event) {
    if (!event.target) {
      return;
    }
    this.setState({
      activity: parseInt(event.target.value)
    });
  }

  handleSearch(event = { target: {} }) {
    this.setState({ filterTag: event.target.value });
    if (!event.target.value) {
      return;
    }

    if (event.target.value.length >= 2) {
      this.props.getActivities({
        postId: queryString.parse(this.props.location.search).updating, name: this.state.filterTag, onSuccess: () => {
          if (!queryString.parse(this.props.location.search).updating) {
            this.handleUntagged();
            //this is only for new post

          }
        }
      });
    }
  }

  /**
  * Enables a language so that language specific form fields can be added for this post
  * in this language.
  *
  * @param {array} languages to add to enabled languages list
  */
  handleAddLanguages(languages) {
    // Create translation forms for each newly enabled language
    let translationForms = languages
      .filter(language => !this.state.enabledLanguages.includes(language))
      .map(language => {
        const translationForm = {
          ...YAPost.BLANK_TRANSLATION_FORM
        };
        translationForm.languageId = language.id;
        return translationForm;
      });

    // Filter current translation forms to only include forms for enabled languages
    // This is for the case when languages were removed
    const postTranslations = this.state.postTranslations.filter(
      translationForm =>
        languages
          .map(language => language.id)
          .includes(translationForm.languageId)
    );

    this.setState({
      postTranslations: [...postTranslations, ...translationForms],
      enabledLanguages: languages,
      addLanguagesModalOpen: false
    });
  }

  /**
  * Updates an existing attachment with a new one as the attachment to upload for the post.
  *
  * @param {number} index
  * @param {object} attachment
  */
  updateAttachment(attachment) {
    if (
      this.state.selectedLanguage.code !== YAPost.DEFAULT_LANGUAGE_CODE
    ) {
      // TODO: if updating a post, and there is an attachment already, need to add to attachments to delete
      // Get the form for the current selected language
      let index = this.state.postTranslations.findIndex(
        translationForm =>
          translationForm.languageId == this.state.selectedLanguage.id
      );

      // Update the translations form with the new values
      let translationForm = this.state.postTranslations[index] || {};

      // Add the replaced attachment to attachments to delete if an attachment exists for this post
      if (isImmutable(translationForm['attachments'].get(0))) {
        const toDelete = translationForm['attachments'].getIn([0, 'id']);
        this.setState(({ attachmentsToDelete }) => ({
          attachmentsToDelete: attachmentsToDelete.push(
            toDelete
          )
        }));
      }

      translationForm['attachments'] = translationForm['attachments'].set(0, attachment);

      const updatedTranslations = this.state.postTranslations;

      updatedTranslations[index] = translationForm;

      this.setState({
        postTranslations: updatedTranslations
      });
    } else {
      // Add the replaced attachment to attachments to delete if an attachment exists for this post
      if (isImmutable(this.state.attachments.get(0))) {
        const toDelete = this.state.attachments.getIn([0, 'id']);
        this.setState(({ attachmentsToDelete }) => ({
          attachmentsToDelete: attachmentsToDelete.push(
            toDelete
          )
        }));
      }
      this.setState(({ attachments }) => ({
        attachments: attachments.set(0, attachment)
      }));
    }
  }

  /**
   * Removes a attachment from the current language form
   *
   */
  removeAttachment() {
    if (
      this.state.selectedLanguage.code !== YAPost.DEFAULT_LANGUAGE_CODE
    ) {
      // Get the form for the current selected language
      let index = this.state.postTranslations.findIndex(
        translationForm =>
          translationForm.languageId == this.state.selectedLanguage.id
      );

      // Update the translations form with the new values
      let translationForm = this.state.postTranslations[index] || {};

      // Add the replaced attachment to attachments to delete if an attachment exists for this post
      if (isImmutable(translationForm['attachments'].get(0))) {
        const toDelete = translationForm['attachments'].getIn([0, 'id']);
        this.setState(({ attachmentsToDelete }) => ({
          attachmentsToDelete: attachmentsToDelete.push(
            toDelete
          )
        }));
      }

      translationForm['attachments'] = translationForm['attachments'].delete(0);

      const updatedTranslations = this.state.postTranslations;

      updatedTranslations[index] = translationForm;

      this.setState({
        postTranslations: updatedTranslations
      });
    } else {
      // Add the replaced attachment to attachments to delete if an attachment exists for this post
      if (isImmutable(this.state.attachments.get(0))) {
        const toDelete = this.state.attachments.getIn([0, 'id']);
        this.setState(({ attachmentsToDelete }) => ({
          attachmentsToDelete: attachmentsToDelete.push(
            toDelete
          )
        }));
      }

      this.setState(({ attachments }) => ({
        attachments: attachments.delete(0)
      }));
    }
  }

  /**
  * Adds a new picture to the list of pictures to upload for the post.
  *
  * @param {object} pictures
  * @param {number[]} rotationsArray
  * @param {object[]} picturesArray
  */
  addNewPicture(picture, rotationsArray, picturesArray) {
    this.setState(({ pictures }) => ({
      pictures: pictures.concat(List(picturesArray))
    }));
  }

  handleSelectLanguage(language = {}) {
    this.setState({
      selectedLanguage: language
    });
  }

  /**
  * Updates an existing picture with a new one as the picture to upload for the post. Saves
  * this picture's rotation factor for preview orientation correction TODO: rotation factor
  *
  * @param {number} index
  * @param {object} picture
  * @param {number[]} rotationsArray
  */
  updatePicture(picture, rotationsArray) {
    // TODO: get the lang specific one
    // Determine if the picture is an existing one, add to pictures to delete
    if (isImmutable(this.state.pictures.get(0))) {
      const toDelete = this.state.pictures.get(0).get('id');
      this.setState(({ attachmentsToDelete }) => ({
        attachmentsToDelete: attachmentsToDelete.push(
          toDelete
        )
      }));
    }
    this.setState(({ pictures }) => ({
      pictures: pictures.set(0, picture)
    }));
  }

  /**
   * Removes a picture based on if it is a brand new
   * picture or an existing one.
   *
   * @param {number} index - Index value of the picture to remove from the list
   */
  removePicture() {
    if (isImmutable(this.state.pictures.get(0))) {
      const toDelete = this.state.pictures.getIn([0, 'id']);
      this.setState(({ attachmentsToDelete }) => ({
        attachmentsToDelete: attachmentsToDelete.push(
          toDelete
        )
      }));
    }

    this.setState(({ pictures }) => ({
      pictures: pictures.delete(0)
    }));
  }
  /**
   * Checks if the post being edited is current tagged to this category
   *
   * @param {Map(object)} category to check if post is tagged to
   */
  isPostCategory(category) {
    return (
      this.state.categories &&
      this.state.categories.some(
        taggedCategory => taggedCategory.id === category.get('id')
      )
    );
  }

  /**
   * Handles checking or unchecked of a category by removing it from the post
   * being edited in the state.
   *
   * @param {Map(object)} category to add or remove to/ from state
   * @param {event}
   */
  handleCategoryCheck(category, event) {
    if (!event.target.checked) {
      // Remove unchecked category
      const newCategories = this.state.categories.filter(
        enabledCategory => category.get('id') !== enabledCategory.id
      );
      this.setState({
        categories: newCategories
      });
    } else {
      // Add checked category
      this.setState({
        categories: [...this.state.categories, category.toJS()]
      });
    }
  }

  /**
   * Handles a simple checkbox check event by updating the state property with the
   * new checked state. Applicable the state properties with
   * a key: bool key value pair.
   *
   * @param {Map(object)} category to add or remove to/ from state
   * @param {event}
   */
  handleCheck(property, event) {
    this.setState({
      [property]: event.target.checked
    });
  }
  /**
   * Handles updating the given language agnostic post property
   *
   * @param {*} property
   * @param {*} event
   */
  handleChange(property, event) {

    this.setState({
      [property]:
        typeof event === 'string'
          ? event
          : moment.isDate(event) ? moment.utc(event) : (event.target && event.target.value)
    });
  }

  /**
  * Handles updating the given post property in the state to the given event value for
  * the selected language.
  *
  * @param {string} property
  * @param {object} event
  */
  handleTranslatableChange(property, event) {
    // Fixes case when switch current language triggers this function
    if (!event) {
      return;
    }
    if (
      this.state.selectedLanguage.code !== YAPost.DEFAULT_LANGUAGE_CODE
    ) {
      // Get the form for the current selected language
      let index = this.state.postTranslations.findIndex(
        translationForm =>
          translationForm.languageId == this.state.selectedLanguage.id
      );

      // Update the translations form with the new values
      let translationForm = this.state.postTranslations[index] || {};
      translationForm[property] =
        typeof event === 'string'
          ? event
          : moment.isDate(event) ? moment(event) : (event.target && event.target.value);

      const updatedTranslations = this.state.postTranslations;

      updatedTranslations[index] = translationForm;

      this.setState({
        postTranslations: updatedTranslations
      });
    } else {
      this.handleChange(property, event);
    }
  }

  /**
  * Gets the value for a form field property in the selected language's form. The default language
  * form is this components state.
  *
  * @param {string} property
  */
  getTranslatableValue(property) {
    let translationForm = null;
    if (
      this.state.selectedLanguage.code !== YAPost.DEFAULT_LANGUAGE_CODE
    ) {
      translationForm = this.state.postTranslations.find(
        translation => translation.languageId === this.state.selectedLanguage.id
      );
      translationForm = translationForm || {};
    } else {
      // Getting field value in default language
      translationForm = this.state;
    }
    return translationForm[property];
  }

  /**
  * Returns whether or not the form for the post is valid. A valid form has a title and description
  * for each enabled language and belongs to at least one category
  *
  * @return {boolean} Whether or not the post is valid
  */
  isFormValid() {
    const hasCategories =
      this.state.categories && this.state.categories.length > 0;
    const validTranslations = this.state.postTranslations.every(
      form => form.description && form.title
    );
    let error = null;
    if (this.state.type === 'resource' && !hasCategories) {
      error = 'Resource posts must belong to at least one category';
    }
    if (!this.state.description || !this.state.title || !validTranslations) {
      error =
        'Posts must include a title and description for each enabled language';
    }
    this.setState({
      error
    });
    return error ? false : true;
  }

  /**
  * Sends the user back to the dashboard page, cancelling th() process of creating
  * a new post.
  */
  cancel() {
    return this.props.history.goBack(); // TODO: just go back
  }

  /**
   * Gets the supported languages that are not currently enabled on this form
   */
  getInactiveLanguages() {
    const languageChoices = this.props.languages.toJS();
    const enabled = this.state.enabledLanguages;

    const enabledLanguageIds = enabled.map(language => language.id);
    const allLanguageIds = languageChoices.map(language => language.id);

    const inactiveLanguageIds = allLanguageIds.filter(
      availableLanguage => !enabledLanguageIds.includes(availableLanguage)
    );

    const inactiveLanguages = languageChoices.filter(languageChoice =>
      inactiveLanguageIds.includes(languageChoice.id)
    );

    return inactiveLanguages;
  }

  /**
   * Based on whether or not the user is currently updating or adding a new post,
   * handle the operation accordingly.
   *
   * @return {void}
   */
  handleSubmit() {
    if (!this.isFormValid()) return null;

    if (this.props.post) {
      return this.updatePost();
    }

    return this.createPost();
  }

  handleAddTag() {
    if (queryString.parse(this.props.location.search).updating) {
      if (this.props.post.get('deletedAt')) {
        alert('Inactive posts cannot be tagged. Please re-activate if tagging is required.');
        return;
      }
      this.props.createTag({
        postId: this.props.post.get('id'), activityId: this.state.activity, onSuccess: () => {
          this.props.getActivities({ postId: this.props.post.get('id') });
        }
      });
      this.setState({
        activity: null
      });
    }
    else {
      this.handleUntagged();
      let activityList = this.props.activities.get('results').toJS();
      let name = activityList.find(activity => activity.id === this.state.activity).name;
      let tagsList = this.state.tags;
      tagsList.push({ id: this.state.activity, name: name });
      this.setState({
        tags: tagsList
      });
      //also update untagged list
      let tagsList2 = this.state.untagged.filter(untagged => !this.state.tags.some(obj => obj.name === untagged.name));
      this.setState({
        untagged: tagsList2
      });
      this.setState({
        activity: null
      });
    }
  }

  handleRemoveTag(event) {
    if (queryString.parse(this.props.location.search).updating === undefined) {
      let tagsList = this.state.tags;
      tagsList = tagsList.filter(e => e !== event);
      let untagged = this.state.untagged.concat(event);
      this.setState({
        tags: tagsList,
        untagged: untagged
      });
    }

    if (event.size === 1) {
      this.props.deleteTag({
        id: event.toJS()[0].id,
        onSuccess: () => {
          this.props.getActivities(({ postId: queryString.parse(this.props.location.search).updating }));
        }
      });
    }
  }

  /**
   * Submits a request to add all new language specific attachments to this post, if new attachments
   * exist
   */
  async submitNewAttachments() {
    // Get new attachments from each translation form
    let bodies = this.state.postTranslations
      .map(translationForm => ({
        language: translationForm.languageId,
        attachments: translationForm.attachments
          .filter(attachment => !isImmutable(attachment))
          .toJS()
      }))
      .filter(body => body.attachments && body.attachments.length > 0);

    // Add the default language new attachments to the attachment body list
    const newDefaultAttachments = this.state.attachments
      .filter(attachment => !isImmutable(attachment))
      .toJS();
    if (newDefaultAttachments && newDefaultAttachments.length > 0) {
      bodies.push({
        attachments: newDefaultAttachments
      });
    }

    const hasNewAttachments = bodies.some(
      body => body.attachments && body.attachments.length > 0
    );

    if (hasNewAttachments) {
      const options = {
        postId: this.props.post.get('id'),
        attachmentForms: bodies
      };

      await this.props.createAttachments({
        ...options,
        onFailure: () => {
          this.setState({
            ready: true
          });
        }
      });
    } else {
      // No new attachments to add
      this.cancel();
    }
  }

  /**
   * Updates the post item and sends the user back to the dashboard if successful.
   */
  updatePost() {
    const newPictures = this.state.pictures
      .filter(picture => !isImmutable(picture))
      .toJS();

    const newCategoriesArray = this.state.categories.map(
      category => category.id
    );
    const existingCategories = this.props.post
      .get('categories')
      .map(category => category.get('id'))
      .toJS();
    const categoriesToDelete = existingCategories.filter(
      category => -1 === newCategoriesArray.indexOf(category)
    );

    this.props.updatePost(
      {
        postType: this.state.type === 'resource' ? 'R' : 'N',
        description: this.state.description,
        attachmentsToDelete: this.state.attachmentsToDelete.toJS(),
        featured: this.state.featured,
        translations: this.state.postTranslations,
        categories: newCategoriesArray,
        categoriesToDelete,
        title: this.state.title,
        url: this.state.url,
        notify: this.state.notify,
        publishDate: this.state.publishDate,
        pictures: newPictures,
        postId: this.props.post.get('id'),
        onFailure: () => {
          this.setState({
            ready: true
          });
        }
      });

  }

  /**
   * Sets up state so that the created post may be resubmit for edits
   */
  prepareCreatedPostForEdits() {
    const editTranslationsForms = _.map(this.state.postTranslations, function (
      element
    ) {
      return _.extend({}, element, { editing: true });
    });
    this.setState({
      postTranslations: editTranslationsForms
    });
  }

  /**
   * Return whether or not this form is editing an existing post
   */
  editing() {
    return queryString.parse(this.props.location.search).updating || this.props.post;
  }
  /**
  * Creates the post item and sends the user back to the dashboard if successful.
  */
  createPost() {
    this.setState({
      ready: false
    });

    //create a tag here

    const categoriesArray = this.state.categories.map(category => category.id);
    const tagsArray = this.state.tags.map(tag => tag.id);
    this.props.createPost(
      {
        postType: this.state.type === 'resource' ? 'R' : 'N',
        description: this.state.description,
        pictures: this.state.pictures.toJS(),
        categories: categoriesArray,
        title: this.state.title,
        url: this.state.url,
        notify: this.state.notify,
        publishDate: this.state.publishDate,
        translations: this.state.postTranslations,
        tags: tagsArray,
        featured: this.state.featured,
        onFailure: () => {
          this.prepareCreatedPostForEdits();
          this.setState({
            ready: true
          });
        }
      });
  }

  render() {
    // Get the current error message if there is one currently loaded
    let tagsList = "";
    this.state.tags.length === 0 ?
      (this.props.tags.get('results')).map((tag, index) => {
        tagsList += tag.get("name");
        index + 1 !== this.props.tags.get('results').size ?
          tagsList += ", " : null;
      }
      ) :
      (
        this.state.tags.map((tag, index) => {
          tagsList += tag.name;
          index + 1 !== this.state.tags.length ?
            tagsList += ", " : null;
        })

      );

    let errorMessage = null;
    if (this.state.error) {
      // Front end validation errors
      errorMessage = this.state.error;
    } else if (
      this.props.createPostError ||
      this.props.createAttachmentsError
    ) {
      // Request errors
      errorMessage =
        this.props.createPostError || this.props.createAttachmentsError;
    } else if (this.props.updatePostError) {
      errorMessage = this.props.updatePostError;
    }

    return (
      <div className="main">
        {/* Render the informational header to add/update a post */}
        <header className="status-bar hidden">
          <div className="container-fluid">
            <div className="row columns">
              <div className="col-xs-12">
                <h2 className="row-heading">
                  {queryString.parse(this.props.location.search).updating ? 'Update' : 'Add'}{' '}
                  {this.state.type === 'resource' ? 'Resource' : 'News'}
                </h2>
              </div>
            </div>
          </div>
        </header>

        {/* Side Menu */}
        <aside>
          {// temp: Only allowing one picture per post
            this.state.pictures.size === 0 && (
              <div style={YAPost.styles.dropzoneWrapper}>
                <YAPictureDropzone
                  onDrop={this.addNewPicture.bind(this)}
                  showDeleteButton
                  style={YAPost.styles.picture}
                />
              </div>
            )}

          {// Form to add the pictures to the post
            this.state.pictures.map((picture, index) => (
              <div key={index} style={YAPost.styles.dropzoneWrapper}>
                <YAPictureDropzone
                  onDeleteClick={this.removePicture.bind(this)}
                  onDrop={this.updatePicture.bind(this)}
                  // rotate={this.state.rotations.get(index)} TODO:
                  preview={
                    // Picture is an immutable object if it is existing in the post (editing case)
                    isImmutable(picture)
                      ? picture.getIn(['picture', 'normal'])
                      : picture.preview
                  }
                  showDeleteButton
                  style={YAPost.styles.picture}
                />
              </div>
            ))}

          {/* Form to add the attachments to the post */}

          {// Render each individual attachment to the list of attachments
            this.getTranslatableValue('attachments') &&
            this.getTranslatableValue(
              'attachments'
            ).map((attachment, index) => (
              <div key={index} style={YAPost.styles.dropzoneSmall}>
                <YAAttachmentDropzone
                  fileName={
                    isImmutable(attachment) && attachment.get('name')
                      ? attachment.get('name')
                      : attachment.name || `Attachment #${index}`
                  }
                  onDeleteClick={this.removeAttachment.bind(this)}
                  onDrop={this.updateAttachment.bind(this)}
                  showDeleteButton
                  style={YAPost.styles.attachment}
                  success
                />
              </div>
            ))}

          {// temp: Only allowing one attachment per post
            this.getTranslatableValue('attachments') &&
            this.getTranslatableValue('attachments').size === 0 &&
            // Render add attachment dropdown if no attachment currently in form
            (!this.getTranslatableValue('attachment') && (
              <div style={YAPost.styles.dropzoneSmall}>
                <label>Attach a file:</label>

                <YAAttachmentDropzone
                  style={YAPost.styles.attachment}
                  onDrop={this.addNewAttachment.bind(this)}
                />
              </div>
            ))}

          {
            /* Render category list if Resource type */
            this.state.type === 'resource' && (
              <div className="form-group clearfix" style={{ marginTop: 10 }}>
                <label>Categories</label>

                {this.props.availableCategories
                  .get('results')
                  .map((category, index) => (
                    <div key={index}>
                      <YAInput
                        type="checkbox"
                        checked={this.isPostCategory(category)}
                        onChange={this.handleCategoryCheck.bind(this, category)}
                        placeholder={getTranslation(
                          category,
                          'name',
                          this.state.selectedLanguage.id
                        )}
                        value={getTranslation(
                          category,
                          'name',
                          this.state.selectedLanguage.id
                        )}
                      />
                    </div>
                  ))}
              </div>
            )
          }

          {/* TODO: allow editing state from here */}
        </aside>

        {/* Main Section */}
        <section className="column form-wrap">
          {
            // Render language selectors
          }
          <div className="languages-row">
            <ul className="languages-list">
              <li>Languages</li>
              {this.state.enabledLanguages.map((language = {}, index) => (
                <li
                  key={index}
                  onClick={this.handleSelectLanguage.bind(this, language)}
                  className={
                    this.state.selectedLanguage.id === language.id ? 'active' : ''
                  }
                >
                  {language.name}
                </li>
              ))}
            </ul>

            {/* TODO: Hook up button to add languages */}
            <YAButton
              onClick={setState.bind(this, 'addLanguagesModalOpen', true)}
              type="primary"
            >
              Add Language
            </YAButton>
          </div>

          {
            // Render title and description input fields relative to the current selected language
            <div className="row">
              {/* Render the input to enter the title of the post */}
              <div className="col-lg-9">
                <div className="form-group">
                  <label>Title</label>

                  <YAInput
                    onChange={this.handleTranslatableChange.bind(
                      this,
                      'title'
                    )}
                    type="textarea"
                    value={this.getTranslatableValue('title')}
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>URL Link</label>

                  <YAInput
                    onChange={this.handleChange.bind(this, 'url')}
                    placeholder="http://www.example.ca"
                    type="text"
                    value={this.state.url}
                    className="form-control"
                  />
                </div>

                {/* Render the input to enter the description of the post */}
                <YAInput
                  onChange={this.handleTranslatableChange.bind(
                    this,
                    'description'
                  )}
                  style={YAPost.styles.editor}
                  type="wysiwyg"
                  value={this.getTranslatableValue('description') || ''}
                />

                {// Show the current error message for creating or updating a post if there is one
                  errorMessage ? (
                    <div style={{ marginBottom: `${defaults.gutter / 2}px` }}>
                      <YAAlert type="E">{errorMessage}</YAAlert>
                    </div>
                  ) : null
                }
              </div>

              <div className="col-lg-3">
                <div className="form-group">
                  <label>Publish Date</label>

                  <YAInput
                    onChange={this.handleChange.bind(this, 'publishDate')}
                    value={this.state.publishDate}
                    type="date"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Feature Post</label>

                  <br />
                  {/* Render the featured checkbox */}
                  <YAInput
                    type="checkbox"
                    checked={this.state.featured}
                    onChange={this.handleCheck.bind(this, 'featured')}
                    placeholder="Feature this News Post"
                  />
                </div>

                <div className="form-group">
                  <label>Tag Post</label>

                  {queryString.parse(this.props.location.search).updating ?
                    this.props.tags.get('results').size !== 0 && <p className="bold trim-bottom">Tags:</p>
                    :
                    this.state.tags.length !== 0 && <p className="bold trim-bottom">Tags:</p>
                  }

                  <ul className="tags-list added-tags">
                    {(queryString.parse(this.props.location.search).updating !== undefined) ?
                      (this.props.tags.get('results')).map((tag, index) =>
                        <li key={index} onClick={this.handleRemoveTag.bind(this, tag.get("tags"))}>
                          {tag.get("name")}
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: 10 }}
                          />
                        </li>
                      ) :
                      this.state.tags.map((tag, index) =>
                        <li key={index} onClick={this.handleRemoveTag.bind(this, tag)}>
                          {tag.name}
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: 10 }}
                          />
                        </li>)
                    }
                  </ul>
                </div>

                <div className="form-group">
                  <p className="bold slim-bottom">Add a Tag:</p>

                  <div className="form-group">

                    <YAInput
                      type="select"
                      list="activities"
                      onChange={this.handleSelectActivity.bind(this)}
                      className="form-control"
                      id="activities">
                      <option>Select a tag:</option>
                      {queryString.parse(this.props.location.search).updating ?
                        this.props.activities.get('results').map((activity, index) => (
                          <option key={index} value={activity.get('id')}>
                            {activity.get('name')}
                          </option>
                        ))
                        :

                        this.state.untagged.map((activity, index) => (

                          <option key={index} value={activity.id}>
                            {activity.name}
                          </option>))
                      }
                    </YAInput>
                    <br />
                    <YAButton type="primary" onClick={this.handleAddTag.bind(this)} >
                      Add
                    </YAButton>

                  </div>
                </div>

                <div className="form-group">
                  <label>Push Notifications</label>
                  <p className="slim-bottom">
                    <strong>Note: </strong>
                    If checked, a push notification will be sent to everyone
                    {(this.props.tags.get('results').size === 0) && this.state.tags.length === 0 ?
                      '' : ' with the tags'}
                  </p>

                  <br />
                  {this.props.post && this.props.post.get('deletedAt') ? <div> <strong>Note: </strong>  <p>Inactive posts cannot send notifications</p></div> : <YAInput
                    type="checkbox"
                    checked={this.state.notify}
                    onChange={this.handleCheck.bind(this, 'notify')}
                    placeholder="Send Push on Published"
                  />}
                </div>
              </div>
            </div>
          }
          <div className="row">
            <div className="col-lg-9">
              {/* Create the post item with the attachments and pictures and the given description */}
              <hr />
              {
                <YAButton
                  onClick={this.handleSubmit.bind(this)}
                  type="primary"
                >
                  {this.editing() ? 'Save' : 'Post'}{' '}
                </YAButton>
              }
              <YAButton onClick={this.cancel.bind(this)} type="cancel">
                Cancel
              </YAButton>
            </div>
          </div>
        </section>

        {/* Render the modal to add a new language to this post */}
        <LanguageModal
          inactiveLanguages={this.getInactiveLanguages()}
          activeLanguages={this.state.enabledLanguages}
          show={this.state.addLanguagesModalOpen}
          onCloseClick={setState.bind(this, 'addLanguagesModalOpen', false)}
          onAddClick={addedLanguages => this.handleAddLanguages(addedLanguages)}
        />

        {/* Render loading indicator if post is submitting */}
        <YALoader
          alternate="Loading..."
          image={`${window.__configuration.s3}/icons/loader.gif`}
          show={
            this.props.createPostPending ||
            this.props.updatePostPending ||
            this.props.createAttachmentsPending ||
            !this.state.ready
          }
        />
      </div>
    );
  }
}

export default connect(
  YAPost.mapStateToProps,
  YAPost.mapDispatchToProps
)(withRouter(Radium(YAPost)));
