// Load dependencies
import { delay } from 'bluebird';
import { call, cancel, fork, take, all } from 'redux-saga/effects';
import * as actions from './../actions/activity';

function* getTags() {
  while (true) {
    const { onFailure, userId, activityId, postId } = yield take(actions.GET_TAGS);
    try {
      yield call(actions.getTags, { userId, activityId, postId });
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure(error);
    }
  }
}
function* createTag() {
  while (true) {
    const { onFailure, userId, activityId, postId, tags, onSuccess } = yield take(actions.CREATE_TAG);
    try {
      yield call(actions.createTag, { userId, activityId, postId, tags });
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure(error);
    }
  }
}

function* createActivity() {
  while (true) {
    const { onFailure, name, description, onSuccess } = yield take(actions.CREATE_ACTIVITY);
    try {
      yield call(actions.createActivity, { name, description });
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure(error);
    }
  }
}

function* updateActivities() {
  while (true) {
    const { onFailure, ids, activate, onSuccess } = yield take(actions.UPDATE_ACTIVITIES);
    try {
      yield call(actions.updateActivities, { ids, activate });
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure(error);
    }
  }
}

function* updateActivity() {
  while (true) {
    const { onFailure, name, description, id, tagsToDelete, onSuccess } = yield take(actions.UPDATE_ACTIVITY);
    try {
      yield call(actions.updateActivity, id, { name, description, tagsToDelete });
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure(error);
    }
  }
}

function* getActivities() {
  while (true) {
    const { onFailure, name, id, userId, postId, page, limit, paranoid, orderBy, onSuccess } = yield take(actions.GET_ACTIVITIES);
    try {
      yield call(actions.getActivities, { name, id, userId, postId, page, paranoid, orderBy, limit });
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure(error);
    }
  }
}

function* deleteActivity() {
  while (true) {
    // Retrieve the parameters to delete the user from the action
    const { onFailure, id, onSuccess } = yield take(actions.DELETE_ACTIVITY);
    try {
      // Attempt to make the request to delete the user
      yield call(actions.deleteActivity, id);

      // Call the success handler if one is provided
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the failure handler if a failure handler is provided
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
  }
}

function* deleteTag() {
  while (true) {
    // Retrieve the parameters to delete the user from the action
    const { onFailure, id, tags, onSuccess } = yield take(actions.DELETE_TAG);
    try {
      // Attempt to make the request to delete the user
      yield call(actions.deleteTag, id, tags);

      // Call the success handler if one is provided
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the failure handler if a failure handler is provided
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
  }
}
/**
* Start all of the sagas in this module.
*/
export default function* root() {
  yield all([
    fork(getTags),
    fork(updateActivities),
    fork(getActivities),
    fork(createTag),
    fork(createActivity),
    fork(updateActivity),
    fork(deleteActivity),
    fork(deleteTag),
  ]);
}
