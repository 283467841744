/* global __VERSION__ */

// Load dependencies
import { fromJS } from 'immutable';
import * as actions from './../actions/about';

// Set the initial state
const initialState = fromJS({
  getApplicationInformation: {
    pending: false,
    error: null
  },
  serverVersion: null,
  websiteVersion: __VERSION__
});

/**
 * Handles the management of the state for all information relating to the
 * configuration of the website and the server.  Stores variables such as the
 * current application version.
 *
 * @param {object} state
 * @param {object} action
 *
 * @return {object} New state after applying the action
 */
export default function about(state = initialState, action) {
  switch (action.type) {
    case actions.GET_APPLICATION_INFORMATION_REQUEST:
      return state
        .setIn(['getApplicationInformation', 'pending'], true)
        .setIn(['getApplicationInformation', 'error'], null);

    case actions.GET_APPLICATION_INFORMATION_SUCCESS:
      return state
        .setIn(['getApplicationInformation', 'pending'], false)
        .set('serverVersion', action.payload.version);

    case actions.GET_APPLICATION_INFORMATION_FAILURE:
      return state
        .setIn(['getApplicationInformation', 'pending'], false)
        .setIn(['getApplicationInformation', 'error'], action.error);

    default:
      return state;
  }
}
