/* global __NOOP__ */

// Load dependencies
import _ from 'lodash';
import { List, Map } from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

// Load components
import YAButton from './../../components/form/button';
import YAInput from './../../components/form/input';
import YAModal from './../../components/modal/modal';

/**
* Renders a modal for selecting languages.
*/
class LanguageModal extends Component {
  // Properties that are available for the language modal
  static propTypes = {
    ..._.pick(YAModal.propTypes, ['onCloseClick', 'show']),
    activeLanguages: PropTypes.arrayOf(Map),
    inactiveLanguages: PropTypes.arrayOf(Map),
    onChange: PropTypes.func,
    onAddClick: PropTypes.func
  };

  // Default property values of the language modal
  static defaultProps = {
    ..._.pick(YAModal.defaultProps, ['onCloseClick', 'show']),
    inactiveLanguages: [],
    activeLanguages: [],
    onChange: __NOOP__,
    onAddClick: __NOOP__
  };

  // Default styles for the language modal component
  static styles = {
    modal: {
      width: '300px'
    }
  };

  state = {
    selectedLanguages: [],
    languages: []
  };

  /**
  * Loads in the initial list of workers. Redirects to dashboard if request fails.
  */
  componentWillMount() {
    // Consolodate active and inactive languages for easier access
    this.setState({
      languages: [...this.props.activeLanguages, ...this.props.inactiveLanguages],
      selectedLanguages: this.props.activeLanguages
    });
  }

  componentWillReceiveProps(props) {
    if (props.activeLanguages !== this.props.activeLanguages || props.inactiveLanguages !== this.props.inactiveLanguages) {
      this.setState({
        languages: [...props.activeLanguages, ...props.inactiveLanguages],
        selectedLanguages: props.activeLanguages
      });
    }
  }
  /**
  * Checks if a language is included in the active languages list
  *
  * @param language - the language to check
  */
  isEnabled(language) {
    return this.state.selectedLanguages.some(
      selectedLanguage => selectedLanguage.id === language.id
    );
  }
  /**
   * Adds or removes a language from selected languages depending on whether
   * the corresponding language checkbox was checked or unchecked
   *
   * @param {object} language - the language to add or remove
   */
  handleCheck(event, language) {
    if (event.target.checked) {
      this.setState({
        selectedLanguages: [...this.state.selectedLanguages, language]
      });
    } else {
      this.setState({
        selectedLanguages: this.state.selectedLanguages.filter(
          selectedLanguage => selectedLanguage.id !== language.id
        )
      });
    }
  }

  render() {
    return (
      <YAModal
        style={LanguageModal.styles.modal}
        onCloseClick={this.props.onCloseClick}
        show={this.props.show}
        header="Add Languages"
        footer={
          <YAButton
            onClick={() => this.props.onAddClick(this.state.selectedLanguages)}
            type="primary"
          >
            Add
          </YAButton>
        }
      >

        <div className="languages-wrapper">
          {/* Render the available languages */}
          {this.state.languages.map((language, index) => (
            <YAInput
              key={index}
              type="checkbox"
              checked={this.isEnabled(language)}
              onChange={(event) => this.handleCheck(event, language)}
              placeholder={language['name']}
              value={language['id']}
            />
          ))}
        </div>
      </YAModal>
    );
  }
}

export default Radium(LanguageModal);
