// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Radium from 'radium';
import { brand, defaults, font } from './../styles';

/**
* Renders the header of the YATable component given the list of header element(s)
* to render.
*/
class YATableHeader extends Component {

  // Properties for the table header component
  static propTypes = {
    contents: PropTypes.instanceOf(List).isRequired
  };

  render() {
    return (
      <thead>
        <tr>
          {
            // Render each individual table header as a string or custom element
            this.props.contents.map((heading, index) => (
              <th
                key={index}
              >
                {heading}
              </th>
            ))
          }
        </tr>
      </thead>
    );
  }
}

// Export component, connected to Radium
export default Radium(YATableHeader);