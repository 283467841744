// Load dependencies
import Immutable, { List, Map } from 'immutable';
import * as actions from './../actions/conversation';
import { LOGOUT } from './../actions/authenticator';

// Set the initial state
const initialState = Map({
  conversations: Map({
    meta: null,
    results: List()
  }),

  messages: Map({
    meta: null,
    results: List()
  }),

  getMessages: Map({
    pending: false,
    params: Map({
      includeTranslations: true,
      startDate: null,
      endDate: null,
      all: null,
      limit: null,
      page: null,
      youthId: null,
      workerId: null
    }),
    error: null
  }),

  getConversations: Map({
    pending: false,
    userId: null,
    params: Map({
      all: null,
      limit: null,
      page: null
    }),
    error: null
  })
});

/**
* Handles the management of the state for all conversation related actions such as
* getting conversation
*
* @param {object} state
* @param {object} action
*
* @return {object} New state after applying the action
*/
export default function conversation(state = initialState, action) {
  switch (action.type) {
    // Get conversations request has come in, set the parameters to the store
    case actions.GET_CONVERSATIONS_REQUEST:
      return state
        .setIn(['getConversations', 'pending'], true)
        .setIn(['getConversations', 'params'], Map(action.params))
        .setIn(['getConversations', 'error'], null);

    // Get conversations request has succeeded, save the conversations to the store
    case actions.GET_CONVERSATIONS_SUCCESS:
      return state
        .setIn(['conversations', 'meta'], Map(action.meta))
        .setIn(['conversations', 'results'], Immutable.fromJS(action.payload))
        .setIn(['getConversations', 'pending'], false);

    // Get conversations request has failed, save the error to the store
    case actions.GET_CONVERSATIONS_FAILURE:
      return state
        .setIn(['getConversations', 'pending'], false)
        .setIn(['getConversations', 'error'], action.error);

    // Get messages request has come in, set the parameters to the store
    case actions.GET_MESSAGES_REQUEST:
      return state
        .setIn(['getMessages', 'pending'], true)
        .setIn(['getMessages', 'params'], Map(action.params))
        .setIn(['getMessages', 'error'], null);

    // Get messages request has succeeded, save the messages to the store
    case actions.GET_MESSAGES_SUCCESS:
      return state
        .setIn(['messages', 'meta'], Map(action.meta))
        .setIn(['messages', 'results'], Immutable.fromJS(action.payload))
        .setIn(['getMessages', 'pending'], false);

    // Get messages request has failed, save the error to the store
    case actions.GET_MESSAGES_FAILURE:
      return state
        .setIn(['getMessages', 'pending'], false)
        .setIn(['getMessages', 'error'], action.error);

    // Clear out the current conversations
    case actions.INVALIDATE_CONVERSATIONS:
      return state
        .setIn(['conversations', 'meta'], null)
        .setIn(['conversations', 'results'], List());

    // Clear out the current messages
    case actions.INVALIDATE_MESSAGES:
      return state
        .setIn(['messages', 'meta'], null)
        .setIn(['messages', 'results'], List());

    // Reset the state when the user logs out
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
