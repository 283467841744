/* global __NOOP__ */

// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { List } from 'immutable';
import Radium from 'radium';

// Load components
import YATableBody from './table-body';
import YATableHeader from './table-header';

/**
* Renders a table for some arbitrary data given a column count header data and
* row data.
*/
class YATable extends Component {

  // Properties for the table component
  static propTypes = {
    body: PropTypes.instanceOf(List),
    header: PropTypes.instanceOf(List).isRequired,
    keys: PropTypes.instanceOf(List),
    initialPage: PropTypes.number,
    onPaginationClick: PropTypes.func,
    onRowClick: PropTypes.func,
    pageCount: PropTypes.number,
    forcePage: PropTypes.number,
    showPagination: PropTypes.bool,
    className: PropTypes.string
  };

  // Default property values for the table component
  static defaultProps = {
    body: List(),
    keys: List(),
    initialPage: 1,
    onPaginationClick: __NOOP__,
    onRowClick: __NOOP__,
    pageCount: 1,
    forcePage: 0,
    showPagination: false
  };

  // Styles for the table component
  static styles = {};

  render() {
    return (
      <div>
        <table className={this.props.className}>
          <YATableHeader contents={this.props.header} />
          <YATableBody
            contents={this.props.body}
            keys={this.props.keys}
            onRowClick={this.props.onRowClick}
          />
        </table>

        {
          // Show the pagination if pagination is specified
          this.props.showPagination ?
            <ReactPaginate
              activeClassName="active"
              breakLabel={<span>&hellip;</span>}
              onPageChange={this.props.onPaginationClick}
              containerClassName="pagination"
              disableInitialCallback
              initialPage={this.props.initialPage - 1}
              marginPagesDisplayed={2}
              nextLabel="&#8594;"
              pageCount={this.props.pageCount}
              forcePage={this.props.forcePage}
              pageRangeDisplayed={5}
              previousLabel="&#8592;"
            /> :
            null
        }
      </div>
    );
  }
}

// Export component, connected to Radium
export default Radium(YATable);