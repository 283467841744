// Returns the list of post types available on the server
export function getPostTypes(state) {
  return state.post.get('types');
}

// Retrieves the post that is currently selected
export function getPost(state) {
  return state.post.get('post');
}

export function getCreatePostPending(state) {
  return state.post.getIn(['createPost', 'pending']);
}

export function getCreateAttachmentPending(state) {
  return state.post.getIn(['createAttachment', 'pending']);
}

export function getCreateAttachmentsError(state) {
  return state.post.getIn(['createAttachments', 'error']);
}

export function getCreateAttachmentsPending(state) {
  return state.post.getIn(['createAttachments', 'pending']);
}

export function getUpdatePostPending(state) {
  return state.post.getIn(['updatePost', 'pending']);
}

// Returns whether or not we should go to the server for more posts
export function arePostsReady(state) {
  return (
    !!state.post.getIn(['posts', 'meta']) &&
    (!state.post.getIn(['getResources', 'pending']) &&
      !state.post.getIn(['getNews', 'pending']))
  );
}

// Select the current list of posts with meta information
export function getPosts(state) {
  return state.post.get('posts');
}

export function getPostsPending(state){
  return state.post.getIn(['getResources', 'pending']) || state.post.getIn(['getNews', 'pending']);
}

// Select the current error message for creating a new post
export function getCreatePostError(state) {
  return state.post.getIn(['createPost', 'error']);
}

// Select the current error message for updating an existing post
export function getUpdatePostError(state) {
  return state.post.getIn(['updatePost', 'error']);
}
