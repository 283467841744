// Load dependencies
import request from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { call, put, select } from 'redux-saga/effects';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';
export const CLEAR_CREATE_CATEGORY_ERROR = 'CLEAR_CREATE_CATEGORY_ERROR';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const INVALIDATE_CATEGORIES = 'INVALIDATE_CATEGORIES';

/**
* Submits a request to the server to get the available post categories. Only resource
* posts have categories (for now).
*
* @param {boolean} [invalidate]
*/
export function* getCategories({ invalidate } = {}) {
  try {

    // Invalidate the categories if requested
    if (invalidate) {
      yield put({ type: INVALIDATE_CATEGORIES });
    }

    // Make the request to the server for getting the categories
    yield put({ type: GET_CATEGORIES_REQUEST });
    const { data } = yield call(request.get, `${window.__configuration.api}/post/category`);

    // Getting the post was successful, add them to the store
    yield put({ type: GET_CATEGORIES_SUCCESS, meta: data.meta, payload: data.results });
    return data;
  } catch ({ response: { data } }) {

    // Getting the post has failed, set the error as to why
    yield put({ type: GET_CATEGORIES_FAILURE, error: data.message });
    throw data;
  }
}

/**
* Submits a request to the server to create a new category.
*
* @param {string} name
*
* @return {object} Category item that was created or an error object
*/
export function* createCategory({
  name
}) {
  try {
    // Create the request payload
    const payload = {};
    if (name) payload.name = name;

    // Place the queries into the store, make the request
    yield put({ type: CREATE_CATEGORY_REQUEST, params: arguments[0] });
    const { data } = yield call(request.post, `${window.__configuration.api}/post`, formData);

    yield put({ type: CREATE_CATEGORY_SUCCESS });
    return data.payload;
  } catch (error) {
    let data = _.get(error, ['response', 'data']);
    if (typeof data !== 'object') {
      data = {};
    }

    data.message = data.message;

    // Creating a new category has failed, return the error
    yield put({ type: CREATE_CATEGORY_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
 * Submits a request to the server to update an existing category based on the
 * parameters that are passed through. Returns when updating the category is successful.
 *
 * @param {number} categoryId
 * @param {object} parameters
 */
export function* updateCategory(categoryId, parameters) {
  try {

    // Make the name of parameters shorter for easier access
    const p = parameters;

    // Create the form data, add the data that we need
    const formData = new FormData();

    // Add the data that is provided to the request
    if (p.name) formData.append('name', p.name);

    // Place the queries into the store, make the request
    yield put({ type: UPDATE_CATEGORY_REQUEST, categoryId, params: arguments[1] });
    const { data } = yield call(request.post, `${window.__configuration.api}/category/${categoryId}`, formData);

    yield put({ type: UPDATE_CATEGORY_SUCCESS });
    return data.payload;
  } catch (error) {
    let data = _.get(error, ['response', 'data']);
    if (typeof data !== 'object') {
      data = {};
    }

    data.message = data.message || DEFAULT_ERROR_MESSAGE;

    // Updating an existing category has failed, return the error
    yield put({ type: UPDATE_CATEGORY_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
* Submits a request to the server to delete a category with the specified identifier
* and if the request is successful the category is removed from the list.
*
* @param {number} categoryId
*/
export function* deleteCategory(categoryId) {
  try {

    // Make the request to the server to remove the category
    yield put({ type: DELETE_CATEGORY_REQUEST, categoryId });
    const { data } = yield call(request.delete, `${window.__configuration.api}/category/${categoryId}`);

    // Removing the category was successful, remove the category from the store
    yield put({ type: DELETE_CATEGORY_SUCCESS });
    return data;
  } catch ({ response: { data } }) {

    // Removing the category has failed, set the error as to why
    yield put({ type: DELETE_CATEGORY_FAILURE, error: data.message });
    throw data.message;
  }
}