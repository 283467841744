// Retrieves the category item that is currently selected
export function getSelectedCategory(state) {
  return state.category.get('category');
}

// Select the current list of categories with meta information
export function getCategories(state) {
  return state.category.get('categories');
}

// Select the current error message for creating a new category
export function getCreateCategoryError(state) {
  return state.category.getIn(['createCategory', 'error']);
}

// Select the current error message for updating an existing category
export function getUpdateCategoryError(state) {
  return state.category.getIn(['updateCategory', 'error']);
}