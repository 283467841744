// Load dependencies
import { call, fork, takeLatest, all } from 'redux-saga/effects';
import * as actions from './../actions/about';

/**
 * Watches for the newest request to get application information.  Forwards the
 * request to the handler.
 */
function* watchGetApplicationInformation() {
  yield* takeLatest(actions.GET_APPLICATION_INFORMATION, getApplicationInformation);
}

/**
 * Sends an action to request the server for the application information.
 */
function* getApplicationInformation() {
  try {
    yield call(actions.getApplicationInformation);
  } catch (error) { }
}

/**
 * Start all of the sagas in this module.
 */
export default function* root() {
  yield all([fork(watchGetApplicationInformation)]);
}
