// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { loading } from './../styles';

/**
* Renders a loading spinner that should show only when there is currently a
* request to the server pending.
*/
class YALoader extends Component {

  // Property types for the loader component
  static propTypes = {
    alternate: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    show: PropTypes.bool
  };

  // Default property values for the loader component
  static defaultProps = {
    show: false
  };

  // Default styles to apply to the loader component
  static styles = {
    base: {
      boxSizing: 'border-box',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: loading.bg,
      zIndex: 1
    },
    loader: {
      width: loading.width,
      height: loading.height,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: `-${loading.height / 2}px`,
      marginLeft: `-${loading.width / 2}px`
    },
    dashLoader: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }

  render() {

    // Do not render the loader if not set to show
    if (!this.props.show) {
      return null;
    }

    return (
      <div style={this.props.type == "dashboard" ? YALoader.styles.dashLoader : YALoader.styles.base}>
        <img
          alt={this.props.alternate}
          src={this.props.image}
          style={this.props.type == "dashboard" ? YALoader.styles.dashLoader : YALoader.styles.loader}
          type={this.props.type}
        />
      </div>
    );
  }
}

// Export component, connected to Radium
export default Radium(YALoader);