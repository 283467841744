// Select the current conversations with meta information
export function getConversations(state) {
  return state.conversation.get('conversations');
}

// Select the current conversation messages
export function getMessages(state) {
  return state.conversation.get('messages');
}


export function getMessagesPending(state){
  return state.conversation.getIn(['getMessages','pending']);
}
