// Load dependencies
import Immutable, { List, Map } from 'immutable';
import * as actions from './../actions/log';
import { LOGOUT } from './../actions/authenticator';

// Set the initial state
const initialState = Map({

  accessedPosts: Map({
    meta: null,
    results: List()
  }),

  logs: Map({
    meta: null,
    results: List()
  }),

  getLogs: Map({
    pending: false,
    params: Map({
      all: null,
      startDate: null,
      endDate: null,
      userId: null,
      limit: null,
      page: null,
    }),
    error: null
  }),

  getAccessedPosts: Map({
    pending: false,
    params: Map({
      all: null,
      startDate: null,
      endDate: null,
      userId: null,
      limit: null,
      page: null
    }),
    error: null
  })
});

/**
* Handles the management of the state for all log related actions such as
* getting logs and creating new logs.
*
* @param {object} state
* @param {object} action
*
* @return {object} New state after applying the action
*/
export default function log(state = initialState, action) {
  switch (action.type) {
    // Get logs request has come in, set the parameters to the store
    case actions.GET_LOGS_REQUEST:
      return state
        .setIn(['getLogs', 'pending'], true)
        .setIn(['getLogs', 'params'], Map(action.params))
        .setIn(['getLogs', 'error'], null);

    // Get logs request has succeeded, save the logs to the store
    case actions.GET_LOGS_SUCCESS:
      return state
        .setIn(['logs', 'meta'], Map(action.meta))
        .setIn(['logs', 'results'], Immutable.fromJS(action.payload))
        .setIn(['getLogs', 'pending'], false);

    // Get logs request has failed, save the error to the store
    case actions.GET_LOGS_FAILURE:
      return state
        .setIn(['getLogs', 'pending'], false)
        .setIn(['getLogs', 'error'], action.error);

    // Clear out the current logs
    case actions.INVALIDATE_LOGS:
    return state
      .setIn(['logs', 'meta'], null)
      .setIn(['logs', 'results'], List());

    // Clear out the current accessed post logs
    case actions.INVALIDATE_ACCESSED_POSTS:
    return state
      .setIn(['accessedPosts', 'meta'], null)
      .setIn(['accessedPosts', 'results'], List());

        // Get logs request has come in, set the parameters to the store
    case actions.GET_ACCESSED_POSTS_REQUEST:
    return state
      .setIn(['getAccessedPosts', 'pending'], true)
      .setIn(['getAccessedPosts', 'params'], Map(action.params))
      .setIn(['getAccessedPosts', 'error'], null);

  // Get logs request has succeeded, save the logs to the store
  case actions.GET_ACCESSED_POSTS_SUCCESS:
    return state
      .setIn(['accessedPosts', 'meta'], Map(action.meta))
      .setIn(['accessedPosts', 'results'], Immutable.fromJS(action.payload))
      .setIn(['getAccessedPosts', 'pending'], false);

  // Get logs request has failed, save the error to the store
  case actions.GET_ACCESSED_POSTS_FAILURE:
    return state
      .setIn(['getAccessedPosts', 'pending'], false)
      .setIn(['getAccessedPosts', 'error'], action.error);

    // Reset the state when the user logs out
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
