// Load dependencies
import Immutable, { Map, List } from 'immutable';
import * as actions from './../actions/language';

// Set the initial state
const initialState = Immutable.fromJS({
  getLanguages: {
    pending: false,
    error: null
  },
  languages: List()
});

/**
 * Handles the management of the state for all information relating to
 * site languages
 *
 * @param {object} state
 * @param {object} action
 *
 * @return {object} New state after applying the action
 */
export default function language(state = initialState, action) {
  switch (action.type) {
    case actions.GET_LANGUAGES_REQUEST:
      return state
        .setIn(['getLanguages', 'pending'], true)
        .setIn(['getLanguages', 'error'], null);

    case actions.GET_LANGUAGES_SUCCESS:
      return state
        .setIn(['getLanguages', 'pending'], false)
        .set('languages', Immutable.fromJS(action.payload));

    case actions.GET_LANGUAGES_FAILURE:
      return state
        .setIn(['getLanguages', 'pending'], false)
        .setIn(['getLanguages', 'error'], action.error);

    default:
      return state;
  }
}
