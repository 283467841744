// Load dependencies
import { call, fork, take, all } from 'redux-saga/effects';
import * as actions from './../actions/translation';

/**
* Creates a new translation on the server. This translation is not
* added to the store, but can be added by navigating to the translation to find it.
*/
function* createTranslation() {
  while (true) {
    const params = yield take(actions.CREATE_TRANSLATION);

    try {

      yield call(actions.createTranslation, params);

      // Call the on success handler if there is one
      if (typeof params.onSuccess === 'function') {
        params.onSuccess();
      }
    } catch (error) {
      // Call the on failure handler if there is one
      if (typeof params.onFailure === 'function') {
        params.onFailure();
      }
    }
  }
}

/**
 * Updates an existing translation on the server with any new information and stores
 * the updates in the state if the request is successful.
 */
function* updateTranslation() {
  while (true) {
    // Extract the parameters from the action
    const parameters = yield take(actions.UPDATE_TRANSLATION);

    try {
      // Attempt to update the translation on the server with the parameters
      yield call(actions.updateTranslation, parameters.translationId, parameters);

      // Call the success handler if there is one provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      // Call the failure handler if there is one provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
* Start all of the sagas in this module.
*/
export default function* root() {
  yield all([
    fork(createTranslation),
    fork(updateTranslation),
  ]);
}
