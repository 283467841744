/* global __NOOP__ */

// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Radium from 'radium';

/**
* Renders the body of the YATable component given the list of rows and columns to
* render (as a node.)
*/
class YATableBody extends Component {

  // Properties for the table body component
  static propTypes = {
    contents: PropTypes.instanceOf(List),
    keys: PropTypes.instanceOf(List),
    onRowClick: PropTypes.func
  };

  // Default property values for the table body component
  static defaultProps = {
    contents: List(),
    keys: List(),
    onRowClick: __NOOP__
  }

  /**
  * Calls the row click handler if one is provided with the index of the row that
  * is clicked.
  *
  * @param {number} index
  */
  handleRowClick(index) {
    this.props.onRowClick(index);
  }

  render() {
    return (
      <tbody>
        {
          // Render each individual row
          this.props.contents.map((contents, index) => (
            <tr
              key={this.props.keys.get(index)}
              onClick={this.handleRowClick.bind(this, index)}
            >
              {
                // Render each individual column in that row
                contents.map((column, index) => (
                  <td key={index}>
                    {column}
                  </td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
    );
  }
}

// Export component, connected to Radium
export default Radium(YATableBody);