// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { isImmutable, Map } from 'immutable';
import moment from 'moment';
import _ from 'lodash';
import Radium from 'radium';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { CSVLink, CSVDownload } from 'react-csv';

// Load styles
import {
  defaults,
  brand,
  input,
  popover,
  mediaQueries
} from './../../components/styles';

// Load selectors
import { getProfile } from './../../selectors/authenticator';
import {
  getUser,
  getWorkers,
  getUpdateUserError,
  getUpdateUserProfilePicturePending,
  getUpdateUserProfilePictureError,
  getUpdateUserPending
} from './../../selectors/user';

import {
  getCreateTagError,
  getActivities,
  getTags,
} from './../../selectors/activity';

import {
  getConversations,
  getMessages,
  getMessagesPending
} from './../../selectors/conversation';

import {
  getLogs,
  getLogsPending,
  getAccessedPostsPending,
  getAccessedPosts
} from './../../selectors/log';

// Load actions
import {
  GET_USER,
  GET_WORKERS,
  UPDATE_USER,
  DELETE_USER,
  UPDATE_USER_PROFILE_PICTURE,
} from './../../actions/user';

import {
  GET_TAGS,
  GET_ACTIVITIES,
  CREATE_TAG,
  DELETE_TAG,
} from './../../actions/activity';

import { GET_CONVERSATIONS, GET_MESSAGES, INVALIDATE_MESSAGES } from './../../actions/conversation';
import { GET_LOGS, GET_ACCESSED_POSTS, INVALIDATE_LOGS, INVALIDATE_ACCESSED_POSTS } from './../../actions/log';

// Load components
import YAButton from './../../components/form/button';
import YAAlert from './../../components/form/alert';
import YALoader from './../../components/loader/loader';
import YAInput from './../../components/form/input';
import MessageBubble from './../../components/cards/message-bubble';
import YAPictureDropzone from './../../components/dropzone/picture';
import YADateSelector from './date-selector';
import queryString from 'query-string';

// Load utilities
import { setState, setStateDeep } from './../../util/state';
import { formatForCSV } from './../../util/csv';

/**
*
*/
class YADashboard extends Component {
  // Properties that are passed to the dashboard page
  static propTypes = {
    history: PropTypes.object.isRequired,
    getUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    getAccessedPosts: PropTypes.func.isRequired,
    accessedPosts: PropTypes.instanceOf(Map).isRequired,
    conversations: PropTypes.instanceOf(Map).isRequired,
    messages: PropTypes.instanceOf(Map).isRequired,
    logs: PropTypes.instanceOf(Map).isRequired,
    getWorkers: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    createTag: PropTypes.func.isRequired,
    getActivities: PropTypes.func.isRequired,
    getLogs: PropTypes.func.isRequired,
    clearLogs: PropTypes.func.isRequired,
    clearAccessedPosts: PropTypes.func.isRequired,
    clearConversation: PropTypes.func.isRequired,
    getMessages: PropTypes.func.isRequired,
    getConversations: PropTypes.func.isRequired,
    workers: PropTypes.instanceOf(Map).isRequired,
    tags: PropTypes.instanceOf(Map).isRequired,
    activities: PropTypes.instanceOf(Map).isRequired,
    user: PropTypes.instanceOf(Map),
    updateUser: PropTypes.func.isRequired,
    messagesPending: PropTypes.bool,
    logsPending: PropTypes.bool,
    userPending: PropTypes.bool,
    accessedPostsPending: PropTypes.bool,
    updateUserPending: PropTypes.bool,
    getCreateTagError: PropTypes.string,
    updateUserError: PropTypes.string,
    updateUserProfilePicture: PropTypes.func.isRequired,
    updateUserProfilePicturePending: PropTypes.bool,
    updateUserProfilePictureError: PropTypes.string,
    deleteTag: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    let userId = this.props.match.params.userId;
    this.props.getUser({
      id: userId, onSuccess: () => {
        // this.setup(this.props.user);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      // Reset state
      this.setState(YADashboard.initalState);
      // Case when another user is clicked from this users dash 
      this.setup(this.props.user);
    } else if (prevProps.conversations !== this.props.conversations) {
      this.setState({ selectedConversation: this.props.conversations.get('results').size ? this.props.conversations.get('results').get(0) : null });
      this.props.conversations.get('results').size > 0 &&
        this.selectConversation(this.props.conversations.get('results').get(0));
    }
  }

  /**
  * Determines which items the container needs from the store for rendering this
  * page.  These items are injected into the properties of the page.
  */
  // TODO: get selected user, get messages (user), get logs (users), get posts (user), get user (type worker)
  static mapStateToProps(state) {
    return {
      conversations: getConversations(state),
      messages: getMessages(state),
      updateUserError: getUpdateUserError(state),
      workers: getWorkers(state),
      tags: getTags(state),
      activities: getActivities(state),
      logs: getLogs(state),
      profile: getProfile(state),
      accessedPosts: getAccessedPosts(state),
      updateUserPending: getUpdateUserPending(state),
      updateUserProfilePicturePending: getUpdateUserProfilePicturePending(
        state
      ),
      updateUserProfilePictureError: getUpdateUserProfilePictureError(state),
      user: getUser(state),
      accessedPostsPending: getAccessedPostsPending(state),
      logsPending: getLogsPending(state),
      messagesPending: getMessagesPending(state),
      getCreateTagError: getCreateTagError(state)
    };
  }

  /**
  * Determines which actions this page requires and those action dispatchers will
  * be injected into the properties of the page.
  */
  static mapDispatchToProps(dispatch) {
    return {
      deleteTag: options =>
        dispatch({ type: DELETE_TAG, ...options }),
      getUser: options => dispatch({ type: GET_USER, ...options }),
      deleteUser: (id, options) =>
        dispatch({ type: DELETE_USER, id, ...options }),
      getConversations: (userId, options) =>
        dispatch({ type: GET_CONVERSATIONS, userId, ...options }),
      getMessages: options => dispatch({ type: GET_MESSAGES, ...options }),
      getLogs: options => dispatch({ type: GET_LOGS, ...options }),
      getWorkers: options => dispatch({ type: GET_WORKERS, ...options }),
      getTags: options => dispatch({ type: GET_TAGS, ...options }),
      getActivities: options => dispatch({ type: GET_ACTIVITIES, ...options }),
      clearConversation: () => dispatch({ type: INVALIDATE_MESSAGES }),
      clearLogs: () => dispatch({ type: INVALIDATE_LOGS }),
      clearAccessedPosts: () => dispatch({ type: INVALIDATE_ACCESSED_POSTS }),
      getAccessedPosts: options =>
        dispatch({ type: GET_ACCESSED_POSTS, ...options }),
      updateUser: options => dispatch({ type: UPDATE_USER, ...options }),
      updateUserProfilePicture: options =>
        dispatch({ type: UPDATE_USER_PROFILE_PICTURE, ...options }),
      createTag: options => dispatch({ type: CREATE_TAG, ...options })
    };
  }

  static styles = {
    conversation: {
      container: {
        paddingBottom: input.heightBase
      },
      input: {
        borderRadius: 0,
        bottom: 50,
        left: '66.6666%',
        position: 'fixed',
        right: 0,
        [`@media (min-width: ${mediaQueries.screenLg}px)`]: {
          left: '50%'
        }
      }
    },
    filter: {
      color: defaults.black,
      height: '25px',
      minWidth: '120px',
      padding: '0 10px',
      container: {
        marginRight: `${defaults.gutter / 5}px`
      }
    },
    picture: {
      height: '150px',
      width: 'auto',
      marginRight: -10,
      marginLeft: -10
    },
    popover: {
      marginLeft: `-${popover.baseWidth / 2 - 6}px`,
      position: 'absolute',
      width: `${popover.baseWidth}px`,
      zIndex: 1
    },
    dateHeader: {
      fontWeight: 'normal',
      color: brand.primary.base
    },
    main: {
      display: 'flex',
      flex: 1
    },
    columnBody: {
      overflowY: 'auto',
      flex: 1,
      paddingTop: 10,
      paddingBottom: 10
    }
  };

  // Initial state of the dashboard container
  static initalState = {
    newDateSelectorOpen: false,
    logDateRange: [],
    chatDateRange: [],
    postDateRange: [],
    editing: false,
    popped: false,
    isDefaultAdmin: false,
    webAccess: false,
    firstName: '',
    lastName: '',
    birthDate: '',
    selectedUserId: null,
    selectedConversation: Map(),
    emailAddress: '',
    notes: '',
    gender: '',
    activity: null,
    phoneNumber: '',
    mobileSelectedHeader: ''
  };

  constructor(props) {
    super(props);
    this.state = YADashboard.initalState;
  }

  /**
   * Selects a conversation and retrieves the messages in the conversation
   */
  selectConversation(conversation = Map()) {
    this.setState({
      selectConversation: conversation
    });
    this.props.getMessages({
      conversationId: conversation.get('id'),
      includeTranslations: true,
      invaliate: true
    });
  }

  goBack() {
    return this.props.history.goBack();
  }
  /**
   * Makes a request to delete a tag, then refreshes the page
   */
  deleteTag(id) {
    this.props.deleteTag({
      id: id,
      onSuccess: () => {
        this.props.getActivities({ userId: this.props.user.get('id') });
      }
    });
  }
  handleExportClick(panel) {
    let csvData = null;
    let filename = 'export.csv';
    let dateRangeSuffix = '';
    switch (panel) {
      case 'log':
        csvData = formatForCSV(this.props.logs.get('results'), [
          'label',
          'description',
          'type',
          'createdAt'
        ]);
        filename = `activity_${this.props.user.get('fullName')}`;
        break;
      case 'chat':
        csvData = formatForCSV(this.props.messages.get('results'), [
          'senderId',
          'senderType',
          'message',
          'important',
          'createdAt',
          'seen',
          'attachedPostId'
        ]);
        filename = `chat_${this.props.user.get(
          'fullName'
        )}_${this.getOtherChatParticipant(this.state.selectedConversation)}`;
        break;
      default:
        break;
    }
    if (csvData) {
      const dateRangeString = this.getDateRangeString(panel, 'YYYY-MM-DD');
      dateRangeSuffix = dateRangeString ? `_${dateRangeString}` : '';
      this.setState({
        [`${panel}ExportData`]: csvData,
        [`${panel}ExportFilename`]: filename.concat(dateRangeSuffix)
      });
    }
  }

  /**
   * Stores the user in state for the conversation view module and the edit profile module
   */
  storeUserInState() {

    this.setState({
      selectedUserId: this.props.user.get('id'),
      firstName: this.props.user.get('firstName'),
      lastName: this.props.user.get('lastName'),
      isDefaultAdmin: this.props.user.get('isDefaultAdmin'),
      webAccess: this.props.user.get('type') === 'A' ? true : false,
      birthDate: moment(this.props.user.get('birthDate')).isValid()
        ? moment(this.props.user.get('birthDate'))
        : null,
      emailAddress: this.props.user.get('emailAddress'),
      onboarding: false,
      picture: this.props.user.get('profilePicture'),
      notes: this.props.user.get('notes'),
      gender: this.props.user.get('gender'),
      phoneNumber: this.props.user.get('phoneNumber')
    });
  }

  async setup(user = Map()) {
    // Invalidate all the currently stored panel data
    await this.props.clearConversation();
    await this.props.clearLogs();
    await this.props.clearAccessedPosts();
    await this.props.getActivities({ userId: user.get('id') });
    await this.props.getTags({ userId: user.get('id') });
    // Get all of this user's conversations
    await this.props.getConversations(user.get('id'), {
      onSuccess: () => {
        // this.setState({
        //   selectedConversation: this.props.conversations.get('results').size
        //     ? this.props.conversations.get('results').get(0)
        //     : null
        // });
      }
    });

    // Get all of this users interaction logs
    await this.props.getLogs({
      userId: user.get('id'),
    });

    await this.props.getAccessedPosts({
      userId: user.get('id'),
    });

    // Store user in state to allow for editing
    this.storeUserInState();

    // Get all workers if this user is a youth
    if (this.props.user.get('type') === 'Y') {
      await this.props.getWorkers({ onboarded: true, includeAdmins: true, deleted: false });
    }
  }

  /**
   * Strips the HTML tags from a post description to render in chat bubble
   */
  stripHtml(str = '') {
    return str.replace(/<(?:.|\n)*?>/gm, '');
  }

  /**
   * Return the name of the user engaged in the current selected conversation with
   * this user. The other chat participate is the other party in the current selected
   * conversation.
   */
  getOtherChatParticipant(conversation) {
    // if (!this.state.selectedConversation) {
    //   return '';
    // }
    const otherUser =
      this.props.user.get('type') !== 'Y'
        ? conversation.get('youth')
        : conversation.get('worker');
    if (otherUser) return otherUser.get('fullName');
    return '';
  }

  changeConversation(e) {
    const selectedConvo = this.props.conversations
      .get('results')
      .find(conversation => conversation.get('id') == e.target.value);

    if (!selectedConvo) {
      return;
    }

    const options = {
      conversationId: selectedConvo.get('id'),
      invalidate: true,
      includeTranslations: true,
      onSuccess: () => {
        this.setState({
          selectedConversation: selectedConvo || Map()
        });
      }
    };

    // Apply the date range filter
    const dateRange = this.getDateRange('chat');

    if (dateRange) {
      options.startDate = dateRange[0];
      options.endDate = dateRange[1];
    }

    this.props.getMessages(options);

    // Clear chat CSV
    this.setState({
      chatExportData: null
    });
  }

  handleLinkPress(postId, postType) {
    let type = postType == 'R' ? 'Resource' : 'News';
    this.props.history.push('/post/' + type + '?updating=' + postId + '');
  }

  renderConversationsPanel() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="column-subheader">
          <a onClick={this.handleLaunchDateSelect.bind(this, 'chat')}>
            <FontAwesomeIcon
              icon={faCalendar}
              style={{ fontSize: 16, marginRight: 5 }}
            />
            Select a Date Range
          </a>

          <span>&nbsp;or&nbsp;</span>
          <a onClick={this.handleShowAll.bind(this, 'chat')}>
            Show All
          </a>
        </div>
        {// Show the selected date range
          this.getDateRange('chat') && (
            <div className="column-subheader">
              <span style={YADashboard.styles.dateHeader}>
                {this.getDateRangeString('chat')}
              </span>
            </div>
          )}
        <div className="column-subheader">
          <FontAwesomeIcon
            icon={faUser}
            style={{ fontSize: 16, marginRight: 5 }}
          />

          <span className="inline-label">Conversation with:</span>

          <YAInput
            type="select"
            className="form-control"
            onChange={this.changeConversation.bind(this)}
          >
            {this.props.conversations.get('results').map(conversation => (
              <option
                key={conversation.get('id')}
                id={conversation.get('id')}
                value={conversation.get('id')}
              >
                {this.getOtherChatParticipant(conversation)}
              </option>
            ))};
          </YAInput>
        </div>
        {/* Render conversation log */}
        <div style={YADashboard.styles.columnBody}>
          <YALoader
            alternate="Loading..."
            image={`${window.__configuration.s3}/icons/loader.gif`}
            show={this.props.messagesPending}
            type="dashboard"
          />
          {this.props.messages.get('results').map((message, index) => (
            <div key={index} className="chat-item">
              <MessageBubble
                message={message.get('message')}
                document={message.get('document')}
                picture={message.getIn(['picture', 'raw'])}
                timestamp={moment
                  .utc(message.get('createdAt'))
                  .local()
                  .format('YYYY-MM-DD h:mm a')}
                // Add person's name to chat bubble
                linkTitle={message.getIn(['attachedPost', 'title'])}
                linkDescription={message.getIn(['attachedPost', 'shortDescription']) ||
                  this.stripHtml(message.getIn(['attachedPost', 'description']))}
                chatParticipant={this.getOtherChatParticipant(
                  this.state.selectedConversation
                )}
                currentUser={this.props.user.get('fullName')}
                onPress={
                  message.get('attachedPost')
                    ? this.handleLinkPress.bind(
                      this,
                      message.getIn(['attachedPost', 'id']),
                      message.getIn(['attachedPost', 'type'])
                    )
                    : null
                }
                isSender={message.get('senderId') === this.props.user.get('id')}
                type={
                  message.get('important')
                    ? 'I'
                    : message.get('attachedPost') ? 'L' : 'N'
                }
              />
              {// Render message translations
                message
                  .get('translatedMessages')
                  .map((translatedMessage, index) => (
                    <MessageBubble
                      key={index}
                      message={translatedMessage.get('translation')}
                      language={translatedMessage.getIn(['language', 'name'])}
                      type="T"
                      isSender={
                        message.get('senderId') === this.props.user.get('id')
                      }
                    />
                  ))}
            </div>
          ))}
        </div>
      </div>
    );
  }

  handleChange(property, event) {
    // Fixes case when switch current language triggers this function
    if (!event) {
      return;
    }

    this.setState({
      [property]:
        typeof event === 'string'
          ? event
          : moment.isDate(event) ? moment(event) : (event.target && event.target.value)
    });
  }

  /**
   * Return the current state of the user. Available states are active, inactive
   * (if the user has been deleted), and pending (if the user has not yet onboarded)
   */
  getUserState() {
    if (this.props.user.get('deletedAt')) {
      return 'Inactive';
    }
    if (!this.props.user.get('onboarded')) {
      return 'Pending';
    }
    return 'Active';
  }

  handleSelectActivity(event) {
    if (!event.target) {
      return;
    }
    this.setState({
      activity: event.target.value
    });
  }
  handleAddTag() {
    if (this.state.activity !== undefined) {
      this.props.createTag({
        userId: this.props.user.get('id'), activityId: this.state.activity,
        onSuccess: () => {
          this.props.getActivities({ userId: this.props.user.get('id') });
        }
      });

      this.setState({
        activity: undefined
      });
    }
  }
  /**
   * Launch the date selector and store the panel that the date selector is picking dates for
   * (chat, log or posts accessed)
   */
  handleLaunchDateSelect(panel) {
    this.setState({
      dateSelectingFor: panel,
      newDateSelectorOpen: true
    });
  }

  /**
  * Updates an existing picture with a new one as the picture to upload for the post. Saves
  * this picture's rotation factor for preview orientation correction TODO: rotation factor
  *
  * @param {number} index
  * @param {object} picture
  * @param {number[]} rotationsArray // TODO:
  */
  updatePicture(picture, rotationsArray) {
    this.props.updateUserProfilePicture({
      id: this.props.user.get('id'),
      picture
    });
  }

  /**
   * Removes a user's profile picture
   *
   * @param {number} index - Index value of the picture to remove from the list
   */
  removePicture() {
    this.props.updateUserProfilePicture({
      id: this.props.user.get('id')
    });
  }

  getDateRangeString(panel, format = 'MM/DD/YY') {
    const range = this.state[`${panel}DateRange`];
    if (!Array.isArray(range) || range.length !== 2) {
      return null;
    }
    const start = moment(range[0]).format(format);
    const end = moment(range[1]).format(format);
    return `${start}-${end}`;
  }

  /**
   * Get the date range for panel
   */
  getDateRange(panel) {
    const range = this.state[`${panel}DateRange`];
    if (!Array.isArray(range) || range.length !== 2) {
      return null;
    }
    return this.state[`${panel}DateRange`];
  }

  /**
   * Refetch items without date filters and limit
   */
  handleShowAll(panel) {
    this.setState({
      [`${panel}DateRange`]: null
    });

    switch (panel) {
      case 'post':
        this.props.getAccessedPosts({
          userId: this.props.user.get('id'),
          all: true
        });
        break;

      case 'chat':
        this.state.selectedConversation &&
          this.props.getMessages({
            conversationId: this.state.selectedConversation.get('id'),
            invalidate: true,
            all: true,
            includeTranslations: true
          });
        break;

      case 'log':
        // Get all of this users interaction logs
        this.props.getLogs({
          userId: this.props.user.get('id'),
          all: true
        });

        break;

      default:
        break;
    }
  }
  /**
   * Save the dates picked in the date selector for the specific panel. Refetch the items filtered
   * by the date range
   */
  handleDateSelect(start, end) {
    const panel = this.state.dateSelectingFor;
    this.setState({
      [`${panel}DateRange`]: [start, end],
      newDateSelectorOpen: false
    });

    switch (panel) {
      case 'post':
        this.props.getAccessedPosts({
          userId: this.props.user.get('id'),
          startDate: start,
          endDate: end
        });
        break;

      case 'chat':
        this.state.selectedConversation &&
          this.props.getMessages({
            conversationId: this.state.selectedConversation.get('id'),
            includeTranslations: true,
            startDate: start,
            endDate: end
          });

        // Clear CSV data
        this.setState({
          chatExportData: null
        });

        break;

      case 'log':
        // Get all of this users interaction logs
        this.props.getLogs({
          userId: this.props.user.get('id'),
          startDate: start,
          endDate: end
        });

        // Clear CSV data
        this.setState({
          logExportData: null
        });

        break;

      default:
        break;
    }
  }

  hasNextConversation() {
    const conversations = this.props.conversations.get('results');
    const currentConversationIndex = conversations.findIndex(
      conversation =>
        conversation.get('id') === this.state.selectedConversation.get('id')
    );
    return (
      currentConversationIndex !== -1 &&
      currentConversationIndex !== conversations.size - 1
    );
  }

  hasPrevConversation() {
    const conversations = this.props.conversations.get('results');
    const currentConversationIndex = conversations.findIndex(
      conversation =>
        conversation.get('id') === this.state.selectedConversation.get('id')
    );
    return currentConversationIndex !== -1 && currentConversationIndex !== 0;
  }

  /**
   * Handles a simple checkbox check event by updating the state property with the
   * new checked state. Applicable the state properties with
   * a key: bool key value pair.
   *
   * @param {Map(object)} category to add or remove to/ from state
   * @param {event}
   */
  handleCheck(property, event) {
    this.setState({
      [property]: event.target.checked
    });
  }

  getFieldValue(property) {
    // YAInput doesnt want null values some of state is null, error asked for undefined instead
    if (this.state[property] === null) {
      return undefined;
    }
    return this.state[property];
  }

  updateUser() {
    // Make sure the form is valid before proceeding
    if (!this.state.emailAddress && this.state.webAccess) {
      alert('If you would like to provide web access to this user, please update this user\'s email address');
      //dont update the user to admin if there is no email associated to the user.
      return;
    }
    this.props.updateUser({
      id: this.state.selectedUserId,
      ...this.state,
      webAccess: (this.props.user.get('type') === 'Y' || this.props.user.get('type') === 'C') ? undefined : this.state.webAccess
    });
    this.setState({
      editing: !this.state.editing
    });
  }

  renderProfileEdit() {
    return (
      <aside>
        <h3>EDIT PROFILE:</h3>

        <h1>{this.props.user.get('fullName')}</h1>

        <div className="form-group">
          <label>Email Address:</label>

          <YAInput
            type="text"
            onChange={this.handleChange.bind(this, 'emailAddress')}
            className="form-control"
            value={this.getFieldValue('emailAddress')}
          />
        </div>

        <div className="form-group">
          <label>First Name:</label>

          <YAInput
            type="text"
            onChange={this.handleChange.bind(this, 'firstName')}
            className="form-control"
            value={this.getFieldValue('firstName')}
          />
        </div>

        <div className="form-group">
          <label>Last Name:</label>

          <YAInput
            type="text"
            onChange={this.handleChange.bind(this, 'lastName')}
            className="form-control"
            value={this.getFieldValue('lastName')}
          />
        </div>

        <div className="form-group">
          <label>Notes:</label>

          <YAInput
            type="textarea"
            onChange={this.handleChange.bind(this, 'notes')}
            className="form-control"
            value={this.getFieldValue('notes')}
          />
        </div>

        <div className="form-group">
          <label>Phone:</label>

          <YAInput
            type="text"
            onChange={this.handleChange.bind(this, 'phoneNumber')}
            className="form-control"
            value={this.getFieldValue('phoneNumber')}
          />
        </div>

        <div className="form-group">
          <label>Date Of Birth:</label>

          <YAInput
            type="date"
            onChange={this.handleChange.bind(this, 'birthDate')}
            className="form-control"
            value={this.state.birthDate}
          />
        </div>

        <div className="form-group">
          <label>Tags:</label>
          <div className="inline-form">
            <YAInput
              type="select"
              list="activities"
              onChange={this.handleSelectActivity.bind(this)}
              className="form-control"
              id="activities">
              <option>Select a tag:</option>
              {this.props.activities.get('results').map((activity, index) => (
                <option key={index} value={activity.get('id')}>
                  {activity.get('name')}
                </option>
              ))}
            </YAInput>
            <YAButton type="primary" onClick={this.handleAddTag.bind(this)} >
              Add
            </YAButton>
          </div>
          <ul className="tags-list">
            {this.props.tags.toJS().results.map(
              (tag, key) =>
                <li key={key}>
                  {tag.name}
                  <button type="text" className="btn btn-link" onClick={this.deleteTag.bind(this, tag.tags[0].id)}>X</button>
                </li>
            )}
          </ul>
        </div>

        {// Render default admin checkbox if user is an admin
          (this.props.user.get('type') !== 'C' && this.props.user.get('type') !== 'Y') && (
            <div className="form-group">
              <div className="inline-switch-label">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={this.state.webAccess}
                    onChange={this.handleCheck.bind(this, 'webAccess')}
                  />
                  <span className="slider round" />
                </label>
                <span>Web Access</span>
              </div>
              <p style={{ marginTop: 10 }}><span className="bold">Note:</span> If active, this worker will have access to pointbapp.com </p>
            </div>
          )}

        <div className="form-group">
          <YAButton
            type="primary"
            onClick={this.updateUser.bind(this)}
            style={{ width: 'auto' }}
          >
            Save Changes
          </YAButton>
          <YAButton
            onClick={() => this.setState({ editing: false })}
            type="cancel"
            style={{ width: 'auto' }}
          >
            Cancel
          </YAButton>
        </div>
      </aside>
    );
  }

  renderProfileColumn() {
    const errorMessage = this.props.updateUserError || this.props.updateUserProfilePictureError;
    const desktop = window.innerWidth > 959;

    const editProfile = (
      this.props.user.get('deletedAt') === null && <YAButton
        onClick={() => this.setState({ editing: true })}
        type="primary"
        style={{ width: 'auto' }}
      >
        Edit Profile
      </YAButton>
    );

    return (
      <aside>
        <div onClick={() => this.goBack()} className="back">
          Back
        </div>

        {// Show the current error message for updating a user if there is one
          errorMessage ? (
            <div style={{ marginBottom: `${defaults.gutter / 2}px` }}>
              <YAAlert type="E">{errorMessage}</YAAlert>
            </div>
          ) : null}

        <YAPictureDropzone
          onDeleteClick={this.removePicture.bind(this)}
          onDrop={this.updatePicture.bind(this)}
          // rotate={this.state.rotations.get(index)} TODO:
          preview={this.state.picture && this.state.picture.get('normal')}
          showDeleteButton
          style={YADashboard.styles.picture}
        />

        <YALoader
          alternate="Loading..."
          image={`${window.__configuration.s3}/icons/loader.gif`}
          show={this.props.userPending}
          type="dashboard"
        />

        <div onClick={() => {
          if (this.state.mobileSelectedHeader === "Profile") {
            this.setState({ mobileSelectedHeader: "" });
          }
          else { this.setState({ mobileSelectedHeader: "Profile" }); }

        }}
          className={this.state.mobileSelectedHeader === "Profile" ? "mobile-header active" : "mobile-header"}
        >
          <span>Profile</span>

          <FontAwesomeIcon
            icon={this.state.mobileSelectedHeader === "Profile" ? faCaretUp : faCaretDown}
            style={{ fontSize: 16, marginRight: 5 }}
          />
        </div>

        <div className={this.state.mobileSelectedHeader === "Profile" ? "" : "hidden-mobile"}>
          {desktop === false && editProfile}

          <h2>{this.props.user.get('fullName')}</h2>

          <p style={{ fontSize: 15, marginBottom: 20 }}>
            {this.props.user.get('phoneNumber')}
          </p>
          <div className="form-group">
            <label>Current Status</label>
            {this.props.user.get('online') == true ? (
              <p>
                <span className="status-dot" /> Online
              </p>
            ) : (
              <p>
                <span className="status-dot offline" /> Offline
              </p>
            )}
          </div>

          <div className="form-group">

            <label>Favourited Users</label>

            {this.props.user.get('faveWorkers') &&
              <p>

                {
                  this.props.user.get('faveWorkers').map(workers =>
                    // eslint-disable-next-line react/jsx-key
                    <Link to={`/user/${workers.toJS().workerId}`}>
                      <p>{(workers.toJS().workers.fullName)}</p>
                    </Link>)
                }
              </p>
            }

            {this.props.user.get('faveYouths') &&
              <p>

                {
                  this.props.user.get('faveYouths').map(youths =>
                    // eslint-disable-next-line react/jsx-key
                    <Link to={`/user/${youths.toJS().youthId}`}>
                      <p>{(youths.toJS().youths.fullName)}</p>
                    </Link>)
                }
              </p>
            }
          </div>

          <div className="form-group">
            <label>Tags</label>
            {this.props.tags.toJS().results.map(
              (tag, key) =>
                <p key={key} style={{ fontSize: 15, marginBottom: 20 }}>
                  {(tag) ? tag.name : null}
                </p>
            )}
          </div>

          <div className="form-group">
            <label>State</label>

            <p style={{ fontSize: 15, marginBottom: 20 }}>
              {// TODO: allow editing stating
                this.getUserState()}
            </p>
          </div>

          {desktop !== false && editProfile}
        </div>

        {/* Render loading indicator if user update is pending */}

        <YALoader
          alternate="Loading..."
          image={`${window.__configuration.s3}/icons/loader.gif`}
          show={
            this.props.updateUserPending ||
            this.props.updateUserProfilePicturePending
          }
        />
      </aside>
    );
  }

  render() {
    if (!this.props.user) {
      return (<div />);
    }
    return (
      <div
        className="main user-profile"
        onClick={() => {
          if (this.state.showPostTypePopover) {
            setState.call(this, 'showPostTypePopover', false);
          }
        }}
      >
        {/* Render profile column */}
        {this.state.editing
          ? this.renderProfileEdit()
          : this.renderProfileColumn()}

        <div className="column-wrapper">
          {/* Render activity log column */}
          <section className="column short">
            <div onClick={() => {
              if (this.state.mobileSelectedHeader === "Activity") {
                this.setState({ mobileSelectedHeader: "" });
              }
              else { this.setState({ mobileSelectedHeader: "Activity" }); }
            }
            } className={this.state.mobileSelectedHeader === "Activity" ? "mobile-header active" : "mobile-header"}>
              <span>
                Activity Log
              </span>

              <FontAwesomeIcon
                icon={this.state.mobileSelectedHeader === "Activity" ? faCaretUp : faCaretDown}
                style={{ fontSize: 16, marginRight: 5 }}
              />
            </div>

            <div className={this.state.mobileSelectedHeader === "Activity" ? "" : "hidden-mobile"}>
              <div className="column-header">
                <span>Activity Log</span>

                {// Render either the export button or the download export button

                  // Download the log export data if it is ready
                  this.state.logExportData ? (
                    <CSVLink
                      style={{ paddingBottom: '1%' }}
                      data={this.state.logExportData}
                      target="_self"
                      filename={this.state.logExportFilename}
                    >
                      Download ⬇
                    </CSVLink>
                  ) : (
                    <YAButton
                      onClick={this.handleExportClick.bind(this, 'log')}
                      type="primary"
                    >
                      Export
                    </YAButton>
                  )}
              </div>

              <div className="column-subheader">
                <a
                  onClick={this.handleLaunchDateSelect.bind(this, 'log')}
                >
                  <FontAwesomeIcon
                    icon={faCalendar}
                    style={{ fontSize: 16, marginRight: 5 }}
                  />
                  Select a Date Range
                </a>

                <span>&nbsp;or&nbsp;</span>
                <a onClick={this.handleShowAll.bind(this, 'log')}>
                  Show All
                </a>
              </div>
              {// Show the selected date range
                this.getDateRange('log') && (
                  <div className="column-subheader">
                    <span style={YADashboard.styles.dateHeader}>
                      {this.getDateRangeString('log')}
                    </span>
                  </div>
                )}
              <div style={YADashboard.styles.columnBody}>
                <YALoader
                  alternate="Loading..."
                  image={`${window.__configuration.s3}/icons/loader.gif`}
                  show={this.props.logsPending}
                  type="dashboard"
                />
                {/* TODO: If activity log item is new, add 'new' class to log-item */}
                {this.props.logs.get('results').map((log, index) => (
                  <div key={index} className="log-item new">
                    <span className="timestamp">
                      {moment(log.get('createdAt')).format('YYYY-MM-DD hh:mm a')}
                    </span>
                    <p>{log.get('label')}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Render chat column */}
          <section className="column short">
            <div
              onClick={() => {
                if (this.state.mobileSelectedHeader === "Recent Chat Sessions") { this.setState({ mobileSelectedHeader: "" }); }
                else { this.setState({ mobileSelectedHeader: "Recent Chat Sessions" }); }
              }}
              className={this.state.mobileSelectedHeader === "Recent Chat Sessions" ? "mobile-header active" : "mobile-header"}>
              <span>Recent Chat Sessions</span>

              <FontAwesomeIcon
                icon={this.state.mobileSelectedHeader === "Recent Chat Sessions" ? faCaretUp : faCaretDown}
                style={{ fontSize: 16, marginRight: 5 }}
              />
            </div>

            <div className={this.state.mobileSelectedHeader === "Recent Chat Sessions" ? "" : "hidden-mobile"}>
              <div className="column-header">
                <span>Recent Chat Sessions</span>

                {// Download the chat export data if it is ready
                  this.state.chatExportData ? (
                    <CSVLink
                      style={{ paddingBottom: '1%' }}
                      data={this.state.chatExportData}
                      filename={this.state.chatExportFilename}
                    >
                      Download ⬇
                    </CSVLink>
                  ) : (
                    <YAButton
                      onClick={this.handleExportClick.bind(this, 'chat')}
                      type="primary"
                    >
                      Export
                    </YAButton>
                  )}
              </div>
              {this.renderConversationsPanel()}
            </div>
          </section>

          {/* Render posts accessed column */}
          <section className="column short">
            <div
              onClick=
              {() => {
                if (this.state.mobileSelectedHeader === "Resources Accessed") {
                  this.setState({ mobileSelectedHeader: "" });
                }
                else { this.setState({ mobileSelectedHeader: "Resources Accessed" }); }
              }}
              className={this.state.mobileSelectedHeader === "Resources Accessed" ? "mobile-header active" : "mobile-header"}>
              <span>Resources Accessed</span>

              <FontAwesomeIcon
                icon={this.state.mobileSelectedHeader === "Resources Accessed" ? faCaretUp : faCaretDown}
                style={{ fontSize: 16, marginRight: 5 }}
              />
            </div>

            <div className={this.state.mobileSelectedHeader === "Resources Accessed" ? "" : "hidden-mobile"}>
              <div className="column-header">
                <span>Resources Accessed</span>
              </div>

              <div className="column-subheader">
                <a
                  onClick={this.handleLaunchDateSelect.bind(this, 'post')}
                >
                  <FontAwesomeIcon
                    icon={faCalendar}
                    style={{ fontSize: 16, marginRight: 5 }}
                  />
                  Select a Date Range
                </a>

                <span>&nbsp;or&nbsp;</span>

                <a onClick={this.handleShowAll.bind(this, 'post')}>
                  Show All
                </a>
              </div>

              {// Show the selected date range
                this.getDateRange('post') && (
                  <div className="column-subheader">
                    <span style={YADashboard.styles.dateHeader}>
                      {this.getDateRangeString('post')}
                    </span>
                  </div>
                )}

              {/* TODO: Hook up resource items, add 'new' class if item is recently added */}
              <div style={YADashboard.styles.columnBody}>
                <YALoader
                  alternate="Loading..."
                  image={`${window.__configuration.s3}/icons/loader.gif`}
                  show={this.props.accessedPostsPending}
                  type="dashboard"
                />
                {this.props.accessedPosts.get('results').map((log, index) => (
                  <div onClick={this.handleLinkPress.bind(this, log.getIn(['post', 'id']), log.getIn(['post', 'type']))} key={index} className="resource-item new">
                    { /* <div className="thumbnail" /> is this supposed to be the resource pic? */}
                    <div>
                      <span className="timestamp">
                        {moment(log.get('createdAt')).format(
                          'YYYY-MM-DD hh:mm a'
                        )}
                      </span>

                      <span className="title">
                        {log.getIn(['post', 'title'])}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {
            <YADateSelector
              header="Select a Date Range"
              start={
                this.getDateRange(this.state.dateSelectingFor) &&
                this.getDateRange(this.state.dateSelectingFor)[0]
              }
              end={
                this.getDateRange(this.state.dateSelectingFor) &&
                this.getDateRange(this.state.dateSelectingFor)[1]
              }
              onSaveClick={this.handleDateSelect.bind(this)}
              onCloseClick={setState.bind(this, 'newDateSelectorOpen', false)}
              show={this.state.newDateSelectorOpen}
              selectorType={this.state.dateSelectingFor}
            />
          }
        </div>
      </div>
    );
  }
}

export default connect(
  YADashboard.mapStateToProps,
  YADashboard.mapDispatchToProps
)(withRouter(Radium(YADashboard)));
