// Load dependencies
import { call, fork, take, takeLatest, all } from 'redux-saga/effects';
import * as actions from './../actions/language';

/**
 * Watches for the newest request to get the site settings.  Forwards the request
 * to the handler.
 */
function* watchGetLanguages() {
  yield* takeLatest(actions.GET_LANGUAGES, getLanguages);
}

/**
 * Sends an action to request the server to retrieve the list of settings.
 */
function* getLanguages() {
  while (true) {
    // Get the request parameters from the action
    const { onFailure, onSuccess } = yield take(actions.GET_LANGUAGES);

    // Attempt to create the new location
    try {
      yield call(actions.getLanguages);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      if (typeof onFailure === 'function') onFailure();
    }
  }
}

/**
 * Start all of the sagas in this module.
 */
export default function* root() {
  yield all([
    fork(watchGetLanguages),
    fork(getLanguages)
  ]);
}
