/* global __NOOP__ */

// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import { brand, mediaQueries } from './../styles';
const Promise = require('bluebird');
const findOrientation = Promise.promisify(require('exif-orientation'));

// Load components
import Dropzone from 'react-dropzone';

/**
* Renders a dropzone which accepts a picture as an input and returns the
* file that is dropped or selected.  Allows a preview of the image to be
* displayed.
*/
class YAPictureDropzone extends Component {

  // Properties that are passed to the picture dropzone component
  static propTypes = {
    ...Dropzone.propTypes,
    onDeleteClick: PropTypes.func,
    preview: PropTypes.string,
    rotate: PropTypes.number,
    showDeleteButton: PropTypes.bool
  };

  // Default property values of the picture dropzone component
  static defaultProps = {
    ...Dropzone.defaultProps,
    onDeleteClick: __NOOP__,
    rotate: 0,
    showDeleteButton: false
  };

  // Styles for the picture dropzone component
  static styles = {
    base: {
      display: 'block',
      height: '250px',
      width: '100%',
      position: 'relative',
      transition: 'background-color 0.25s linear, border 0.25s linear',
      margin: 'auto',
      flexShrink: 0,
      backgroundColor: '#ccc',
      cursor: 'pointer',
      marginTop: 10
    },
    error: {
      backgroundColor: brand.primary.lighten
    },
    success: {
      backgroundColor: brand.success.lighten
    },
    interior: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%'
    },
    previewBase: {
      display: 'block',
      height: '100%',
      width: '100%',
    },
    preview: {
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    },
    placeholder: {
      display: 'block',
      flex: 1,
      height: 'auto',
      width: '30%'
    },
    close: {
      background: brand.primary.base,
      position: 'absolute',
      width: 22,
      height: 22,
      padding: 0,
      top: 10,
      right: 10,
      borderRadius: '50%',
      textAlign: 'center',
      color: '#fff',
      lineHeight: 1.5,
      fontWeight: 700,
      fontSize: 14,
      cursor: 'pointer'
    }
  };

  // Get the placeholder image link
  static uploadPlaceholder = `${window.__configuration.s3}/icons/upload.svg`;

  /**
   * Handles the delete button click, stops propagation to opening the file selector.
   *
   * @param {object} event
   */
  handleDeleteClick(event) {
    event.stopPropagation();
    this.props.onDeleteClick();
  }

  /**
  * When a file is dropped it is sent to the onDrop function.  We use this function
  * to bypass the dropped files being returned as an array and to determine the rotation factor.
  */
  async handleDrop(files) {
    var rotations = [];
    try {
      await Promise.all(files.map(async (file) => {
        let orientation = await findOrientation(file);
        let rotation = orientation.rotate;
        rotations.push(rotation);
        file.preview = URL.createObjectURL(file);
      }));
    }
    catch (err) {
      rotation = 0;
    }
    return this.props.onDrop(files[0], rotations, files);
  }

  render() {
    const rotate = this.props.rotate;

    // Adjusts preview image orientation
    var rotateStyle = {
      transform: `rotate(${rotate}deg)`
    };

    // Adjusts container size for 90 degree reoriented preview image
    var reorientBase = {
      minHeight: '260px',
      width: '320px',
    };

    return (
      <Dropzone
        accept={"image/*"}
        activeStyle={{ ...YAPictureDropzone.styles.base, ...YAPictureDropzone.styles.success }}
        key="dropzone"
        onDrop={this.handleDrop.bind(this)}
        rejectStyle={{ ...YAPictureDropzone.styles.base, ...YAPictureDropzone.styles.error }}
        style={_.assign({}, YAPictureDropzone.styles.base, this.props.style,
          (rotate === 90 || rotate === -90) && reorientBase)}
        className="dropzone"
      >
        {!this.props.preview ?
          <div style={YAPictureDropzone.styles.interior}>
            <img
              src={YAPictureDropzone.uploadPlaceholder}
              style={YAPictureDropzone.styles.placeholder}
            />
          </div> :

          // Render the preview image if one is provided
          <div style={[YAPictureDropzone.styles.interior, YAPictureDropzone.styles.previewBase]}>
            <div
              className="photo-preview"
              style={[
                YAPictureDropzone.styles.interior,
                YAPictureDropzone.styles.preview, {
                  backgroundImage: `url(${this.props.preview})`,
                },
                rotateStyle
              ]}
            />
            {
              this.props.showDeleteButton ?
                <span
                  onClick={this.handleDeleteClick.bind(this)}
                  style={YAPictureDropzone.styles.close}
                >
                  &times;
                </span> :
                null
            }
          </div>}
      </Dropzone>
    );
  }
}

// Export component, connected to Radium
export default Radium(YAPictureDropzone);