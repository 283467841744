// Load dependencies
import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import packageJson from '../../../package.json';

// Load selectors
import { getProfile, isLoggedIn } from './../../selectors/authenticator';

// Load actions
import { LOGOUT, IS_LOGGED_IN } from './../../actions/authenticator';

// Load components
import YALoader from './../../components/loader/loader';

/**
* Renders the non login pages by injecting the header at the top and rendering the
* contents of the current page.
*/
class YAMain extends Component {

  /**
  * Determines which items the container needs from the store for rendering this
  * page.  These items are injected into the properties of the page.
  */
  static mapStateToProps(state) {
    return {
      profile: getProfile(state),
      isLoggedIn: isLoggedIn(state),
    };
  }

  /**
  * Determines which actions this page requires and those action dispatchers will
  * be injected into the properties of the page.
  */
  static mapDispatchToProps(dispatch) {
    return {
      // getApplicationInformation: () => dispatch({ type: GET_APPLICATION_INFORMATION }),
      logout: () => dispatch({ type: LOGOUT }),
      checkIsLoggedIn: (options) => dispatch({ type: IS_LOGGED_IN, ...options }),
    };
  }

  // Properties that are passed to the main header
  static propTypes = {
    children: PropTypes.element.isRequired,
    logout: PropTypes.func.isRequired,
    profile: PropTypes.instanceOf(Map),
    location: PropTypes.object.isRequired,
    checkIsLoggedIn: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
  };

  // Initial state for the main header
  state = {
    menuMouseDown: false,
    navigationMouseDown: false,
    hideMenu: true,
    hideProfileMenu: true,
  };

  componentDidMount() {
    this.props.checkIsLoggedIn({
      onLoggedIn: () => {
      },
      onFailure: () => {
        this.props.history.push('/login');
      },
      onNotLoggedIn: () => {
        this.props.history.push('/login');
      }
    });
  }

  componentDidUpdate() {
  }

  /**
   * Handle the logic that should run before the component is completely unmounted.
   */
  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleMouseDown, false);
    window.removeEventListener('mousedown', this.handleMouseDownProfile, false);
  }

  getPageName() {
    let pathName = this.props.location.pathname;

    // Case for when parameters are passed in path (i.e /user/{userId})
    if (typeof pathName === 'string' && pathName.split('/').includes('user')) {
      pathName = '/user';
    }

    switch (pathName) {
      case '/tags':
        return 'Tags';
      case '/post/news':
      case '/news':
        return 'News';
      case '/post/resource':
      case '/resource':
        return 'Resources';
      case '/users/workers':
        return 'Outreach Workers';
      case '/users/youth':
      case '/':
        return 'Youth';
      case '/user':
        return 'User';
      default:
        return 'YMCA PointB Administration Panel';
    }
  }

  /**
   * Closes the mobile menu.
  **/
  closeMobileMenu() {
    // Only hide the menu if it is currently visible (to save some ticks)
    if (!this.state.hideMenu) {
      this.setState({
        hideMenu: true
      });
    }

    if (!this.state.hideProfileMenu) {
      this.setState({
        hideProfileMenu: true
      });
    }
  }

  /**
   * Handles the mobile menu mouse down event.  Saves that the mouse was clicked
   * down on the hamburger button.  Serves mostly as a check for when the mouse
   * button is later raised.
   */
  handleMobileMenuMouseDown() {
    this.setState({
      menuMouseDown: true
    });
  }

  /**
   * Handles the mobile menu mouse up event.  Checks if the user did a mouse down
   * click on the button and if it did then we want to toggle the menu open and
   * closed.  This allows us to listen to the entire screen for a mouse down
   * event.
   */
  handleMobileMenuMouseUp() {
    this.setState({
      menuMouseDown: false,
      hideMenu: this.state.menuMouseDown ? !this.state.hideMenu : true
    });
  }

  handleMobileProfileMenuMouseUp() {
    this.setState({
      menuMouseDown: false,
      hideProfileMenu: this.state.menuMouseDown ? !this.state.hideProfileMenu : true
    });
  }

  /**
  * Checks if the user is clicking on the dropdowns and if they are not we close
  * the dropdowns.  This emulates the behaviour of a select box.
  */
  handleMouseDown() {
    this.setState({
      hideMenu:
        this.state.menuMouseDown || this.state.navigationMouseDown
          ? this.state.hideMenu
          : true
    });
  }

  handleMouseDownProfile() {
    this.setState({
      hideProfileMenu:
        this.state.menuMouseDown || this.state.navigationMouseDown
          ? this.state.hideProfileMenu
          : true
    });
  }

  /**
  * Submits a request to log the user out of their account.  They are returned
  * to the login page at the same time.
  */
  logout() {
    return this.props.logout();
  }

  render() {
    return (
      <div className="canvas page-height">
        {/* Render the loading spinner if a request is currently pending */}
        <YALoader
          alternate="Loading..."
          image={`${window.__configuration.s3}/icons/loader.gif`}
          show={(!this.props.profile)}
        />

        <header>

          <div
            className={classNames('overlay', { hideme: this.state.hideProfileMenu })}
          >
            <img
              src={`${window.__configuration.s3}/icons/close-nav.svg`}
              className="close"
              onClick={() => this.setState({ hideProfileMenu: true })}
            />

            {/* Render the navigational menu for the staff member */}
            <span
              className="avatar"
              onMouseDown={this.handleMobileMenuMouseDown.bind(this)}
              onMouseUp={this.handleMobileProfileMenuMouseUp.bind(this)}
              style={{
                backgroundImage: `url(${window.__configuration
                  .s3
                  } / logos / ymca - logo.png)`
              }}
            />

            <nav
              className="nav"
              onMouseDown={() => this.setState({ navigationMouseDown: true })}
              onMouseUp={() => this.setState({ navigationMouseDown: false })}
            >
              <span className="welcome">Welcome,</span>
              <span className="email">{this.props.profile && this.props.profile.get('emailAddress')}</span>

              <hr />

              <ul>
                <li>
                  <Link to="/login" onClick={this.logout.bind(this)}>
                    Sign Out
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          <div
            className={classNames('overlay', { hideme: this.state.hideMenu })}
          >
            <img
              src={`${window.__configuration.s3}/icons/close-nav.svg`}
              className="close"
              onClick={() => this.setState({ hideMenu: true })}
            />

            {/* Render the navigational menu for the staff member */}
            <img
              src={`${window.__configuration.s3}/logos/logo.png`}
              className="login-logo"
              alt="Point B - You are here."
            />

            <nav
              className="nav"
              onMouseDown={() => this.setState({ navigationMouseDown: true })}
              onMouseUp={() => this.setState({ navigationMouseDown: false })}
            >
              <ul>
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/users/youth"
                    onClick={this.closeMobileMenu.bind(this)}
                  >
                    Youth
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    activeClassName="active"
                    to="/users/adults"
                    onClick={this.closeMobileMenu.bind(this)}
                  >
                    Adults
                  </NavLink>
                </li>

                {this.props.profile && this.props.profile.get("type") === 'A' ? null :
                  <div>
                    <li>
                      <NavLink
                        activeClassName="active"
                        to="/users/workers"
                        onClick={this.closeMobileMenu.bind(this)}
                      >
                        Outreach Workers
                      </NavLink>
                    </li>
                  </div>
                }
                {/* Render the classroom listings page */}
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/resource"
                    onClick={this.closeMobileMenu.bind(this)}
                  >
                    Resources
                  </NavLink>
                </li>

                {/* Render the students listings page */}
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/news"
                    onClick={this.closeMobileMenu.bind(this)}
                  >
                    News
                  </NavLink>
                </li>

                <li>  <NavLink
                  activeClassName="active"
                  to="/tags"
                  onClick={this.closeMobileMenu.bind(this)}
                >
                  Tags
                </NavLink>
                </li>

              </ul>
            </nav>
          </div>

          <div className="page-title">
            {/* Render hamburger menu icons */}
            <span
              className="hamburger"
              onMouseDown={this.handleMobileMenuMouseDown.bind(this)}
              onMouseUp={this.handleMobileMenuMouseUp.bind(this)}
            >
              <span />
            </span>

            <span className="page-name">{this.getPageName()}</span>
          </div>
          <span className="profile">
            <div className="user-info">
              <p className="username">
                Welcome {this.props.profile && this.props.profile.get('emailAddress')}
              </p>
              <p className="version">v{packageJson.version}</p> {/* TODO: Get version number from API */}

            </div>

            <span
              className="avatar"

              onMouseDown={this.handleMobileMenuMouseDown.bind(this)}
              onMouseUp={this.handleMobileProfileMenuMouseUp.bind(this)}
              style={{
                backgroundImage: `url(${window.__configuration
                  .s3
                  } / logos / ymca - logo.png)`
              }}
            />
          </span>

        </header>

        <main id="content" role="main">
          {this.props.isLoggedIn && this.props.children}
        </main>
      </div>
    );
  }
}

export default connect(YAMain.mapStateToProps, YAMain.mapDispatchToProps)(
  withRouter(YAMain)
);
