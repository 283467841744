// Load dependencies
import { call, fork, take, all } from 'redux-saga/effects';
import * as actions from './../actions/category';

/**
* Gets the categories from the server sets it to the store so that it can be displayed.
* Sets the meta information to the store as well so that the categories can be paginated through.
*/
function* getCategories() {
  while (true) {
    // Get the request parameters from the caller
    const params = yield take(actions.GET_CATEGORIES);

    try {
      // Send a request to get the categories from the server
      yield call(actions.getCategories, params);

      // Call the success handler if one is provided
      if (typeof params.onSuccess === 'function') {
        params.onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the failure handler if one is provided
      if (typeof onFailure === 'function') {
        params.onFailure();
      }
    }
  }
}

/**
* Creates a new category on the server given a location, student or classroom
* tags and any extra information about the category to create.  This category is not
* added to the store, but can be added by navigating to the category to find it.
*/
function* createCategory() {
  while (true) {
    const { name, onFailure, onSuccess } = yield take(actions.CREATE_CATEGORY);

    try {
      yield call(actions.createCategory, {
        name
      });

      // Call the on success handler if there is one
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the on failure handler if there is one
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
  }
}

/**
 * Updates an existing category on the server with any new information and stores
 * the updates in the state if the request is successful.
 */
function* updateCategory() {
  while (true) {
    // Extract the parameters from the action
    const parameters = yield take(actions.UPDATE_CATEGORY);

    try {
      // Attempt to update the category on the server with the parameters
      yield call(actions.updateCategory, parameters.categoryId, parameters);

      // Call the success handler if there is one provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      // Call the failure handler if there is one provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
* Deletes a category from the server and removes it from the store in the list
* if the request is successful.
*/
function* deleteCategory() {
  while (true) {
    // Grab the parameters for the request from the action
    const { onFailure, onSuccess, categoryId } = yield take(
      actions.DELETE_CATEGORY
    );

    try {
      // Send the request to remove the category on the server
      yield call(actions.deleteCategory, categoryId);

      // Call the on success handler if there is one
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      // Call the on failure handler if there is one
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
  }
}

/**
* Start all of the sagas in this module.
*/
export default function* root() {
  yield all([
    fork(getCategories),
    fork(createCategory),
    fork(updateCategory),
    fork(deleteCategory)
  ]);
}