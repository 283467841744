// Load dependencies
import request from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { call, put } from 'redux-saga/effects';

// Export the actions that are available for the users
export const GET_WORKERS = 'GET_WORKERS';
export const GET_WORKERS_REQUEST = 'GET_WORKERS_REQUEST';
export const GET_WORKERS_SUCCESS = 'GET_WORKERS_SUCCESS';
export const GET_WORKERS_FAILURE = 'GET_WORKERS_FAILURE';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_USER = 'GET_USER';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';
export const CLEAR_USER_ERROR_REQUEST = 'CLEAR_USER_ERROR_REQUEST';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE';

export const UPDATE_USER_PROFILE_PICTURE = 'UPDATE_USER_PROFILE_PICTURE';
export const UPDATE_USER_PROFILE_PICTURE_REQUEST =
  'UPDATE_USER_PROFILE_PICTURE_REQUEST';
export const UPDATE_USER_PROFILE_PICTURE_SUCCESS =
  'UPDATE_USER_PROFILE_PICTURE_SUCCESS';
export const UPDATE_USER_PROFILE_PICTURE_FAILURE =
  'UPDATE_USER_PROFILE_PICTURE_FAILURE';
export const UPDATE_USER_ONBOARDED = 'UPDATE_USER_ONBOARDED_STATUS';
export const UPDATE_USER_ONBOARDED_REQUEST = 'UPDATE_USER_ONBOARDED_REQUEST';
export const UPDATE_USER_ONBOARDED_SUCCESS = 'UPDATE_USER_ONBOARDED_SUCCESS';
export const UPDATE_USER_ONBOARDED_FAILURE = 'UPDATE_USER_ONBOARDED_FAILURE';

/**
* Submits a request to the server to get a list of outreach workers that are stored on
* the server with some extra information about those workers.  Allows query
* parameters to paginate through the users and a name query parameter to
* search for a specific worker.
*
* @param {number} [limit]
* @param {string} [name]
* @param {number} [page]
*/
export function* getWorkers(params = {}) {
  try {
    // Make the request to the server for getting workers
    yield put({ type: GET_WORKERS_REQUEST, params });
    const { data } = yield call(
      request.get,
      `${window.__configuration.api}/user/worker`,
      { params }
    );
    // Getting workers was successful, add them to the store
    yield put({
      type: GET_WORKERS_SUCCESS,
      meta: data.meta,
      payload: data.results
    });

    return data;
  } catch ({ response: { data } }) {
    // Getting the workers has failed, set the error as to why
    yield put({ type: GET_WORKERS_FAILURE, error: data.message });
    throw data;
  }
}

/**
* Submits a request to the server to get a list of users that are stored on
* the server with some extra information about those users.  Allows query
* parameters to paginate through the users and a name query parameter to
* search for a specific user.
*
* @param {number} [limit]
* @param {string} [name]
* @param {number} [page]
*/
export function* getUsers({
  userType,
  onboarded,
  deleted,
  worker,
  limit,
  name,
  page,
  includeAdmins,
  activityId,
  activityStatus,
  orderBy
}) {
  try {
    // Make the request to the server for getting users
    const params = { onboarded, deleted, worker, limit, name, page, includeAdmins, orderBy, activityId, activityStatus };

    yield put({ type: GET_USERS_REQUEST, params });
    const { data } = yield call(
      request.get,
      `${window.__configuration.api}/user/${getUserTypeLong(userType)}`,
      { params }
    );
    // Getting users was successful, add them to the store
    yield put({
      type: GET_USERS_SUCCESS,
      meta: data.meta,
      payload: data.results
    });
    return data;
  } catch ({ response: { data } }) {
    // Getting the users has failed, set the error as to why
    yield put({ type: GET_USERS_FAILURE, error: data.message });
    throw data;
  }
}

/**
* Submits a request to the server to get a user with the specified identifier
* and sets the user to the store if the request is successful.  Returns the
* user that was found or an error (such as not authorized.)
*
* @param {number} id
* @return {object} Either the user returned from the server or an error message
*/
export function* getUser(id) {
  try {
    // Set the request to the store and make the request for the user
    yield put({ type: GET_USER_REQUEST, id });
    const { data } = yield call(
      request.get,
      `${window.__configuration.api}/user/${id}`
    );

    // Retrieving the user was successful, add them to the store
    yield put({ type: GET_USER_SUCCESS, user: data.user });
    return data.user;
  } catch ({ response: { data } }) {
    // Retrieving the user was not successful, add the error to the store
    yield put({ type: GET_USER_FAILURE, error: data.message });
    throw data.message;
  }
}

export function* clearUserError() {
  yield put({ type: CLEAR_USER_ERROR_REQUEST });
  return;
}

/**
* Submits a request to the server to create a new user
*
* @param {string} type
* @param {string} emailAddress
* @param {string} phoneNumber
*
* @return {object} User instance created on the server or an error message
*/
export function* createUser({ userType, phoneNumber, emailAddress }) {
  try {
    // Set the request parameters to the store and make the request to create a user

    yield put({ type: CREATE_USER_REQUEST, params: { userType, phoneNumber, emailAddress } });
    const params = { type: userType };
    phoneNumber ? params.phoneNumber = phoneNumber : null;
    emailAddress ? params.emailAddress = emailAddress : null;
    const { data } = yield call(
      request.post,
      `${window.__configuration.api}/user`,
      params
    );
    // Creating the user was successful, add the user to the list of users
    yield put({ type: CREATE_USER_SUCCESS, user: data.payload });
    return data;
  } catch ({ response: { data } }) {
    // Creating the user has failed, store the error message
    yield put({ type: CREATE_USER_FAILURE, error: data.message });
    throw data.message;
  }
}
/**
* Submits a request to the server to update a user given a specific user
* identifier and some values to change such as a birth date,
* name and optional notes.
*
* @param {number} id
* @param {object} [birthDate]
* @param {string} [firstName]
* @param {string} [lastName]
* @return {object} User instance updated on the server or an error message
*/
export function* updateUser(id, parameters) {
  try {
    // Make the name of parameters shorter for easier access
    const p = parameters;
    // Create the form data, add the data that we need
    const formData = new FormData();
    if (p.birthDate) {
      p.birthDate = p.birthDate.format('YYYY-MM-DD');
    }

    // Add the data that is provided to the request
    if (p.webAccess) formData.append('type', 'A');
    if (p.webAccess === false) formData.append('type', 'W');
    //didnt pick up emailAddress='' without the ||....
    if (p.emailAddress || p.emailAddress === '') formData.append('emailAddress', p.emailAddress);
    if (p.firstName || p.firstName === '') formData.append('firstName', p.firstName);
    if (p.lastName || p.lastName === '') formData.append('lastName', p.lastName);
    if (p.isDefaultAdmin) formData.append('isDefaultAdmin', p.isDefaultAdmin);
    if (p.onboarded) formData.append('onboarded', p.onboarded);
    if (p.notes) formData.append('notes', p.notes);
    if (p.gender) formData.append('gender', p.gender);
    if (p.worker) formData.append('worker', p.worker);
    if (p.phoneNumber || p.phoneNumber === '') formData.append('phoneNumber', p.phoneNumber);
    if (p.birthDate) formData.append('birthDate', p.birthDate);
    if (p.picture) formData.append('picture', p.picture);

    // Place the queries into the store, make the request
    yield put({ type: UPDATE_USER_REQUEST, id, params: arguments[1] });
    const { data } = yield call(
      request.post,
      `${window.__configuration.api}/user/${id}`,
      formData
    );

    yield put({ type: UPDATE_USER_SUCCESS, user: data.payload });
    return data.payload;
  } catch (error) {
    let data = _.get(error, ['response', 'data']);
    if (typeof data !== 'object') {
      data = {};
    }
    const message = data.message;
    const overLimitError =
      'Payload content length greater than maximum allowed:';

    // If the file size is too large, convert to human readable message
    if (typeof message === 'string' && message.startsWith(overLimitError)) {
      const bytes = _.toNumber(data.message.split(':')[1]);
      const megaBytes = (bytes / Math.pow(1024, 2)).toFixed(1);
      data.message = `The files uploaded are too large, our limit is currently: ${megaBytes} MB`;
    }

    // Updating an existing post has failed, return the error
    yield put({ type: UPDATE_USER_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
* Sends a request to the server to delete the user with the specified identifier
* and if the request is successful then the store will remove the user from the
* list of users.
*
* @param {number} id - Identifier of the user that we wish to delete
* @return {object} Action that is dispatched to remove the user or an error message
*/
export function* deleteUser(id) {
  try {
    // Let the store know the request is going to take place, make the request
    yield put({ type: DELETE_USER_REQUEST, id });
    const { data } = yield call(
      request.delete,
      `${window.__configuration.api}/user/${id}`
    );

    // Deleting the user was successful, let the store know
    yield put({ type: DELETE_USER_SUCCESS });
    return data;
  } catch ({ data }) {
    // Deleting the user has failed, store the error message
    yield put({ type: DELETE_USER_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
* Submits a request to the server to update the profile picture of the user
* specified with the picture file specified. If no profile picture passed, submit a request to
* delete the user's current profile picture. Returns whether or not the request
* was successful and sets the new link to the new profile picture in the store
* for the newly updated user.
*
* @param {number} id
* @param {object} picture
*
* @return {object} Newly updated user instance in the database or an error message
*/
export function* updateUserProfilePicture(id, { picture }) {
  try {
    let action = request.put;
    let formData = null;
    if (!picture) {
      action = request.delete;
    } else {
      // Format the picture into the form data to be sent to the server
      formData = new FormData();
      formData.append('picture', picture);
    }
    // Send the request to the server to update the profile picture
    yield put({
      type: UPDATE_USER_PROFILE_PICTURE_REQUEST,
      id,
      params: { picture }
    });
    const { data } = yield call(
      action,
      `${window.__configuration.api}/user/${id}/picture`,
      formData ? formData : undefined
    );

    // Updating the profile picture was successful, update the picture in the store
    yield put({
      type: UPDATE_USER_PROFILE_PICTURE_SUCCESS,
      user: data.payload
    });
    return data;
  } catch (err) {
    console.log(err);
    // Updating the profile picture has failed, store the error message
    yield put({
      type: UPDATE_USER_PROFILE_PICTURE_FAILURE,
      error: data.message
    });
    throw data.message;
  }
}
/**
 * Updates a users onboarded/pending status
 *
 * @param {number} workerId
 */
export function* updateUserOnboarded(id, onboarded) {
  try {
    // Place the queries into the store, make the request
    yield put({ type: UPDATE_USER_ONBOARDED_REQUEST, id, onboarded });

    const params = { onboarded };

    const { data } = yield call(
      request.put,
      `${window.__configuration.api}/user/onboarded/${id}`,
      params
    );

    yield put({ type: UPDATE_USER_ONBOARDED_SUCCESS, user: data.payload });
    return data.payload;
  } catch ({ response: data }) {
    // Updating an existing post has failed, return the error
    yield put({ type: UPDATE_USER_ONBOARDED_FAILURE, error: data.message });
    throw data.message;
  }
}
/**
 * Activates a user. This allows for app users to see this user
 * when requesting for users.
 *
 * @param {number} workerId
 */
export function* activateUser(id) {
  try {
    // Place the queries into the store, make the request
    yield put({ type: ACTIVATE_USER_REQUEST, id });

    const { data } = yield call(
      request.put,
      `${window.__configuration.api}/user/activate/${id}`
    );

    yield put({ type: ACTIVATE_USER_SUCCESS });
    return data.payload;
  } catch ({ response: data }) {
    // Updating an existing post has failed, return the error
    yield put({ type: ACTIVATE_USER_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
* Returns the short form user type that the server accepts. Defaults to youth
**/
export function getUserType(userTypeLong) {
  switch (userTypeLong) {
    // C stands for adult (caregiver), since A is taken by admin
    case 'adult':
    case 'adults':
      return 'C';
    case 'youth':
    case 'youths':
      return 'Y';
    case 'worker':
    case 'workers':
      return 'W';
    case 'admin':
    case 'admins':
      return 'A';
    case 'superadmin':
    case 'superadmins':
      return 'SA';
    default:
      return 'Y';
  }
}

function getUserTypeLong(type) {
  switch (type) {
    case 'all':
      return 'all';
    case 'A':
      return 'admin';
    case 'W':
      return 'worker';
    case 'Y':
      return 'youth';
    case 'C':
      return 'adult';
    case 'SA':
      return 'superadmin';
    default:
      return 'youth';
  }
}
