// Load the separate modules
import about from './about';
import authenticator from './authenticator';
import conversation from './conversation';
import settings from './settings';
import user from './user';
import language from './language';
import log from './log';
import post from './post';
import translation from './translation';
import category from './category';
import activity from './activity';

// Export all of the sagas in one array
export default [
  about,
  authenticator,
  conversation,
  settings,
  user,
  log,
  language,
  category,
  post,
  translation,
  activity
];