// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { StyleRoot } from 'radium';
import YARouter from './../router/router';

/**
* Root component which renders the main router and connects the entire application
* to the Redux store.
*/
export default class Root extends Component {

  // Properties that are passed into the root container
  static propTypes = {
    store: PropTypes.object.isRequired
  };

  render() {

    // Get the store from the properties
    const { store } = this.props;

    // Render the root component
    return (
      <Provider store={store}>
        <StyleRoot style={{ height: '100%' }}>
          <YARouter store={store} />
        </StyleRoot>
      </Provider>
    );
  }
}