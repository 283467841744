/* global __NOOP__ */

// Load dependencies
import { List } from 'immutable';
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

// Load styles
import { defaults, brand, font } from './../styles';

/**
* Represents an autocomplete box for choosing a suggestion based on some
* category.  Allows the user to select a row and returns the index of the
* row that was selected to be handled.
*/
class YAAutocomplete extends Component {

  // Property types available for the autocomplete component
  static propTypes = {
    categories: PropTypes.instanceOf(List).isRequired,
    onClick: PropTypes.func,
    style: PropTypes.object,
    suggestions: PropTypes.instanceOf(List)
  };

  // Default property values for the autocomplete component
  static defaultProps = {
    onClick: __NOOP__,
    style: {},
    suggestions: List()
  };

  // Default styles for the autocomplete component
  static styles = {
    base: {
      boxSizing: 'border-box',
      position: 'relative',
      display: 'block',
      borderTop: `1px solid ${defaults.borderColor}`,
      borderRight: `1px solid ${defaults.borderColor}`,
      borderBottom: `5px solid ${defaults.borderColor}`,
      borderLeft: `1px solid ${defaults.borderColor}`,
      fontFamily: font.family.sansSerif,
      fontSize: font.size.small,
      color: defaults.textColor,
      marginBottom: `${(defaults.gutter / 2)}px`,
      backgroundColor: defaults.white
    },
    row: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'top'
    },
    column: {
      flex: 1,
      flexGrow: 5,
      borderRight: `1px solid ${defaults.borderColor}`
    },
    header: {
      padding: `${(defaults.gutter / 2)}px`,
      borderBottom: `1px solid ${defaults.borderColor}`,
      color: brand.primary.base
    },
    title: {
      fontSize: font.size.base,
      fontWeight: font.weight.normal,
      margin: 0
    },
    body: {
      boxSizing: 'border-box',
      display: 'block',
      margin: `${(defaults.gutter / 2)}px`,
      fontSize: font.size.small,
      height: `${(32 * 5)}px`,
      overflow: 'auto'
    },
    link: {
      display: 'block',
      color: defaults.textColor,
      textDecoration: 'none',
      lineHeight: '24px',
      margin: '4px auto',
      transition: 'all .25s linear',
      borderBottom: `1px dashed ${defaults.borderColor}`,
      ':hover': {
        color: brand.primary.darken
      },
      ':focus': {
        color: brand.primary.darken
      },
      ':active': {
        color: brand.primary.darken
      }
    }
  };

  /**
  * Reports the suggestion that was clicked to the on click handler.
  *
  * @param {number} categoryIndex
  * @param {number} suggestionIndex
  * @param {string} suggestion
  */
  handleClick(categoryIndex, suggestionIndex, suggestion) {
    return this.props.onClick(categoryIndex, suggestionIndex, suggestion);
  }

  render() {
    return (
      <div
        style={[
          YAAutocomplete.styles.base,
          this.props.style
        ]}
      >
        <div style={YAAutocomplete.styles.row}>
          {
            // Render each individual category
            this.props.categories.map((category, categoryIndex) => (
              <div
                key={categoryIndex}
                style={YAAutocomplete.styles.column}
              >

                {/* Render the header of the autocomplete component */}
                <header style={YAAutocomplete.styles.header}>
                  <h1 style={YAAutocomplete.styles.title}>
                    {category}
                  </h1>
                </header>

                {/* Render the contents of the autocomplete box */}
                <div style={YAAutocomplete.styles.body}>
                  {
                    // Render each individual suggestion
                    this.props.suggestions.get(categoryIndex).map((suggestion, suggestionIndex) => (
                      <a
                        key={`${categoryIndex},${suggestionIndex}`}
                        onClick={this.handleClick.bind(this, categoryIndex, suggestionIndex, suggestion)}
                        style={YAAutocomplete.styles.link}
                      >
                        {suggestion}
                      </a>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

// Export component, connected to Radium
export default Radium(YAAutocomplete);