// Load dependencies
import request from 'axios';
import { call, put } from 'redux-saga/effects';

export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';

export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_REQUEST = 'CREATE_TAG_REQUEST';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAILURE = 'CREATE_TAGS_FAILURE';

export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const CREATE_ACTIVITY_REQUEST = 'CREATE_ACTIVITY_REQUEST';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_FAILURE = 'CREATE_ACTIVITY_FAILURE';

export const UPDATE_ACTIVITIES = 'UPDATE_ACTIVITIES';
export const UPDATE_ACTIVITIES_REQUEST = 'UPDATE_ACTIVITIES_REQUEST';
export const UPDATE_ACTIVITIES_SUCCESS = 'UPDATE_ACTIVITIES_SUCCESS';
export const UPDATE_ACTIVITIES_FAILURE = 'UPDATE_ACTIVITIES_FAILURE';

export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_REQUEST = 'UPDATE_ACTIVITY_REQUEST';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'UPDATE_ACTIVITY_FAILURE';

export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVITIES_REQUEST = 'GET_ACTIVITIES_REQUEST';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_FAILURE = 'GET_ACTIVITIES_FAILURE';

export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_REQUEST = 'UPDATE_TAG_REQUEST';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAGS_FAILURE';

export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_ACTIVITY_REQUEST = 'DELETE_ACTIVITY_REQUEST';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_FAILURE = 'DELETE_ACTIVITY_FAILURE';

export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';

export const INVALIDATE_ACTIVITIES = 'INVALIDATE_ACTIVITIES';

export function* getTags(params) {
try {
  yield put({ type: GET_TAGS_REQUEST, params });
  const { data } = yield call(
    request.get,
    `${window.__configuration.api}/tag`,
    { params }
  );
  yield put({
    type: GET_TAGS_SUCCESS,
    meta: data.meta,
    payload: data.results
  });
  return data;
} catch ({ response: { data } }) {

  yield put({ type: GET_TAGS_FAILURE, error: data.message });
  throw data;
}
}

export function* getActivities({ invalidate, name, id, userId, postId, limit, page ,orderBy,  paranoid } = { }) {
  try {
    if (invalidate) {
      yield put({ type: INVALIDATE_ACTIVITIES });
    }
    const params = {name, id, userId, postId, limit, page, paranoid, orderBy}
    yield put({ type: GET_ACTIVITIES_REQUEST, params });
    const { data } = yield call(
      request.get,
      `${window.__configuration.api}/activity`,
      { params }
    );
    yield put({
      type: GET_ACTIVITIES_SUCCESS,
      meta: data.meta,
      payload: data.results
    });

    return data;
  } catch ({ response: { data } }) {
    yield put({ type: GET_ACTIVITIES_FAILURE, error: data.message });
    throw data;
  }
}

export function* createTag(params) {
  try {
    yield put({ type: CREATE_TAG_REQUEST, params });
    const { data } = yield call(
      request.post,
      `${window.__configuration.api}/tag`, params
    );
    yield put({
      type: CREATE_TAG_SUCCESS,
      meta: data.meta,
      payload: data.tag
    });

    return data;
  } catch ({ response: { data } }) {
    yield put({ type: CREATE_TAG_FAILURE, error: data.message });
    throw data;
  }
}

export function* createActivity(params) {
  try {
    yield put({ type: CREATE_ACTIVITY_REQUEST, params });
    const { data } = yield call(
      request.post,
      `${window.__configuration.api}/activity`, params
    );
    yield put({
      type: CREATE_ACTIVITY_SUCCESS,
      meta: data.meta,
      payload: data.payload
    });

    return data;
  } catch ({ response: { data } }) {
    yield put({ type: CREATE_ACTIVITY_FAILURE, error: data.message });
    throw data;
  }
}

export function* updateActivities(params) {

  try {

    yield put({ type: UPDATE_ACTIVITIES_REQUEST, params });
    const { data } = yield call(
      request.post,
      `${window.__configuration.api}/activities`, params
    );
    yield put({
      type: UPDATE_ACTIVITIES_SUCCESS,
      params: params
    });

    return data;
  } catch ({ response: { data } }) {
    yield put({ type: UPDATE_ACTIVITIES_FAILURE, error: data.message });
    throw data;
  }
}

export function* updateActivity(id, params) {
  try {
    yield put({ type: UPDATE_ACTIVITY_REQUEST, params });
    const { data } = yield call(
      request.put,
      `${window.__configuration.api}/activity/${id}`, params
    );
    yield put({
      type: UPDATE_ACTIVITY_SUCCESS,
      meta: data.meta,
      payload: data.payload
    });
    return data;
  } catch ({ response: { data } }) {
    yield put({ type: UPDATE_ACTIVITY_FAILURE, error: data.message });
    throw data;
  }
}

export function* deleteActivity(id) {
  try {
    // Let the store know the request is going to take place, make the request
    yield put({ type: DELETE_ACTIVITY_REQUEST, id });
    const { data } = yield call(
      request.delete,
      `${window.__configuration.api}/activity/${id}` 
    );
    // Deleting the user was successful, let the store know
    yield put({ type: DELETE_ACTIVITY_SUCCESS });
    return data;
  } catch ({ data }) {
    // Deleting the user has failed, store the error message
    yield put({ type: DELETE_ACTIVITY_FAILURE, error: data.message });
    throw data.message;
  }
}
export function* deleteTag(id) {
  
  try {
    // Let the store know the request is going to take place, make the request
    yield put({ type: DELETE_TAG_REQUEST, id });
    const { data } = yield call(
      request.delete,
      `${window.__configuration.api}/tag/${id}` 
    );
    // Deleting the user was successful, let the store know
    yield put({ type: DELETE_TAG_SUCCESS, payload: data.activityId });
    return data;
  } catch ({ data }) {
    // Deleting the user has failed, store the error message
    yield put({ type: DELETE_TAG_FAILURE, error: data.message });
    throw data.message;
  }
}