// Load dependencies
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Radium from 'radium';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

/**
* Renders a catch-all 404 nout found page.
*/
class YANoMatch extends Component {

  /**
   * Handle the logic that should run before the component is completely mounted.
   */
  componentWillMount() {
    ReactGA.event({
      category: 'Application',
      action: '404: Not Found'
    });
  }

  render() {
    return (
      <div style={{ height: '100%' }}>

        {/* Render large error title */}
        <div
          className="flex"
          style={{ height: '100%' }}
        >
          <div className="row flex-grid">
            <div className="container">
              <div className="form-wrap">
                <h1 className="large-heading">
                  Error 404. Page Not Found.
                </h1>

                <p>Sorry, but the page your looking for could not be found.</p>

                <p>
                  <NavLink
                    to="/"
                    className="btn btn-primary"
                    title="Back to YMCA Administration Portal"
                  >
                    Back to home
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(YANoMatch.mapStateToProps, YANoMatch.mapDispatchToProps)(Radium(YANoMatch));