// Load dependencies
import Immutable, { List, Map } from 'immutable';
import * as actions from './../actions/activity';
import { LOGOUT } from './../actions/authenticator';
import moment from 'moment';
// Set the initial state
const initialState = Map({

  activity:  Map(),
  tag: null,

  activities: Map({
    meta: null,
    results: List()
  }),

  tags: Map({
    meta: null,
    results: List()
  }),

  getTags: Map({
    pending: false,
    params: Map({
      userId: null,
      activityId: null,
      postId: null
    }),
    error: null
  }),

  getActivities: Map({
    pending: false,
    params: Map({
      name: null,
      id: null,
      userId: null,
      postId: null,
      paranoid: null,
      orderBy: null
    }),
    error: null
  }),

  createTag: Map({
    pending: false,
    params: Map({
      userId: null,
      activityId: null,
      postId: null,
      tags: []
    }),
    error: null
  }),

  createActivity: Map({
    pending: false,
    params: Map({
      name: null,
      description: null,
      page: null,
      limit: null,
    }),
    error: null
  }),

  updateActivities: Map({
    pending: false,
    params: Map({
      ids: [],
      activate: null,
    }),
    error: null
  }),

  updateActivity: Map({
    pending: false,
    id: null,
    params: Map({
      name: null,
      description: null,
      tagsToDelete: []
    }),
    error: null
  }),

  deleteActivity: Map({
    id: null,
    pending: false,
    error: null
  }),
  
  deleteTag: Map({
    id: null,
    pending: false,
    tags: [],
    error: null
  }),

});

/**
* Handles the management of the state for all user related actions such as
* getting users, adding users, removing users and updating users.
* Also handles setting permissions, workers and the user's profile picture.
*
* @param {object} state
* @param {object} action
*
* @return {object} New state after applying the action
*/
export default function activity(state = initialState, action) {
  switch (action.type) {

    case actions.GET_ACTIVITIES_REQUEST:
          return state
            .setIn(['getActivities', 'pending'], true)
            .setIn(['getActivities', 'params'], Map(action.params))
            .setIn(['getActivities', 'error'], null);

    case actions.GET_ACTIVITIES_FAILURE:
      return state
        .setIn(['getActivities', 'pending'], false)
        .setIn(['getActivities', 'error'], action.error);         

    case actions.GET_ACTIVITIES_SUCCESS:
        return state
          .setIn(['activities', 'meta'], Map(action.meta))
          .setIn(['activities', 'results'], Immutable.fromJS(action.payload))
          .setIn(['getActivities', 'pending'], false);
                   
    case actions.GET_TAGS_REQUEST:
        return state
          .setIn(['getTags', 'pending'], true)
          .setIn(['getTags', 'params'], Map(action.params))
          .setIn(['getTags', 'error'], null);
          
    case actions.GET_TAGS_SUCCESS:
      return state
        .setIn(['tags', 'meta'], Map(action.meta))
        .setIn(['tags', 'results'], Immutable.fromJS(action.payload))
        .setIn(['getTags', 'pending'], false);

    case actions.GET_TAGS_FAILURE:
        return state
          .setIn(['getTags', 'pending'], false)
          .setIn(['getTags', 'error'], action.error);

    case actions.CREATE_TAG_REQUEST:
          return state
            .setIn(['createTag', 'pending'], true)
            .setIn(['createTag', 'params'], Immutable.fromJS(action.params))
            .setIn(['createTag', 'error'], null);

    case actions.UPDATE_ACTIVITIES_REQUEST:
        return state
          .setIn(['updateActivities', 'pending'], true)
          .setIn(['updateActivities', 'params'], Immutable.fromJS(action.params))
          .setIn(['updateActivities', 'error'], null);

    case actions.UPDATE_ACTIVITIES_SUCCESS:

        return state
        .updateIn(['activities', 'results'], activities => {
        
          //tag list is empty, just do nothing
          if (!activities|| !activities.size) {
            return activities;
          }

          let updated = activities;
          let temp = activities.map((activity, index) => {
            if (action.params.ids.indexOf(activity.get('id')) !== -1) {
              if (action.params.activate) {
                updated = updated.update(index, activity =>
                   activity.set('deletedAt', null)
                );
              }
              else{
                updated = updated.update(index, activity =>
                  activity.set('deletedAt', moment())
               );
              }
            }
          });
  
          return updated;
        })
          .setIn(['updateActivities', 'error'], null)
          .setIn(['updateActivities', 'pending'], false);
          
          //.set('activities', Immutable.fromJS(action.activity));   set deleteed at => moment 

    case actions.UPDATE_ACTIVITIES_FAILURE:
        return state
          .setIn(['updateActivities', 'pending'], false)
          .setIn(['updateActivities', 'error'], action.error);

    case actions.CREATE_TAG_SUCCESS:
      return state
          .setIn(
            ['tags', 'results'],
            state.getIn(['tags', 'results']).push(
              Immutable.fromJS(action.payload)
            )
          )
          .setIn(['createTag', 'pending'], false); 
          
    case actions.CREATE_TAG_FAILURE:
        return state
          .setIn(['createTag', 'pending'], false)
          .setIn(['createTag', 'error'], action.error);

    case actions.CREATE_ACTIVITY_REQUEST:
          return state
            .setIn(['createActivity', 'pending'], true)
            .setIn(['createActivity', 'params'], Immutable.fromJS(action.params))
            .setIn(['createActivity', 'error'], null);

    case actions.CREATE_ACTIVITY_SUCCESS:
        return state
          .set('activity', Immutable.fromJS(action.payload))
          .setIn(
            ['activities', 'results'],
            state.getIn(['activities', 'results']).push(
              Immutable.fromJS(action.payload)
            )
          )
          .setIn(['createActivity', 'pending'], false)  
 
    case actions.CREATE_ACTIVITY_FAILURE:
        return state
          .setIn(['createActivity', 'pending'], false)
          .setIn(['createActivity', 'error'], action.error);

    case actions.UPDATE_ACTIVITY_REQUEST:
          return state
            .setIn(['updateActivity', 'pending'], true)
            .setIn(['updateActivity', 'params'], Immutable.fromJS(action.params))
            .setIn(['updateActivity', 'id'], action.id)
            .setIn(['updateActivity', 'error'], null);

    case actions.UPDATE_ACTIVITY_SUCCESS:
      return state

      .updateIn(['activities', 'results'], activities => {
        // tag list is empty, just do nothing
        if (!activities|| !activities.size) {
          return activities;
        }

        const index = (activities.findIndex(activity => activity.get('id') === action.payload.id));
        if (activities.get(index))
        {
          let temp = activities.update(user =>
            user.set('description', action.payload.description)
            .set('name', action.payload.name)
            .set('deletedAt', null)
          );

          return temp;
        }   
        return activities;
      })
      .setIn(['updateActivity', 'pending'], false)
      .set('activity', Immutable.fromJS(action.payload))
      .setIn(['updateActivity', 'id'], null)
      .setIn(['updateActivity', 'pending'], false);
 
    case actions.UPDATE_ACTIVITY_FAILURE:
        return state
          .setIn(['updateActivity', 'pending'], false)
          .setIn(['updateActivity', 'error'], action.error);

    case actions.DELETE_ACTIVITY_REQUEST:
        return state
          .setIn(['deleteActivity', 'pending'], true)
          .setIn(['deleteActivity', 'id'], action.id)
          .setIn(['deleteActivity', 'error'], null);
  
    case actions.DELETE_ACTIVITY_SUCCESS:
          return state
          .updateIn(['activities', 'results'], activities => {
            // Staff member list is empty, just do nothing
            if (!activities|| !activities.size) {
              return activities;
            }
  
            // Check to see if the staff member is inside of the list of staff members
            const id = parseInt(state.getIn(['deleteActivity', 'id']));
            const index = activities.findIndex(activity => activity.get('id') === id);
            // Staff member was found in the list, return the list without that staff member
            if (index !== -1) {
              return activities.update(index, activity =>
                activity.set('deletedAt', moment())
              );
            }
  
            // Return the list since it does not contain the student
            return activities;
          })
          .setIn(['deleteActivity', 'pending'], false)
          .setIn(['deleteActivity', 'id'], null);
    
      case actions.DELETE_ACTIVITY_FAILURE:
        return state
          .setIn(['deleteActivity', 'pending'], false)
          .setIn(['deleteActivity', 'id'], null)
          .setIn(['deleteActivity', 'error'], action.error);

      case actions.DELETE_TAG_REQUEST:
          return state
            .setIn(['deleteTag', 'pending'], true)
            .setIn(['deleteTag', 'id'], action.id)
            .setIn(['deleteTag', 'error'], null);
    
      case actions.DELETE_TAG_SUCCESS:
            return state
            .updateIn(['tags', 'results'], tags => {
              // tag list is empty, just do nothing
              if (!tags|| !tags.size) {
                return tags;
              }
              const index = (tags.findIndex(activity => activity.get('id') === action.payload));
              if (tags.get(index)){return tags.delete(index);}   
              return tags;
            })
            .setIn(['deleteTag', 'pending'], false)
            .setIn(['deleteTag', 'id'], null);
      
        case actions.DELETE_TAG_FAILURE:
          return state
            .setIn(['deleteTag', 'pending'], false)
            .setIn(['deleteTag', 'id'], null)
            .setIn(['deleteTag', 'error'], action.error);

        case actions.INVALIDATE_ACTIVITIES:
          return state
            .setIn(['activities', 'meta'], null)
            .setIn(['activities', 'results'], List());
        
    // Reset the state when the user logs out
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
