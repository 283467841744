/**
* Returns an object property's stored translation given the object
* (with a property called `translations` with a list of its translations) and a language
* identifier. Returns the default string property (in default language)
* if the property is not found in the
* translations list.
*
* @param {Map()} obj - The object to get a property translation for
* @param {string} property - The name of the property to get a translation for
* @param {number} languageId - The identifier of the language to get the translation for
*
* @return {string} The translation
*/
export function getTranslation(obj, property, languageId) {
  const translationsList = obj.get('translations') || [];

  const translations = translationsList.find(translation => translation.get('languageId') === languageId);

  // Default to default object property value if no translation exists
  if (!translations) {
    return obj.get(property);
  }

  return translations.get(property);
}
