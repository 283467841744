// Load dependencies
import request from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { call, put, select } from 'redux-saga/effects';

// Export the actions that are available for the post
export const GET_POST_TYPES = 'GET_POST_TYPES';
export const GET_POST_TYPES_REQUEST = 'GET_POST_TYPES_REQUEST';
export const GET_POST_TYPES_SUCCESS = 'GET_POST_TYPES_SUCCESS';
export const GET_POST_TYPES_FAILURE = 'GET_POST_TYPES_FAILURE';

export const GET_NEWS = 'GET_NEWS';
export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE';

export const GET_RESOURCES = 'GET_RESOURCES';
export const GET_RESOURCES_REQUEST = 'GET_RESOURCES_REQUEST';
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
export const GET_RESOURCES_FAILURE = 'GET_RESOURCES_FAILURE';

export const INVALIDATE_POSTS = 'INVALIDATE_POSTS';
export const INVALIDATE_CATEGORIES = 'INVALIDATE_CATEGORIES';

export const CLEAR_POST = 'CLEAR_POST';

export const GET_POST = 'GET_POST';
export const GET_POST_REQUEST = 'GET_POST_REQUEST';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_FAILURE = 'GET_POST_FAILURE';

export const CREATE_POST = 'CREATE_POST';
export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE';

export const CREATE_ATTACHMENT = 'CREATE_ATTACHMENT';
export const CREATE_ATTACHMENT_REQUEST = 'CREATE_ATTACHMENT_REQUEST';
export const CREATE_ATTACHMENT_SUCCESS = 'CREATE_ATTACHMENT_SUCCESS';
export const CREATE_ATTACHMENT_FAILURE = 'CREATE_ATTACHMENT_FAILURE';

export const CREATE_ATTACHMENTS = 'CREATE_ATTACHMENTS';
export const CREATE_ATTACHMENTS_REQUEST = 'CREATE_ATTACHMENTS_REQUEST';
export const CREATE_ATTACHMENTS_SUCCESS = 'CREATE_ATTACHMENTS_SUCCESS';
export const CREATE_ATTACHMENTS_FAILURE = 'CREATE_ATTACHMENTS_FAILURE';

export const CLEAR_CREATE_POST_ERROR = 'CLEAR_CREATE_POST_ERROR';
export const CLEAR_CREATE_ATTACHMENTS_ERROR = 'CLEAR_CREATE_ATTACHMENTS_ERROR';
export const CLEAR_UPDATE_POST_ERROR = 'CLEAR_UPDATE_POST_ERROR';

export const UPDATE_POST = 'UPDATE_POST';
export const UPDATE_POST_REQUEST = 'UPDATE_POST_REQUEST';
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS';
export const UPDATE_POST_FAILURE = 'UPDATE_POST_FAILURE';

export const UNDELETE_POST = 'UNDELETE_POST';
export const UNDELETE_POST_REQUEST = 'UNDELETE_POST_REQUEST';
export const UNDELETE_POST_SUCCESS = 'UNDELETE_POST_SUCCESS';
export const UNDELETE_POST_FAILURE = 'UNDELETE_POST_FAILURE';

export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE';

const DEFAULT_ERROR_MESSAGE =
  'There was a problem with your upload.  Maybe your files are too large?';

/**
* Submits a request to the server to get the type of posts that are available
* to use when creating a new post.  Adds the post types available to the store if
* successful.
*
* @return {object} Either the post types found or an error message
*/
export function* getPostTypes() {
  try {

    // Make the request to the server for getting the post types
    yield put({ type: GET_POST_TYPES_REQUEST });
    const { data } = yield call(request.get, `${window.__configuration.api}/post/type`);

    // Getting the list of post types was successful, store them in the store
    yield put({ type: GET_POST_TYPES_SUCCESS, payload: data.payload });
    return data;
  } catch ({ response: { data } }) {

    // Getting the post types has failed, set the error as to why
    yield put({ type: GET_POST_TYPES_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
* Submits a request to the server to get the resource posts.
* Appends the post to the current post, or invalidates
* and resets the posts if specified.
*
* @param {boolean} [invalidate]
* @param {number} [limit]
* @param {number} [page]
* @param {string} type
*/
export function* getResources({ invalidate, category, search, deleted, limit, page, orderBy } = {}) {
  try {
    // Invalidate the posts if requested
    if (invalidate) {
      yield put({ type: INVALIDATE_POSTS });
    }
    // Make the request to the server for getting the posts
    const params = { category, search, deleted, limit, page, orderBy };
    yield put({ type: GET_RESOURCES_REQUEST, params });
    const { data } = yield call(request.get, `${window.__configuration.api}/post/all/R`, { params });
    // Getting the posts was successful, add them to the store
    yield put({ type: GET_RESOURCES_SUCCESS, meta: data.meta, payload: data.results });
    return data;
  } catch ({ response: { data } }) {
    // Getting the posts has failed, set the error as to why
    yield put({ type: GET_RESOURCES_FAILURE, error: data.message });
    throw data;
  }
}

/**
* Submits a request to the server to get the news posts.
* Appends the post to the current post, or invalidates
* and resets the posts if specified.
*
* @param {boolean} [invalidate]
* @param {number} [limit]
* @param {number} [page]
* @param {string} type
*/
export function* getNews({ invalidate, deleted, search, limit, page, orderBy } = {}) {
  try {
    // Invalidate the posts if requested
    if (invalidate) {
      yield put({ type: INVALIDATE_POSTS });
    }

    // Make the request to the server for getting the posts
    const params = { limit, page, deleted, search, orderBy };
    yield put({ type: GET_NEWS_REQUEST, params });
    const { data } = yield call(request.get, `${window.__configuration.api}/post/all/N`, { params });

    // Getting the posts was successful, add them to the store
    yield put({ type: GET_NEWS_SUCCESS, meta: data.meta, payload: data.results });
    return data;
  } catch ({ response: { data } }) {

    // Getting the posts has failed, set the error as to why
    yield put({ type: GET_NEWS_FAILURE, error: data.message });
    throw data;
  }
}

/**
* Submits a request to the server to get a specific post.
*
* @param {number} id
*/
export function* getPost(id) {
  try {
    // Make the request to the server for getting the post
    yield put({ type: GET_POST_REQUEST, id });

    const { data } = yield call(request.get, `${window.__configuration.api}/post/${id}`);
    // Getting the post was successful, add them to the store
    yield put({ type: GET_POST_SUCCESS, post: data.post });
    return data.post;
  } catch ({ response: { data } }) {

    // Getting the post has failed, set the error as to why
    yield put({ type: GET_POST_FAILURE, error: data.message });
    throw data;
  }
}

/**
* Submits a request to the server to create a new post based on the parameters
* that are passed through.  Sets all the photos to the form data
* if they are provided.  Returns when creating the post is successful.
*
* @param {string} description
* @param {string} name
* @param {object[]} [pictures]
* @param {string} type
*
* @return {object} Post item that was created or an error object
*/
export function* createPost({
  description, title, pictures, url, notify, publishDate, categories, tags,
  postType, featured
}) {
  try {

    // Create the form data, add the data that we need
    const formData = new FormData();

    // Add the mandatory data to the request
    formData.append('type', postType);

    // Add the data that is provided to the request
    if (description) formData.append('description', description);
    if (title) formData.append('title', title);
    if (url) formData.append('url', url);
    if (notify) formData.append('notify', notify);
    if (publishDate) formData.append('publishDate', moment(publishDate).format('YYYY-MM-DD'));
    if (featured) formData.append('featured', featured);

    // Add each category provided to the request
    if (categories) {
      _.forEach(categories, category => {
        formData.append('categories', category);
      });
    }

    if (tags) {
      _.forEach(tags, category => {
        formData.append('tags', category);
      });
    }

    // Add each photo provided to the request
    if (pictures) {
      _.forEach(pictures, picture => {
        formData.append('pictures[]', picture);
      });
    }

    // Place the queries into the store, make the request
    yield put({ type: CREATE_POST_REQUEST, params: arguments[0] });
    const { data } = yield call(request.post, `${window.__configuration.api}/post`, formData);

    yield put({ type: CREATE_POST_SUCCESS, post: data.payload }); // TODO: put new post in here
    return data;
  } catch (error) {
    let data = _.get(error, ['response', 'data']);
    if (typeof data !== 'object') {
      data = {};
    }
    const message = data.message;
    const overLimitError = 'Payload content length greater than maximum allowed:';

    // If the file size is too large, convert to human readable message
    if (typeof message === 'string' && message.startsWith(overLimitError)) {
      const bytes = _.toNumber(data.message.split(':')[1]);
      const megaBytes = (bytes / Math.pow(1024, 2)).toFixed(1);
      data.message = `The files uploaded are too large, our limit is currently: ${megaBytes} MB`;
    } else {
      data.message = data.message || DEFAULT_ERROR_MESSAGE;
    }

    // Creating a new post has failed, return the error
    yield put({ type: CREATE_POST_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
 * Submits a request to the server to update an existing post based on the
 * parameters that are passed through.  Sets all the photos to
 * the form data if they are provided.  Returns when updating the post is successful.
 *
 * @param {number} postId
 * @param {object} parameters
 */
export function* updatePost(postId, parameters) {
  try {
    // Make the name of parameters shorter for easier access
    const p = parameters;

    // Create the form data, add the data that we need
    const formData = new FormData();

    // Add the data that is provided to the request
    if (p.description) formData.append('description', p.description);
    if (p.attachmentsToDelete && p.attachmentsToDelete.length > 0) formData.append('attachmentsToDelete', p.attachmentsToDelete);
    if (p.title) formData.append('title', p.title);
    if (p.url) formData.append('url', p.url);
    if (p.notify) formData.append('notify', p.notify);
    if (p.publishDate) formData.append('publishDate', moment(p.publishDate).format('YYYY-MM-DD'));
    if (p.featured) formData.append('featured', p.featured);

    // Add each category provided to the request
    if (p.categories) {
      _.forEach(p.categories, category => {
        formData.append('categories', category);
      });
    }

    // Add each category to delete to the request
    if (p.categoriesToDelete) {
      _.forEach(p.categoriesToDelete, category => {
        formData.append('categoriesToDelete', category);
      });
    }

    // Add each photo provided to the request
    if (p.pictures) {
      _.forEach(p.pictures, picture => {
        formData.append('pictures[]', picture);
      });
    }

    // Place the queries into the store, make the request
    yield put({ type: UPDATE_POST_REQUEST, postId, params: arguments[1] });
    const { data } = yield call(request.post, `${window.__configuration.api}/post/${postId}`, formData);

    yield put({ type: UPDATE_POST_SUCCESS, post: data.payload });
    return data.payload;
  } catch (error) {
    let data = _.get(error, ['response', 'data']);
    if (typeof data !== 'object') {
      data = {};
    }
    const message = data.message;
    const overLimitError = 'Payload content length greater than maximum allowed:';

    // If the file size is too large, convert to human readable message
    if (typeof message === 'string' && message.startsWith(overLimitError)) {
      const bytes = _.toNumber(data.message.split(':')[1]);
      const megaBytes = (bytes / Math.pow(1024, 2)).toFixed(1);
      data.message = `The files uploaded are too large, our limit is currently: ${megaBytes} MB`;
    } else {
      data.message = data.message || DEFAULT_ERROR_MESSAGE;
    }

    // Updating an existing post has failed, return the error
    yield put({ type: UPDATE_POST_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
 * Undeletes a post with the given postId
 *
 * @param {number} postId
 */
export function* undeletePost(postId) {
  try {
    // Place the queries into the store, make the request
    yield put({ type: UNDELETE_POST_REQUEST, postId });

    // Create the form data, add the data that we need
    const formData = new FormData();

    formData.append('undelete', true)

    const { data } = yield call(request.post, `${window.__configuration.api}/post/${postId}`, formData);

    yield put({ type: UNDELETE_POST_SUCCESS });
    return data.payload;
  } catch ({ response: data }) {
    // Updating an existing post has failed, return the error
    yield put({ type: UNDELETE_POST_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
 * Submits a request to the server to create a post attachment
 * Returns when creating the attachment is successful.
 *
 * @param {number} postId
 * @param {object} parameters
 */
export function* createAttachment(postId, parameters) {
  try {

    // Make the name of parameters shorter for easier access
    const p = parameters;

    // Create the form data, add the data that we need
    const formData = new FormData();

    // Add the mandatory data to the request
    if (p.language) formData.append('language', p.language);

    // Add each attachment provided to the request
    if (p.attachments) {
      _.forEach(p.attachments, attachment => {
        formData.append('attachments[]', attachment);
      });
    }

    // Place the queries into the store, make the request
    yield put({ type: CREATE_ATTACHMENT_REQUEST, postId, params: arguments[1] });
    const { data } = yield call(request.post, `${window.__configuration.api}/post/attachment/${postId}`, formData);

    yield put({ type: CREATE_ATTACHMENT_SUCCESS });
    return data.payload;
  } catch (error) {
    let data = _.get(error, ['response', 'data']);
    if (typeof data !== 'object') {
      data = {};
    }
    const message = data.message;
    const overLimitError = 'Payload content length greater than maximum allowed:';

    // If the file size is too large, convert to human readable message
    if (typeof message === 'string' && message.startsWith(overLimitError)) {
      const bytes = _.toNumber(data.message.split(':')[1]);
      const megaBytes = (bytes / Math.pow(1024, 2)).toFixed(1);
      data.message = `The files uploaded are too large, our limit is currently: ${megaBytes} MB`;
    } else {
      data.message = data.message || DEFAULT_ERROR_MESSAGE;
    }

    // Updating an existing post has failed, return the error
    yield put({ type: CREATE_ATTACHMENT_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
* Submits a request to the server to delete a post with the specified identifier
* and if the request is successful the post is removed from the list.
*
* @param {number} postId
*/
export function* deletePost(postId) {
  try {

    // Make the request to the server to remove the post
    yield put({ type: DELETE_POST_REQUEST, postId });
    const { data } = yield call(request.delete, `${window.__configuration.api}/post/${postId}`);

    // Removing the post was successful, remove the post from the store
    yield put({ type: DELETE_POST_SUCCESS });
    return data;
  } catch ({ response: { data } }) {

    // Removing the post has failed, set the error as to why
    yield put({ type: DELETE_POST_FAILURE, error: data.message });
    throw data.message;
  }
}