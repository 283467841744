// Load dependencies
import request from 'axios';
import moment from 'moment';
import { call, put } from 'redux-saga/effects';

// Export the actions that are available for the conversation
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE';

export const GET_CONVERSATIONS = 'GET_CONVERSATIONS';
export const GET_CONVERSATIONS_REQUEST = 'GET_CONVERSATIONS_REQUEST';
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS';
export const GET_CONVERSATIONS_FAILURE = 'GET_CONVERSATIONS_FAILURE';

export const INVALIDATE_CONVERSATIONS = 'INVALIDATE_CONVERSATIONS';
export const INVALIDATE_MESSAGES = 'INVALIDATE_MESSAGES';

/**
* Submits a request to the server to get messages in a conversation that is stored on
* the server with some extra information about the conversation.
*
* @param {boolean} [all]
* @param {boolean} [distinct]
* @param {number} [limit]
* @param {number} [page]
* @param {number} [userId]
*/
export function* getMessages(
  conversationId,
  { all, includeTranslations, startDate, endDate, invalidate, distinct, limit, page} = {}
) {
  try {
    if (startDate) {
      startDate = moment.utc(startDate).format('YYYY-MM-DD');
    }

    if (endDate) {
      endDate = moment.utc(endDate).format('YYYY-MM-DD');
    }
    // Invalidate the posts if requested
    if (invalidate) {
      yield put({ type: INVALIDATE_MESSAGES });
    }
    // Make the request to the server for getting conversation
    const params = { all, conversationId, includeTranslations, startDate, endDate, distinct, limit, page };
    yield put({ type: GET_MESSAGES_REQUEST, params });
    const { data } = yield call(
      request.get,
      `${window.__configuration.api}/conversation`,
      { params }
    );

    // Getting conversation was successful, add them to the store
    yield put({
      type: GET_MESSAGES_SUCCESS,
      meta: data.meta,
      payload: data.results
    });
    return data;
  } catch ({ response: { data } }) {
    // Getting the conversation has failed, set the error as to why
    yield put({ type: GET_MESSAGES_FAILURE, error: data.conversation });
    throw data.conversation;
  }
}

/**
* Submits a request to the server to get a list of conversations for a specified user
* that are stored on he server with some extra information about those conversations.
* Allows query parameters to paginate through the conversations.
*
* @param {number} userId
* @param {boolean} [all]
* @param {boolean} [distinct]
* @param {number} [limit]
* @param {number} [page]
*/
export function* getConversations(
  userId,
  { all, limit, page } = {}
) {
  try {
    // Make the request to the server for getting conversations
    const params = {
      all,
      limit,
      page
    }; // TODO: change to worker
    yield put({ type: GET_CONVERSATIONS_REQUEST, userId, params });
    const { data } = yield call(
      request.get,
      `${window.__configuration.api}/conversation/all/${userId}`,
      { params }
    );
    // Getting conversations was successful, add them to the store
    yield put({
      type: GET_CONVERSATIONS_SUCCESS,
      meta: data.meta,
      payload: data.results
    });
    return data;
  } catch ({ response: { data } }) {
    // Getting the conversation has failed, set the error as to why
    yield put({ type: GET_CONVERSATIONS_FAILURE, error: data.message });
    throw data.message;
  }
}

export function* clearConversation() {
  yield put({ type: INVALIDATE_MESSAGES });
}

