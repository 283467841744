// Load dependencies
import Immutable, { Map } from 'immutable';
import * as actions from './../actions/settings';

// Set the initial state
const initialState = Immutable.fromJS({
  getSettings: {
    pending: false,
    error: null
  },
  settings: Map()
});

/**
 * Handles the management of the state for all information relating to site settings.
 *
 * @param {object} state
 * @param {object} action
 *
 * @return {object} New state after applying the action
 */
export default function settings(state = initialState, action) {
  switch (action.type) {
    case actions.GET_SETTINGS_REQUEST:
      return state
        .setIn(['getSettings', 'pending'], true)
        .setIn(['getSettings', 'error'], null);

    case actions.GET_SETTINGS_SUCCESS:
      return state
        .setIn(['getSettings', 'pending'], false)
        .set('settings', Map(action.payload));

    case actions.GET_SETTINGS_FAILURE:
      return state
        .setIn(['getSettings', 'pending'], false)
        .setIn(['getSettings', 'error'], action.error);

    default:
      return state;
  }
}
