// Select the current list of workers with meta information
export function getWorkers(state) {
  return state.user.get('workers');
}

// Select the current list of users with meta information
export function getUsers(state) {
  return state.user.get('users');
}

export function getUsersPending(state) {
  return state.user.getIn(['getUsers', 'pending']);
}

export function getUsersError(state) {
  return state.user.getIn(['getUsers', 'error']);
}

// Select the current user with their complete profile
export function getUser(state) {
  return state.user.get('user');
}

export function getUserPending(state) {
  return state.user.getIn(['getUser', 'pending']);
}

// Select the current error message for creating a new user
export function getCreateUserError(state) {
  return state.user.getIn(['createUser', 'error']);
}

// Select the current error message for updating an existing user
export function getUpdateUserError(state) {
  return state.user.getIn(['updateUser', 'error']);
}

export function getUpdateUserPending(state) {
  return state.user.getIn(['updateUser', 'pending']);
}

export function getUpdateUserProfilePicturePending(state) {
  return state.user.getIn(['updateUserProfilePicture', 'pending']);
}

// Select the current error message for updating the user's profile picture
export function getUpdateUserProfilePictureError(state) {
  return state.user.getIn(['updateUserProfilePicture', 'error']);
}

// Select the current error message for creating a new worker
export function getCreateWorkerError(state) {
  return state.user.getIn(['createWorker', 'error']);
}

// Get the current error message for removing a worker from a user
export function getRemoveWorkerError(state) {
  return state.user.getIn(['removeWorker', 'error']);
}
