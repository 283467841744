// Load dependencies
import { applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import * as reducers from './../reducers';
import sagas from './../sagas';

/**
* Sets up the store to work with Redux Saga and the reducers.
*
* @param {object} initialState
* @return {object} Store that is configured with Redux
*/
export default function setupStore(initialState) {

  // Create the Saga middleware
  const sagaMiddleware = createSagaMiddleware();

  // Compose the middlewares together
  const enhancer = compose(
    applyMiddleware(sagaMiddleware)
  );

  // Create the store with the reducers and middleware
  const store = configureStore({
    reducer: reducers,
    preloadedState: initialState,
    enhancers: [enhancer],
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
  });

  // Run the Saga middleware
  sagas.map(saga => sagaMiddleware.run(saga));

  return store;
}