/* global __NOOP__ */

// Load dependencies
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Radium from 'radium';

// Load styles
import { defaults, modal } from './../../components/styles';

// Load components
import YAAlert from './../../components/form/alert';
import YAButton from './../../components/form/button';
import YAInput from './../../components/form/input';
import YAModal from './../../components/modal/modal';

/**
* Renders a modal for entering and changing information pertaining to a parent
* given an email address, first and last name and a phone number.
*/
class YAReassignModal extends Component {
  // Properties that are available for the parent modal
  static propTypes = {
    ..._.pick(YAModal.propTypes, ['header', 'onCloseClick', 'show']),
    disabled: PropTypes.bool,
    emailAddress: PropTypes.string,
    error: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    onChange: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onContinueClick: PropTypes.func,
    phoneNumber: PropTypes.string,
    showDelete: PropTypes.bool
  };

  // Default property values of the parent modal
  static defaultProps = {
    ..._.pick(YAModal.defaultProps, ['onCloseClick', 'show']),
    disabled: true,
    emailAddress: '',
    firstName: '',
    lastName: '',
    onChange: __NOOP__,
    onDeleteClick: __NOOP__,
    onContinueClick: __NOOP__,
    phoneNumber: '',
    showDelete: false
  };

  // Default styles for the parent modal component
  static styles = {
    input: {
      backgroundColor: modal.footer.bg
    },
    row: {
      marginTop: `${defaults.gutter / 2}px`
    }
  };

  /**
  * When the component receives a new set of properties, check that the error
  * property has changed and that the error is now going to be shown.  If the
  * error is updated then we want to scroll the user back to the top of the
  * modal to view the error.
  *
  * @param {object} props - Next set of properties we are receiving to render
  */
  componentWillReceiveProps(props) {
    if (this.props.error !== props.error && props.error) {
      this.refs.modal.scrollToTop();
    }

    if (this.props.show !== props.show && props.show) {
      ReactGA.modalview('parent');
    }
  }

  /**
  * Call the handler of the parameter change.  Sends the value that changed and
  * the property to store that value in (email address, first name, last name, etc.)
  *
  * @param {string} property
  * @param {object} event
  */
  handleChange(property, event) {
    return this.props.onChange(
      property,
      _.get(event, 'target.rawValue') || _.get(event, 'target.value')
    );
  }


  /**
  * Renders the footer of the parent modal which consists of the controls to
  * close the modal and to save the changes.
  */
  renderFooter() {
    return (
      <div>
        {/* Render the button to cancel and close the modal */}
        <YAButton onClick={this.props.onCloseClick} size="small" type="text">
          Cancel
        </YAButton>

        {// Render the button to delete the parent and close the modal
          this.props.showDelete ? (
            <YAButton onClick={this.props.onDeleteClick} size="small" type="text">
              Delete
            </YAButton>
          ) : null}

        {/* Render the button to save the changes */}
        <YAButton
          onClick={this.props.onContinueClick}
          size="small"
          type="primary"
        >
          Continue
        </YAButton>
      </div>
    );
  }

  render() {
    return (
      <YAModal
        ref="modal"
        header={this.props.header}
        footer={this.renderFooter()}
        onCloseClick={this.props.onCloseClick}
        show={this.props.show}
      >
        {/* Render the error message if there is one */}
        <div>
          {this.props.error ? (
            <YAAlert type="E">{this.props.error}</YAAlert>
          ) : null}
        </div>

      </YAModal>
    );
  }
}

export default Radium(YAReassignModal);
