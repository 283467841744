// Load dependencies
import request from 'axios';
import store from 'store';
import { call, put, select } from 'redux-saga/effects';
// import ReactGA from 'react-ga';

// Load selectors
import { getToken } from './../selectors/authenticator';

// Export the actions that are available for the authenticator
export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SET_TOKEN = 'SET_TOKEN';

/**
* Submits a login request to the server with the given username and password and
* returns whether or not the request was successful.
*
* @param {string} emailAddress
* @param {string} password
*
* @return {object} Either the authentication token or an error message
*/
export function* login(emailAddress, password) {
  try {

    // Make the login request to the server
    yield put({ type: LOGIN_REQUEST });
    const { data } = yield call(request.post, `${window.__configuration.api}/auth/superadmin/login`, { emailAddress, password });
    // Login was successful, store the token in local storage
    yield put({ type: LOGIN_SUCCESS, authToken: data.payload.token });
    setToken(data.payload.token);
    return data.payload.token;
  } catch ({ response: { data } }) {

    // An error occurred logging into the user's profile, return an error
    yield put({ type: LOGIN_FAILURE, error: data.message });
    throw data;
  }
}

/**
* Clears out the user's authentication details and all of their session information
* so that the user can be redirected to the login page.
*/
export function* logout() {
  // Clear the user's authentication token from the storage
  setToken(null);
}

/**
* Checks whether or not the user is logged in by finding their authentication token
* from the local storage or from the store.  Returns whether or not the user is
* currently logged in.
*
* @return {boolean} Whether or not the user is logged in
*/
export function* isLoggedIn() {

  // Check for the authentication token in the store
  let authToken = yield select(getToken);

  // Authentication token was not found in the store, check local storage
  if (authToken === null) {
    authToken = store.get('PointB:authToken') || null;

    // Set the authentication token to the store
    if (authToken !== null) {
      yield put({ type: SET_TOKEN, authToken });
      request.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    }
  }

  // Set whether or not the user is logged in
  yield put({ type: SET_LOGGED_IN, loggedIn: authToken !== null });
  return authToken !== null;
}

/**
* Submits a request to the server to get the profile of the current staff member
* who is logged in.  Returns an error if the user is not logged in.  Sets the
* profile to the store if successful.
*
* @return {object} Either the user's profile or an error message
*/
export function* getProfile() {
  try {

    // Make the get profile request to the server
    yield put({ type: GET_PROFILE_REQUEST });
    const { data } = yield call(request.get, `${window.__configuration.api}/profile`);

    // Store the profile of the user for Google Analytics
    // ReactGA.set({ TODO:
    //   userId: `s_${data.payload.id}`
    // });

    // Getting the profile was successful, store the profile in the store
    yield put({ type: GET_PROFILE_SUCCESS, profile: data.profile });

    // There is a token that was returned from the server, update the user's token
    if (data.token) {
      yield put({ type: SET_TOKEN, authToken: data.token });
      setToken(data.token);
    }

    return data.payload;
  } catch ({ response: { data } }) {

    // An error occurred getting the user's profile, return the error
    yield put({ type: GET_PROFILE_FAILURE, error: data.message });
    throw data;
  }
}

/**
* Sends a request to the server to submit a request to reset the password of the
* staff member with the specified email address.
*
* @param {string} emailAddress - Email address of the staff member to get a new password for
* @return {object} Action that is dispatched indicating either success or failure
*/
export function* forgotPassword(emailAddress) {
  try {

    // Store the request parameters and make a request to the server
    yield put({ type: FORGOT_PASSWORD_REQUEST, params: { emailAddress } });
    yield call(request.put, `${window.__configuration.api}/auth/forgot`, {
      emailAddress
    });

    // Successfully sent the request for staff forgot password, return the results
    yield put({ type: FORGOT_PASSWORD_SUCCESS });
  } catch ({ response: { data } }) {

    // Unable to forget the password of the staff member, store the error message
    yield put({ type: FORGOT_PASSWORD_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
* Sends a request to the server to reset the password of the user who has the specified
* token assigned to their account.
*
* @param {string} token - Token that was sent to the staff member changing their password
* @param {string} password - New password to apply to the account of the staff member
*
* @return {object} Action that is dispatched indicating either success or failure
*/
export function* resetPassword(token, password) {
  try {

    // Store that the request is going to take place and make the request
    yield put({ type: RESET_PASSWORD_REQUEST });
    yield call(request.put, `${window.__configuration.api}/auth/reset/${token}`, {
      password
    });

    // Resetting the password was successful, refresh the state
    yield put({ type: RESET_PASSWORD_SUCCESS });
  } catch ({ response: { data } }) {

    // Unable to reset the password of the user, store the error
    yield put({ type: RESET_PASSWORD_FAILURE, error: data.message });
    throw data.message;
  }
}

/**
* Sets the provided token as the authentication token to local storage.  If no
* token is provided then the token will be removed from local storage.
*
* @param {string} authToken
*/
function setToken(authToken) {
  // Token was provided, set it to local storage
  if (typeof authToken === 'string') {
    store.set('PointB:authToken', authToken);
    request.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  } else {

    // Token was not provided, remove the token from storage
    store.remove('PointB:authToken');
  }
}