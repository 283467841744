// Load dependencies
import { delay } from 'bluebird';
import { call, cancel, fork, take, all } from 'redux-saga/effects';
import * as actions from './../actions/user';

/**
* Gets a list of outreach workers from the server, paginated by the page that is passed
* through the action. Sets the workers that are found to the state with meta
* information about how to access more workers.
*/
function* getWorkers() {
  while (true) {
    const { onFailure, onboarded, includeAdmins, all, limit, page } = yield take(actions.GET_WORKERS);
    try {
      yield call(actions.getWorkers, { all, includeAdmins, onboarded, limit, page });
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure(error);
    }
  }
}

/**
* Gets a list of useres from the server, paginated by the page that is passed
* through the action. Sets the users that are found to the state with meta
* information about how to access more users.
*/
function* getUsers() {
  while (true) {
    const { userType, onFailure, onSuccess, worker, deleted, onboarded, limit, name, page, includeAdmins, orderBy, activityId, activityStatus } = yield take(actions.GET_USERS);
    try {
      yield call(actions.getUsers, { limit, userType, worker, deleted, onboarded, name, page, includeAdmins, orderBy, activityId, activityStatus });
      if (typeof onSuccess === 'function') onSuccess();
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure(error);
    }
  }
}

/**
* Gets a specific user from the server with their entire profile, such as
* progress reports, that are not available from the bulk user call.  Sets
* the user that is found to the store.
*/
function* getUser() {
  while (true) {
    // Get the parameters to grab the user from the action
    const { onFailure, onSuccess, id } = yield take(actions.GET_USER);

    try {
      // Attempt the request to grab the user's profile from the server
      yield call(actions.getUser, id);
      if (typeof onSuccess === 'function') onSuccess();
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure();
    }
  }
}

function* clearUserError() {
  while (true) {
    yield take(actions.CLEAR_USER_ERROR);
    yield call(actions.clearUserError);
  }
}

/**
* Creates a new user with the specified birth date, first
* and last names and if the request is successful the new user will be added
* to the list of users.
*/
function* createUser() {
  while (true) {
    // Get the parameters to create the user from the action
    const { phoneNumber, userType, emailAddress, onFailure, onSuccess } = yield take(
      actions.CREATE_USER
    );

    try {
      // Send the request to create the user
      yield call(actions.createUser, { userType, phoneNumber, emailAddress });
      if (typeof onSuccess === 'function') onSuccess();
    } catch (error) {
      if (typeof onFailure === 'function') onFailure();
    }
  }
}

/**
* Updates a user with the specified user identifier given some values such
* as a first or last name, birth date, notes that the user is
* in.  Upon success updates the user in the store with the new values.
*/
function* updateUser() {
  while (true) {
    // Get the parameters to update the user from the action
    const {
      id,
      emailAddress,
      firstName,
      lastName,
      onboarded,
      isDefaultAdmin,
      notes,
      gender,
      worker,
      phoneNumber,
      birthDate,
      webAccess,
      onFailure,
      onSuccess
    } = yield take(actions.UPDATE_USER);

    try {
      // Attempt to make the request to update the user
      yield call(actions.updateUser, id, {
        emailAddress,
        firstName,
        lastName,
        onboarded,
        isDefaultAdmin,
        notes,
        gender,
        worker,
        phoneNumber,
        webAccess,
        birthDate,
      });
      if (typeof onSuccess === 'function') onSuccess();
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure();
    }
  }
}

/**
* Deletes a user on the server with the specified identifier and if the request
* is successful then the user is removed from the list of users.
*/
function* deleteUser() {
  while (true) {
    // Retrieve the parameters to delete the user from the action
    const parameters = yield take(actions.DELETE_USER);

    try {
      // Attempt to make the request to delete the user
      yield call(actions.deleteUser, parameters.id);

      // Call the success handler if one is provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the failure handler if a failure handler is provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
 * Activates a soft deleted user
 */
function* activateUser() {
  while (true) {
    // Extract the parameters from the action
    const parameters = yield take(actions.ACTIVATE_USER);

    try {
      // Attempt to update the user on the server with the parameters
      yield call(actions.activateUser, parameters.id);

      // Call the success handler if there is one provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the failure handler if there is one provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
* Updates a user's profile picture with a new one and saves the profile picture
* link that is generated to the store so that it can be reloaded.
*/
function* updateUserProfilePicture() {
  while (true) {
    // Get the parameters from the action for the request
    const { id, onFailure, onSuccess, picture } = yield take(
      actions.UPDATE_USER_PROFILE_PICTURE
    );

    try {
      // Attempt to update the user's profile picture
      yield call(actions.updateUserProfilePicture, id, { picture });
      if (typeof onSuccess === 'function') onSuccess();
    } catch (error) {
      console.error(error);
      if (typeof onFailure === 'function') onFailure();
    }
  }
}
/**
 * Updates the onboarded/pending state of a user
 */
function* updateUserOnboarded() {
  while (true) {
    // Extract the parameters from the action
    const parameters = yield take(actions.UPDATE_USER_ONBOARDED);

    try {
      // Attempt to update the user on the server with the parameters
      yield call(actions.updateUserOnboarded, parameters.id, parameters.onboarded);

      // Call the success handler if there is one provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      // Call the failure handler if there is one provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
* Start all of the sagas in this module.
*/
export default function* root() {
  yield all([
    fork(getWorkers),
    fork(getUser),
    fork(getUsers),
    fork(createUser),
    fork(clearUserError),
    fork(updateUser),
    fork(deleteUser),
    fork(activateUser),
    fork(updateUserOnboarded),
    fork(updateUserProfilePicture)
  ]);
}
