// Load dependencies
import Immutable, { List, Map } from 'immutable';
import * as actions from './../actions/category';
import { LOGOUT } from './../actions/authenticator';

// Set the initial state
const initialState = Map({
  categories: Map({
    // TODO: remove pagination
    meta: null,
    results: List()
  }),

  category: Map({
    // TODO: temp
    id: 1,
    name: 'Spirituality'
  }),

  getCategories: Map({
    pending: false,
    params: Map({
      id: null,
      limit: null,
      page: null,
      type: null
    }),
    error: null
  }),

  createCategory: Map({
    pending: false,
    params: Map({
      name: null
    }),
    error: null
  }),

  updateCategory: Map({
    pending: false,
    categoryId: null,
    params: Map({
      name: null
    }),
    error: null
  }),

  deleteCategory: Map({
    pending: false,
    categoryId: null,
    error: null
  })
});

/**
* Handles the management of data relating to the post categories
* Stores pagination information for the category so that
* more data can be loaded using the data from the meta information.
*
* @param {object} state
* @param {object} action
*
* @return {object} New state after applying the action
*/
export default function category(state = initialState, action) {
  switch (action.type) {
    // Request to create a category has come in, store the parameters
    case actions.CREATE_CATEGORY_REQUEST:
      return state
        .setIn(['createCategory', 'pending'], true)
        .setIn(['createCategory', 'params'], Immutable.fromJS(action.params))
        .setIn(['createCategory', 'error'], null);

    // Request to create a category has succeeded, set the flags back to normal
    case actions.CREATE_CATEGORY_SUCCESS:
      return state.setIn(['createCategory', 'pending'], false);

    // Request to create a category has failed, set the error to the store
    case actions.CREATE_CATEGORY_FAILURE:
      return state
        .setIn(['createCategory', 'pending'], false)
        .setIn(['createCategory', 'error'], action.error);

    // Get request for the categories has come in, set the parameters to the store
    case actions.GET_CATEGORIES_REQUEST:
      return state
        .setIn(['getCatgories', 'pending'], true)
        .setIn(['getCatgories', 'params'], Map(action.params))
        .setIn(['getCatgories', 'error'], null);

    // Get request for the categories has succeeded, set the category to the store
    case actions.GET_CATEGORIES_SUCCESS:
      return state
        .setIn(['categories', 'meta'], Map(action.meta))
        .setIn(
          ['categories', 'results'],
          state
            .getIn(['categories', 'results'])
            .concat(Immutable.fromJS(action.payload))
        )
        .setIn(['getCatgories', 'pending'], false);

    // Get request for the categories has failed, set the error to the store
    case actions.GET_CATEGORIES_FAILURE:
      return state
        .setIn(['getCatgories', 'pending'], false)
        .setIn(['getCatgories', 'error'], action.error);

    // Clears out the current error for creating a category
    case actions.CLEAR_CREATE_CATEGORY_ERROR:
      return state.setIn(['createCategory', 'error'], null);

    // Clear out the current categories
    case actions.INVALIDATE_CATEGORIES:
      return state
        .setIn(['categories', 'meta'], null)
        .setIn(['categories', 'results'], List());

    // Request to update a category has come in, store the parameters
    case actions.UPDATE_CATEGORY_REQUEST:
      return state
        .setIn(['updateCategory', 'pending'], true)
        .setIn(['updateCategory', 'categoryId'], action.categoryId)
        .setIn(['updateCategory', 'params'], Immutable.fromJS(action.params))
        .setIn(['updateCategory', 'error'], null);

    // Request to update a category was successful, clear the flags
    case actions.UPDATE_CATEGORY_SUCCESS:
      return state
        .setIn(['updateCategory', 'pending'], false)
        .setIn(['updateCategory', 'categoryId'], null)
        .setIn(
          ['updateCategory', 'params'],
          initialState.getIn(['updateCategory', 'params'])
        );

    // Request to update a category has failed, store the error message
    case actions.UPDATE_CATEGORY_FAILURE:
      return state
        .setIn(['updateCategory', 'pending'], false)
        .setIn(['updateCategory', 'categoryId'], null)
        .setIn(
          ['updateCategory', 'params'],
          initialState.getIn(['updateCategory', 'params'])
        )
        .setIn(['updateCategory', 'error'], action.error);

    // Request to delete a category has come in, store the parameters
    case actions.DELETE_CATEGORY_REQUEST:
      return state
        .setIn(['deleteCategory', 'pending'], true)
        .setIn(['deleteCategory', 'categoryId'], action.categoryId)
        .setIn(['deleteCategory', 'error'], null);

    // Successfully removed a category, remove it from the category if it's there
    case actions.DELETE_CATEGORY_SUCCESS:
      return state
        .updateIn(['category', 'results'], category => {
          // Category doesn't exist or is empty, short circuit and exit
          if (!category || !category.size) {
            return category;
          }

          // Search for the deleted category in the list of categorys
          const categoryId = state.getIn(['deleteCategory', 'categoryId']);
          const index = category.findIndex(
            category => category.get('id') === categoryId
          );

          // Category was found in the list, remove it from the list
          if (index !== -1) {
            return category.delete(index);
          }

          // Category was not found in the category, do nothing
          return category;
        })
        .setIn(['deleteCategory', 'pending'], false)
        .setIn(['deleteCategory', 'categoryId'], null);

    // Unable to delete the category, store the error message from the server
    case actions.DELETE_CATEGORY_FAILURE:
      return state
        .setIn(['deleteCategory', 'pending'], false)
        .setIn(['deleteCategory', 'categoryId'], null)
        .setIn(['deleteCategory', 'error'], action.error);

    // Reset the state when the user logs out
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
