// Load dependencies
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Radium from 'radium';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

/**
* Renders a catch-all 404 nout found page.
*/
class YAMaintenance extends Component {

  /**
   * Handle the logic that should run before the component is completely mounted.
   */
  componentWillMount() {
    ReactGA.event({
      category: 'Application',
      action: '404: Not Found'
    });
  }

  render() {
    return (
      <div style={{ height: '100%' }}>

        {/* Render large error title */}
        <div
          className="flex"
          style={{ height: '100%' }}
        >
          <div className="row flex-grid">
            <div className="container">
              <div className="form-wrap">
                <h1 className="large-heading">
                  Temporary Maintenance
                </h1>
                <p>Oops. Looks like this page is currently out of service! Please try again later.</p>
                <p>
                  <NavLink
                    to="/"
                    className="btn btn-primary"
                    title="Back to Point B Portal"
                  >
                    Back ihto home
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(YAMaintenance.mapStateToProps, YAMaintenance.mapDispatchToProps)(Radium(YAMaintenance));