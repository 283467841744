// Load dependencies
import request from 'axios';
import moment from 'moment';
import { call, put } from 'redux-saga/effects';

// Export the actions that are available for the logs
export const GET_LOGS = 'GET_LOGS';
export const GET_LOGS_REQUEST = 'GET_LOGS_REQUEST';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOGS_FAILURE = 'GET_LOGS_FAILURE';

export const GET_ACCESSED_POSTS=  'GET_ACCESSED_POSTS';
export const GET_ACCESSED_POSTS_REQUEST = 'GET_ACCESSED_POSTS_REQUEST';
export const GET_ACCESSED_POSTS_SUCCESS = 'GET_ACCESSED_POSTS_SUCCESS';
export const GET_ACCESSED_POSTS_FAILURE = 'GET_ACCESSED_POSTS_FAILURE';

export const INVALIDATE_LOGS = 'INVALIDATE_LOGS';
export const INVALIDATE_ACCESSED_POSTS = 'INVALIDATE_ACCESSED_POSTS';
/**
* Submits a request to the server to get a list of logs that are stored on
* the server with some extra information about those logs.  Allows query
* parameters to paginate through the logs.
*
* @param {boolean} [all]
* @param {boolean} [distinct]
* @param {number} [limit]
* @param {number} [userId]
* @param {number} [page]
*/
export function* getLogs({ startDate, endDate, all, distinct, limit, userId, page } = { }) {
  try {

    if (startDate) {
      startDate = moment.utc(startDate).format('YYYY-MM-DD');
    }

    if (endDate) {
      endDate = moment.utc(endDate).format('YYYY-MM-DD');
    }

    // Make the request to the server for getting logs
    const params = { startDate, endDate, all, distinct, limit, page, userId };
    yield put({ type: GET_LOGS_REQUEST, params });
    const { data } = yield call(request.get, `${window.__configuration.api}/interaction`, { params });

    // Getting logs was successful, add them to the store
    yield put({ type: GET_LOGS_SUCCESS, meta: data.meta, payload: data.results });
    return data;
  } catch({ response: { data } }) {

    // Getting the logs has failed, set the error as to why
    yield put({ type: GET_LOGS_FAILURE, error: data.log });
    throw data.log;
  }
}

/**
* Submits a request to the server to get a list of logs relating to accessed posts
* that are stored on the server with some extra information about those logs. Allows query
* parameters to paginate through the logs.
*
* @param {boolean} [all]
* @param {boolean} [distinct]
* @param {number} [limit]
* @param {number} [userId]
* @param {number} [page]
*/
export function* getAccessedPosts({ startDate, endDate, all, distinct, limit, userId, page } = { }) {
  try {

    if (startDate) {
      startDate = moment.utc(startDate).format('YYYY-MM-DD');
    }

    if (endDate) {
      endDate = moment.utc(endDate).format('YYYY-MM-DD');
    }

    // Make the request to the server for getting logs
    const params = { startDate, endDate, all, distinct, limit, page, userId };
    params.type = 'post_view';

    yield put({ type: GET_ACCESSED_POSTS_REQUEST, params });
    const { data } = yield call(request.get, `${window.__configuration.api}/interaction`, { params });

    // Getting logs was successful, add them to the store
    yield put({ type: GET_ACCESSED_POSTS_SUCCESS, meta: data.meta, payload: data.results });
    return data;
  } catch({ response: { data } }) {

    // Getting the logs has failed, set the error as to why
    yield put({ type: GET_ACCESSED_POSTS_FAILURE, error: data.log });
    throw data.log;
  }
}

export function* clearLogs() {
    yield put({ type: INVALIDATE_LOGS });
}

export function* clearAccessedPosts() {
  yield put({ type: INVALIDATE_ACCESSED_POSTS });
}