// Load dependencies
import request from 'axios';
import { call, put } from 'redux-saga/effects';

// Export the actions that are available for the application
export const GET_APPLICATION_INFORMATION = 'GET_APPLICATION_INFORMATION';
export const GET_APPLICATION_INFORMATION_REQUEST = 'GET_APPLICATION_INFORMATION_REQUEST';
export const GET_APPLICATION_INFORMATION_SUCCESS = 'GET_APPLICATION_INFORMATION_SUCCESS';
export const GET_APPLICATION_INFORMATION_FAILURE = 'GET_APPLICATION_INFORMATION_FAILURE';

/**
 * Sends a request to the server to get information about the server.  Sends
 * the information to the state.
 */
export function* getApplicationInformation() {
  try {
    // Make the request to the server for getting classrooms
    yield put({ type: GET_APPLICATION_INFORMATION_REQUEST });
    const { data } = yield call(request.get, `${window.__configuration.api}/about`);

    // Getting information was successful, add it to the store
    yield put({ type: GET_APPLICATION_INFORMATION_SUCCESS, payload: data });
    return data;
  } catch ({ response: { data } }) {
    // Getting the information has failed, set the error as to why
    yield put({ type: GET_APPLICATION_INFORMATION_FAILURE, error: data.message });
    throw data.message;
  }
}
