/* global __CONTENT__ */

// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, Map } from 'immutable';
import Radium from 'radium';
import { font, defaults } from './../../components/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';

// Load custom components
import YAButton from './../../components/form/button';
import YAUserModal from './user-modal';
import YAReassignModal from './reassign-modal';
import YATable from './../../components/table/table';
import YAInput from './../../components/form/input';
import YALoader from './../../components/loader/loader';
// Load selectors
import {
  getUsers,
  getUsersPending,
  getCreateUserError,
  getUser,
  getUsersError
} from './../../selectors/user';

import { isLoggedIn } from './../../selectors/authenticator';
// Load actions
import {
  GET_USERS,
  GET_USER,
  CREATE_USER,
  DELETE_USER,
  ACTIVATE_USER,
  UPDATE_USER_ONBOARDED,
  getUserType,
  CLEAR_USER_ERROR
} from './../../actions/user';

// Load utilities
import { setState, setStateDeep } from './../../util/state';
import queryString from 'query-string';

/**
* Renders the main users table for staff members to view information
* about the users.
*/
class YAUsers extends Component {

  // Styles for the users container
  static styles = {
    absentButton: {
      marginRight: `${defaults.gutter / 2}px`
    },
    addUser: {
      color: defaults.white,
      marginLeft: `5px`
    },
    center: {
      textAlign: 'center'
    },
    dropzone: {
      border: 'none',
      display: 'inline-block',
      height: '36px',
      width: '36px'
    },
    nameColumn: {
      fontSize: font.size.large
    },
    search: {},
    searchIcon: {},
    right: {
      textAlign: 'right'
    },
    name: {
      display: 'inline-block',
      paddingLeft: '46px'
    },
    wrapper: {
      position: 'relative'
    }
  };

  /**
  * Determines which items the container needs from the store for rendering this
  * page.  These items are injected into the properties of the page.
  */
  static mapStateToProps(state) {
    return {
      newUserError: getCreateUserError(state),
      users: getUsers(state),
      user: getUser(state),
      usersPending: getUsersPending(state),
      getUsersError: getUsersError(state),
      isLoggedIn: isLoggedIn(state),
    };
  }

  /**
  * Determines which actions this page requires and those action dispatchers will
  * be injected into the properties of the page.
  */
  static mapDispatchToProps(dispatch) {
    return {
      createUser: options => dispatch({ type: CREATE_USER, ...options }),
      clearUserError: options => dispatch({ type: CLEAR_USER_ERROR }),
      getUsers: options => dispatch({ type: GET_USERS, ...options }),
      getUser: options => dispatch({ type: GET_USER, ...options }),
      deleteUser: options => dispatch({ type: DELETE_USER, ...options }),
      activateUser: options => dispatch({ type: ACTIVATE_USER, ...options }),
      updateUserOnboarded: options =>
        dispatch({ type: UPDATE_USER_ONBOARDED, ...options })
    };
  }

  // Properties that are passed to the users page
  static propTypes = {
    createUser: PropTypes.func.isRequired,
    clearUserError: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    deleteUser: PropTypes.func.isRequired,
    activateUser: PropTypes.func.isRequired,
    updateUserOnboarded: PropTypes.func.isRequired,
    newUserError: PropTypes.string,
    history: PropTypes.object.isRequired,
    users: PropTypes.instanceOf(Map).isRequired,
    user: PropTypes.instanceOf(Map),
    isLoggedIn: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  };

  static DEFAULT_QUERY_OPTIONS = { invalidate: true };

  // Initial state of theuserlistings page
  state = {
    newUser: Map({
      emailAddress: '',
      phoneNumber: '',
      canAccessWeb: false
    }),
    search: '',
    filter: 'all',
    queryOptions: YAUsers.DEFAULT_QUERY_OPTIONS,
    newUserModalOpen: false,
    reassignModalOpen: false,
    inactiveWorkerID: null,
    clickFilter: false,
    sort: false,
    sortBy: ''
  };

  componentDidMount() {
    const userType = getUserType(this.props.match.params.userType);
    this.setState({ userType });
    this.props.getUsers({ userType, includeAdmins: (userType === 'W' ? true : null) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.userType !== this.props.match.params.userType) {
      const userType = getUserType(this.props.match.params.userType);
      this.setState({
        filter: 'all',
        userType,
      });
      this.props.getUsers({ userType, includeAdmins: (userType === 'W' ? true : null) });
    }

  }

  reassign(id) {
    this.setState({
      reassignModalOpen: false
    });

    this.props.deleteUser({ id });
  }

  /**
   * Handles changing the user state by triggering the appropriate action
   */
  async handleItemStateChange(id, event) {
    if (!event.target) {
      return;
    }
    switch (event.target.value) {
      case 'pending':
        await this.props.updateUserOnboarded({
          id,
          onboarded: false
        });
        break;
      case 'active':
        await this.props.activateUser({ id });
        break;
      case 'inactive':
        this.setState({
          reassignModalOpen: true,
          inactiveWorkerID: id,
        });
        break;
    }
  }
  /**
  * Sets the given property to the state with the value that is passed from the
  * input box change event.
  *
  * @param {string} property
  * @param {object} event
  */
  handleChange(property, event) {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      // If running on an iOS device we will have to make sure that the input is
      // refocused.  For some reason iOS browsers have a bug with persisting changes
      // to the actual input values.
      event.persist();
    }
    this.setState(
      {
        [property]: event.target.value
      },
      () => {
        if (iOS) {
          event.target.focus();
        }
      }
    );
  }

  /**
  * Paginates the list of users with the page passed from the pagination
  * widget.  Reloads the list of users with the selected users.
  */
  paginateusers({ selected }) { } // TODO:

  /**
  * Sends the request to create a new user if the new user information has
  * been filled out and the data provided is valid.  Adds the user to the front
  * of the list if the request is successful.
  */
  createUser() {
    // Return early if the new student is not valid
    if (this.isUserValid()) {
      this.props.createUser({
        userType: this.state.newUser.get('canAccessWeb') ? 'A' : this.state.userType,
        ...this.state.newUser.toJS(),
        onSuccess: () => {
          setState.call(this, 'newUserModalOpen', false);
          this.resetUser();
        }
      });
    }
  }

  /**
  * Return whether or not the currently selected student is valid.
  */
  isUserValid() {
    if (this.state.newUser.get('canAccessWeb')) {
      return !!this.state.newUser.get('emailAddress').length;
    }
    return !!this.state.newUser.get('phoneNumber').length || !!this.state.newUser.get('emailAddress').length;
  }

  /**
   * Return the current state of the user. Available states are active, inactive
   * (if the user has been deleted), and pending (if the user has not yet onboarded)
   */
  getUserState(user) {
    if (!user || user.get('deletedAt')) {
      return 'inactive';
    }
    if (!user.get('onboarded')) {
      return 'pending';
    }
    return 'active';
  }

  /**
  * Resets the current `new` student that is stored in the state.  Sets the
  * birth date, first and last names back to their initial values.
  */
  resetUser() {
    this.setState(() => ({
      newUser: Map({
        emailAddress: '',
        phoneNumber: '',
        canAccessWeb: false
      })
    }));
  }

  /**
  * Link to the student currently stored at the given index in the list of getUsersusers
  * loaded in the properties.
  *
  * @param {number} index
  */
  linkToUser(index) {
    this.props.history.push(
      `/user/${this.props.users.getIn(['results', index, 'id'])}`
    );
  }

  /**
   * Refetches items based on the search input after more than 3 characters have been entered
   */
  handleSearch(event = { target: {} }) {
    this.setState({ search: event.target.value });

    const options = this.state.queryOptions || {
      invalidate: true
    };
    if (this.state.userType === 'W') { options.includeAdmins = true; }

    // Search term has been cleared, refetch
    if (!event.target.value) {
      this.getUsers(options);
      return;
    }

    if (event.target.value.length >= 3) {
      const searchOptions = { ...options, name: event.target.value };
      this.getUsers(searchOptions);
    }
  }
  /**
   * Handles selecting of a filter by re-fetching items accordingly
   * @param {string} filter
   */
  handleFilterSelect(filter) {
    this.setState({
      filter
    });
    const options = { invalidate: true };
    if (this.state.userType === 'W') { options.includeAdmins = true; }

    if (this.state.search) {
      options.name = this.state.search;
    }

    switch (filter) {
      case 'active':
        options.onboarded = true;
        options.deleted = false;
        break;
      case 'inactive':
        options.deleted = true;
        break;
      case 'pending':
        options.onboarded = false;
        break;
      default:
        break;
    }

    // Save the query options for further filtering (i.e filtering by worker after filtering by state)
    this.setState({
      queryOptions: options
    });

    this.getUsers(options);
  }

  async getUsers(options) {
    options.userType = this.state.userType;
    await this.props.getUsers(options);
  }
  handleSortClick(event, order) {
    this.setState({
      sort: !this.state.sort,
      sortBy: order
    });

    let options = this.state.sort ? { orderBy: order + " ASC", invalidate: true } : { orderBy: order + " DESC", invalidate: true };
    options.userType = this.state.userType;
    if (this.state.userType === 'W') { options.includeAdmins = true; }
    switch (this.state.filter) {
      case 'active':
        options.onboarded = true;
        options.deleted = false;
        break;
      case 'inactive':
        options.deleted = true;
        break;
      case 'pending':
        options.onboarded = false;
        break;
      default:
        break;
    }
    this.props.getUsers(options);
  }
  /**
  * Returns the header list that should be passed to the table component.
  */
  getHeader() {
    return List([
      // Render the header column to create a new parent
      <span key={1} onClick={() => this.handleSortClick(this, "firstName")}>Full Name
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretUp : faCaretDown}
          style={{ fontSize: 16, marginRight: 5 }} />
      </span>,

      <span key={2}>Phone Number</span>,

      <div key={3} onClick={() => this.handleSortClick(this, "emailAddress")}>
        Email Address
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretUp : faCaretDown}
          style={{ fontSize: 16, marginRight: 5 }} />
      </div>,

      <span key={4} onClick={() => this.handleSortClick(this, "online")}>Current Status
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretUp : faCaretDown}
          style={{ fontSize: 16, marginRight: 5 }} />
      </span>,

      <span key={5} >State</span>
    ]);
  }

  /**
  * Gets the body of the table by parsing the currently loaded users and
  * mapping them to a list of column contents to pass through.
  */
  getBody() {
    const desktop = window.innerWidth > 959;
    return this.props.users.get('results').map(user => [
      // Render the name of the user
      <div key={1}>
        <span>{user.get('fullName')}</span>
      </div>,

      <div key={2}>
        {desktop ? user.get('phoneNumber') :
          <a href="tel:" className="phone-icon">
            <FontAwesomeIcon
              icon={faPhone}
            />
          </a>}
      </div>,
      <div key={3}>{user.get('emailAddress')}</div>,
      <div key={4}>
        {user.get('online') === true ? (
          <span className="status-dot" />
        ) : (
          <span className="status-dot offline" />
        )}
        {desktop ?
          user.get('online') === true ? 'Online' : 'Offline'
          : ''}
      </div>,
      <div key={5}>
        <YAInput
          value={this.getUserState(user)}
          onClick={event => event.stopPropagation()}
          type="select"
          onChange={event => this.handleItemStateChange(user.get('id'), event)}
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="pending">Pending</option>
        </YAInput>
      </div>
    ]);
  }

  /**
  * Gets the keys that should be passed to each row so that the renderer can index
  * the rows for better performance.
  */
  getKeys() {
    return this.props.users.get('results').map(user => user.get('id'));
  }

  getUserTypeLong() {
    switch (this.state.userType) {
      case 'Y':
        return 'Youth';
      case 'W':
        return 'Worker';
      case 'A':
        return 'Admin';
      case 'C':
        return 'Adult';
      default:
        return 'User';
    }
  }

  renderSearch() {
    const searchBar = (
      <div className="search">
        <input
          type="text"
          onChange={this.handleSearch.bind(this)}
          className="form-control"
          placeholder="Search"
        />

        <FontAwesomeIcon
          icon={faSearch}
          fixedWidth
          className="form-control-feedback"
        />
      </div>
    );

    let search;
    window.innerWidth > 959 ? (
      search = searchBar
    ) : (
      search = (
        <div className="mobile-search-filter">
          {searchBar}
          <button onClick={() => { this.setState({ clickFilter: !this.state.clickFilter }); }}>
            Filter
            <FontAwesomeIcon
              icon={this.state.clickFilter ? faCaretDown : faCaretUp}
              style={{ fontSize: 16, marginRight: 5 }}
            />
          </button>
        </div>
      )
    );
    return search;
  }

  render() {
    // Get the required items to pass to the table component

    const header = this.getHeader();
    const body = this.getBody();
    const keys = this.getKeys();
    const search = this.renderSearch();

    let addNewBtn;
    window.innerWidth > 959 ?
      addNewBtn = (
        <YAButton
          onClick={setState.bind(this, 'newUserModalOpen', true)}
          type="primary"
        >
          {`Add New ${this.getUserTypeLong()}`}
          <span> +</span>
        </YAButton>
      ) : (
        addNewBtn = (
          <button
            className="mobile-add-btn"
            onClick={setState.bind(this, 'newUserModalOpen', true)}
          >
            <img src={`${window.__configuration.s3}/icons/plus.svg`} />
          </button>
        )
      );

    return (
      <div className="main users">
        <aside>
          {/* Render Add New button */}
          {addNewBtn}

          {search}

          {/* Render filters */}
          <ul className={this.state.clickFilter ? "filters" : "filters hidden-mobile"}>
            <li
              onClick={this.handleFilterSelect.bind(this, 'all')}
              className={this.state.filter === 'all' ? 'active' : ''}
            >
              All
            </li>
            <li
              onClick={this.handleFilterSelect.bind(this, 'active')}
              className={this.state.filter === 'active' ? 'active' : ''}
            >
              Active
            </li>
            <li
              onClick={this.handleFilterSelect.bind(this, 'pending')}
              className={this.state.filter === 'pending' ? 'active' : ''}
            >
              Pending
            </li>
            <li
              onClick={this.handleFilterSelect.bind(this, 'inactive')}
              className={this.state.filter === 'inactive' ? 'active' : ''}
            >
              Inactive
            </li>
          </ul>
        </aside>

        <section className="column">
          {/* Render the table for displaying the list of users */}
          <YATable
            body={body}
            header={header}
            keys={keys}
            onPaginationClick={this.paginateusers.bind(this)}
            onRowClick={this.linkToUser.bind(this)}
            pageCount={this.props.users.getIn(['meta', 'pageCount'])}
            showPagination={this.props.users.getIn(['meta', 'pageCount']) > 1}
          />
          <YALoader
            alternate="Loading..."
            image={`${window.__configuration.s3}/icons/loader.gif`}
            show={
              this.props.usersPending
            }
          />

          {/* Render the modal to create a new user (whether or not it is showing) */}
          {
            <YAUserModal
              phoneNumber={this.state.newUser.get('phoneNumber')}
              emailAddress={this.state.newUser.get('emailAddress')}
              canAccessWeb={this.state.newUser.get('canAccessWeb')}
              userType={this.state.userType}
              disabled={!this.isUserValid()}
              error={this.props.newUserError}
              header="Create User"
              onChange={setStateDeep.bind(this, 'newUser')}
              onCloseClick={() => {
                this.props.clearUserError();
                this.setState({ newUserModalOpen: false });
              }}
              onSaveClick={this.createUser.bind(this)}
              show={this.state.newUserModalOpen}
            />
          }
          {
            <YAReassignModal
              error={this.props.reassignError}
              header="Pressing continue will deactivate the user."
              onCloseClick={setState.bind(this, 'reassignModalOpen', false)}
              onContinueClick={this.reassign.bind(this, this.state.inactiveWorkerID)}
              show={this.state.reassignModalOpen}
              worker={this.state.inactiveWorkerID}
            />
          }
        </section>
      </div>
    );
  }
}

export default connect(YAUsers.mapStateToProps, YAUsers.mapDispatchToProps)(
  withRouter(Radium(YAUsers))
);
