/* global __NOOP__ */

/**
* Handle non-deep changes to the state given some property in the state and the
* value you would like to set to that property.
*
* @param {string} property
* @param {any} value
* @param {function} [callback=__NOOP__]
*/
export function setState(property, value, callback = __NOOP__) {

  // Coerce the callback to a function
  if (typeof callback !== 'function') {
    callback = __NOOP__;
  }

  // Only set the state of the object if the setState function is available
  if (this && typeof this === 'object' && typeof this.setState === 'function') {
    this.setState(() => ({ [property]: value }), callback);
  }
}

/**
* Handles setting the state of the currently bound (this) component to a deeply
* nested Map.  For example, if we have a Map object called `example`, we can
* set the value of `example.property` to the value provided.
*
* @param {string} stateProperty
* @param {string} deepProperty
* @param {any} value
* @param {function} [callback=__NOOP__]
*/
export function setStateDeep(stateProperty, deepProperty, value, callback = __NOOP__) {

  // Coerce the callback to a function
  if (typeof callback !== 'function') {
    callback = __NOOP__;
  }

  // Only set the state of the object if the setState function is available
  if (this && typeof this === 'object' && typeof this.setState === 'function') {
    this.setState(state => ({
      [stateProperty]: state[stateProperty].set(deepProperty, value)
    }), callback);
  }
}