/* global __NOOP__ */

// Load dependencies
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { defaults, font, input } from './../styles';

// Load components
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import TagsInput from 'react-tagsinput';
import PhoneInput from 'react-phone-number-input/input';

/**
* Abstraction for all of the different input types.  Renders the input with the
* specified type and any extra options that are provided to it.  Handles change
* events.  Does not store the value in the state, it takes the current value from
* the properties.
*/
class YAInput extends Component {

  // Properties for the input component
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    inputValue: PropTypes.any,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    noBaseStyles: PropTypes.bool,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onTagsChange: PropTypes.func,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.oneOf([
      'text', 'email', 'password', 'date', 'textarea', 'select', 'submit',
      'color', 'search', 'tel', 'url', 'checkbox', 'radio', 'tag', 'wysiwyg'
    ]).isRequired,
    value: PropTypes.any,
    checked: PropTypes.bool,
    filterDate: PropTypes.func,
  };

  // Default properties for the input component
  static defaultProps = {
    disabled: false,
    noBaseStyles: false,
    onChange: __NOOP__,
    onKeyDown: __NOOP__,
    onTagsChange: __NOOP__,
    style: {}
  };

  // Styles for the different input elements
  static styles = {
    base: {
      boxSizing: 'border-box',
      width: '100%',
      display: 'block',
      // border:          `1px solid ${ defaults.borderColor }`,
      // padding:         input.padding,
      lineHeight: 1,
      backgroundColor: input.bg,
      // borderRadius:    input.borderRadius,
      // height:          input.heightBase
    },
    select: {
      appearance: 'textfield'
    },
    textarea: {
      minHeight: 100
    },
    label: {
      display: 'inline-block',
      cursor: 'pointer',
      fontFamily: font.family.sansSerif,
      color: defaults.textColor,
      lineHeight: 1,
      marginRight: input.padding
    },
    checkbox: {
      width: '16px',
      height: '16px',
      display: 'inline-block',
      lineHeight: 1,
      position: 'relative',
      top: 1,
      cursor: 'pointer'
    },
    checkboxLabel: {
      lineHeight: 1,
      marginRight: 10,
      height: 'auto',
      fontWeight: 'bold',
      marginBottom: '5',
      fontSize: 14,
      color: defaults.textColor
    },
    checkradio: {
      boxSizing: 'border-box',
      display: 'inline-block',
      marginRight: (input.padding / 1.5),
      appearance: 'textfield',
      width: '14px',
      height: '14px',
      backgroundColor: '#f00',
      position: 'relative',
      top: 4,
      cursor: 'text',
      border: 'none'
    }
  };

  clearTagInput() {
    return this.refs.tag._clearInput();
  }

  render() {
    switch (this.props.type) {

      // Checkbox has been specified as the input type
      case 'checkbox':
        return (
          <label style={this.props.noBaseStyles ? undefined : YAInput.styles.checkboxLabel}>
            <input
              className={this.props.className}
              disabled={this.props.disabled}
              checked={this.props.checked}
              onChange={this.props.onChange}
              type={this.props.type}
              style={[
                this.props.noBaseStyles ? {} : YAInput.styles.checkbox,
                this.props.style
              ]}
            />
            {this.props.placeholder}
          </label>
        );

      // Render the datepicker if the date type is selected
      case 'date':
        return (
          <DatePicker
            popperProps={{ strategy: 'fixed' }}
            className={classNames('ya-datepicker', this.props.className)}
            dateFormat="yyyy-MM-dd"
            filterDate={this.props.filterDate}
            disabled={this.props.disabled}
            maxDate={this.props.maxDate}
            minDate={this.props.minDate}
            onChange={this.props.onChange}
            placeholderText={this.props.placeholder}
            selected={this.props.value && new Date(this.props.value)}
            showYearDropdown
            style={{ backgroundColor: 'red' }}
          />
        );

      // Radio button has been specified as the input type
      case 'radio':
        return (
          <label style={this.props.noBaseStyles ? undefined : YAInput.styles.label}>
            <input
              className={this.props.className}
              disabled={this.props.disabled}
              onChange={this.props.onChange}
              checked={this.props.checked}
              type={this.props.type}
              style={[
                this.props.noBaseStyles ? {} : YAInput.styles.checkbox,
                this.props.style
              ]}
              value={this.props.value}
            />
            {this.props.placeholder}
          </label>
        );

      // Select box has been specified as the input type
      case 'select':
        return (
          <select
            className={this.props.className}
            disabled={this.props.disabled}
            onChange={this.props.onChange}
            onClick={this.props.onClick}
            placeholder={this.props.placeholder}
            style={[
              // this.props.noBaseStyles ? { } : YAInput.styles.base,
              // this.props.noBaseStyles ? { } : YAInput.styles.select,
              this.props.style
            ]}
            value={this.props.value}
          >
            {this.props.children}
          </select>
        );

      // Submit button has been specified as the input type
      case 'submit':
        return (
          <input
            className={this.props.className}
            disabled={this.props.disabled}
            style={[
              this.props.noBaseStyles ? {} : YAInput.styles.base,
              this.props.style
            ]}
            type={this.props.type}
            value={this.props.value}
          />
        );

      // Telephone input was specified, render an input masked telephone number
      case 'tel':
        return (
          <PhoneInput
            className={this.props.className}
            onChange={this.props.onChange}
            country='US'
            // options={{ phone: true, phoneRegionCode: 'US' }}
            style={{
              ...(this.props.noBaseStyles ? {} : YAInput.styles.base),
              ...this.props.style
            }}
          />);

      // Text area has been specified as an input type
      case 'textarea':
        return (
          <textarea
            className={this.props.className}
            disabled={this.props.disabled}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown}
            placeholder={this.props.placeholder}
            style={[
              this.props.noBaseStyles ? {} : YAInput.styles.base,
              this.props.noBaseStyles ? {} : YAInput.styles.textarea,
              this.props.style
            ]}
            value={this.props.value}
          >
          </textarea>
        );

      // Render the tags input if tag is the selected type
      case 'tag':
        return (
          <TagsInput
            inputProps={{
              onChange: this.props.onChange,
              onKeyDown: this.props.onKeyDown,
              style: _.assign(
                {},
                YAInput.styles.base,
                this.props.style
              )
            }}
            onChange={this.props.onTagsChange}
            onlyUnique
            ref="tag"
            value={this.props.value}
          />
        );

      // Render the wysiwyg editor if specified
      case 'wysiwyg': {

        // Define the list of modules that the wysiwyg editor supports
        const modules = {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
              { align: '' },
              { align: 'right' },
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' }
            ]
          ]
        };

        // Define the list of formats that the wysiwyg editor supports
        const formats = [
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent', 'align'
        ];

        return (
          <ReactQuill
            className={this.props.className}
            formats={formats}
            modules={modules}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown}
            readOnly={this.props.disabled}
            style={this.props.style}
            theme="snow"
            value={this.props.value}
          />
        );
      }

      // Any other input can be rendered in a general case
      default:
        return (
          <input
            className={this.props.className}
            disabled={this.props.disabled}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown}
            placeholder={this.props.placeholder}
            style={[
              this.props.noBaseStyles ? {} : YAInput.styles.base,
              this.props.style
            ]}
            type={this.props.type}
            value={this.props.value}
          />
        );
    }
  }
}

// Export component, connected to Radium
export default Radium(YAInput);