// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

/**
 * Render a Popover that gives a descroption of the feed's purpose.
 */
class YATooltip extends Component {
  // Properties for the message card component
  static propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string
  };

  // Icon URL for the info popover
  static INFO_BUTTON_URL = `${window.__configuration.cdn}/icons/feed-info.svg`;

  // Styles for the message card component
  static styles = {
    base: {
      boxSizing: 'border-box',
      position: 'relative',
      display: 'inline-block',
      backgroundColor: 'transparent',
      backgroundImage: `url(${YATooltip.INFO_BUTTON_URL})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '100%',
      marginLeft: 3,
      width: '16px',
      height: '16px',
      textIndent: -9999,
      overflow: 'hidden',
      opacity: .5,
      padding: 0
    }
  };

  render() {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={
          <Popover id="popover-trigger-click-root-close"
            title={this.props.type}
          >
            {this.props.children}
          </Popover>
        }>
        <Button style={YATooltip.styles.base}>
          {this.props.placeholder}
        </Button>
      </OverlayTrigger>
    );
  }
}

// Export component, connected to Radium
export default Radium(YATooltip);