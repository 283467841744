// Select the current list of workers with meta information

export function getTags(state) {
    return state.activity.get('tags');
  }

  export function getActivities(state) {
    return state.activity.get('activities');
  }

  export function getActivity(state) {
    return state.activity.get('activity');
  }
  
  export function getCreateTagError(state) {
    return state.activity.getIn(['createTag', 'error']);
  }

  export function getCreateActivityError(state) {
    return state.activity.getIn(['createActivity', 'error']);
  }

  export function getUpdateActivityError(state) {
    return state.activity.getIn(['updateActivity', 'error']);
  }

  export function areActivitiesReady(state) {
    return (
      !!state.activity.getIn(['activities', 'meta']) &&
        !state.activity.getIn(['getActivities', 'pending'])
    );
  }