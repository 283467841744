// Load dependencies
import classNames from 'classnames';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import moment from 'moment-timezone';
import { isEmail } from 'validator';
import { LOGIN, IS_LOGGED_IN } from './../../actions/authenticator';
import { getLoginError, getLoggedInPending, isLoggedIn } from './../../selectors/authenticator';

import { defaults } from './../../components/styles';

// Load components
import YAAlert from './../../components/form/alert';
import YAButton from './../../components/form/button';
import YAInput from './../../components/form/input';
import YALoader from './../../components/loader/loader';
/**
* Renders the login page so that the user is able to log into the application if
* they are not currently logged in.  If the user is currently logged in then they
* will be redirected to the main page.
*/
class YALogin extends Component {

  // Properties that are passed to the login page
  static propTypes = {
    loginError: PropTypes.string,
    login: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    checkIsLoggedIn: PropTypes.func.isRequired,
    loggedInPending: PropTypes.bool.isRequired,
  };

  // Default styles for the login page
  static styles = {
    controls: {
      display: 'flex',
      flexDirection: 'column'
    },
    forgotPassword: {
      color: defaults.white
    },
    support: {
      marginTop: 10
    }
  };

  /**
  * Checks if the user is logged in before displaying the contents of the login
  * page.  If the user is logged in then we send them to the main page.  Otherwise
  * we let the user continue.
  *
  * @param {object} store - Redux store passed from the router
  */
  static onEnter(store) {
    return (nextState, replaceState, callback) => {
      callback();
      // store.dispatch({ // TODO: temp
      //   type: IS_LOGGED_IN,
      //   onLoggedIn: () => {
      //     replaceState('/');
      //     callback();
      //   },
      //   onNotLoggedIn: callback
      // });
    };
  }

  /**
  * Determines which items the container needs from the store for rendering this
  * page.  These items are injected into the properties of the page.
  */
  static mapStateToProps(state) {
    return {
      loginError: getLoginError(state),
      loggedInPending: getLoggedInPending(state),
      isLoggedIn: isLoggedIn(state),
    };
  }

  /**
  * Determines which actions this page requires and those action dispatchers will
  * be injected into the properties of the page.
  */
  static mapDispatchToProps(dispatch) {
    return {
      login: options => dispatch({ type: LOGIN, ...options }),
      checkIsLoggedIn: (options) => dispatch({ type: IS_LOGGED_IN, ...options }),
    };
  }

  // Initial state for the login page
  state = {
    emailAddress: '',
    errorMessage: '',
    password: ''
  };

  /**
   * Handle the logic that should run before the component is completely mounted.
   */
  componentDidMount() {
    this.props.checkIsLoggedIn({
      onLoggedIn: () => {
        this.props.history.push('/');
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loggedInPending && !this.props.loggedInPending) {
      if (this.props.isLoggedIn) {
        this.props.history.push('/');
      } else {
        this.setState({
          errorMessage: 'Invalid login credentials.'
        });
      }
    }
  }

  /**
  * Given an event, set the state of the specified property to the value provided
  * from the event.
  *
  * @param {string} property
  * @param {object} event
  */
  onChange(property, event) {
    this.setState({
      [property]: event.target.value
    });
  }

  /**
   * Returns whether or not the login form is currently valid.
   *
   * @return {boolean} Whether or not the form has a valid email address and password
   */
  isFormValid() {
    return isEmail(this.state.emailAddress) && this.state.password.length;
  }

  /**
   * Segues to the forgot password screen with the given email address if the
   * email address provided by the user is a valid email.
   */
  goToForgotPassword() {
    this.props.history.push(`/forgot${isEmail(this.state.emailAddress) ?
      `?emailAddress=${encodeURIComponent(this.state.emailAddress)}` :
      ''
      }`);
  }

  /**
  * Sends the login request to the server and if successful redirects the user
  * to the main page.  If unsuccessful shows an alert message that the login
  * has failed.
  */
  sendLoginRequest(event) {

    // Prevent the page from unloading from the form submit
    event.preventDefault();

    // Don't do anything if the form is not valid
    if (!this.isFormValid()) {
      return;
    }

    // Make the request to the server to login
    this.props.login({
      emailAddress: this.state.emailAddress,
      password: this.state.password,
      // onLoginSuccess: () => {
      //   // ReactGA.event({
      //   //   category: 'Login',
      //   //   action: 'success',
      //   //   label: 'From Login Page'
      //   // });

      //   // Login was successful, send the user to the main page
      //   console.log("History push");
      //   this.props.history.push('/');
      // },
      // onLoginFailure: () => {
      //   // ReactGA.event({
      //   //   category: 'Login',
      //   //   action: 'failure',
      //   //   label: 'From Login Page'
      //   // });
      //   // Set an error message
      //   console.log("Error");
      //   this.setState({
      //     errorMessage: 'Invalid login credentials.'
      //   });
      // }
    });
  }

  render() {
    return (
      <main
        className="splash"

      // style={{
      //   backgroundImage: `url(${window.__configuration.s3}/splash/splash.png)`
      // }}
      >
        <span />

        <form
          className="stripped"
          onSubmit={this.sendLoginRequest.bind(this)}
        >

          {/* Render the application logo */}
          <img
            src={`${window.__configuration.s3}/logos/logo.png`}
            className="login-logo"
            alt="Point B - You are here."
          />

          <fieldset>

            {/* Render an alert if the login has failed for any reason */}
            {
              this.props.loginError !== null ?
                <YAAlert type="E">
                  <span>{this.state.errorMessage}</span>
                </YAAlert> :
                null
            }

            {/* Collect the email address from the user */}
            <YAInput
              type="email"
              className="stripped-input"
              noBaseStyles
              onChange={this.onChange.bind(this, 'emailAddress')}
              placeholder="Your Email Address"
              value={this.state.emailAddress}
            />

            {/* Collect the password from the user */}
            <YAInput
              type="password"
              className="stripped-input"
              noBaseStyles
              onChange={this.onChange.bind(this, 'password')}
              placeholder="Your Password"
              value={this.state.password}
            />

            <div style={YALogin.styles.controls}>

              {/*  Button which submits the login request to the server */}
              <div>
                <YAInput
                  type="submit"
                  style={{ lineHeight: 1 }}
                  className={classNames('btn btn-default btn-block')}
                  // noBaseStyles
                  value="Sign In"
                />

                <YALoader
                  alternate="Loading..."
                  image={`${window.__configuration.s3}/icons/loader.gif`}
                  show={
                    this.props.loggedInPending
                  }
                />
              </div>

              {/* Button which takes the user to the forgot password screen */}

              <p style={YALogin.styles.support}> Need support? <a href="mailto:lily.lumsden@ymcahbb.ca">Contact lily.lumsden@ymcahbb.ca</a></p>
              <div>
                <YAButton
                  onClick={this.goToForgotPassword.bind(this)}
                  size="tiny"
                  style={YALogin.styles.forgotPassword}
                  type="text"
                >
                  Forgot Password
                </YAButton>
                |
                <YAButton
                  onClick={() => this.props.history.push('/privacy')}
                  size="tiny"
                  style={YALogin.styles.forgotPassword}
                  type="text"
                >
                  Terms of Use
                </YAButton>
              </div>
            </div>


          </fieldset>
        </form>

        {/* Render footer logo */}
        <footer className="brand-bar login">
          <img
            src={`${window.__configuration.s3}/logos/ymca-logo.png`}
            className="footer-logo"
            alt="YMCA of Hamilton | Burlington | Brantford"
          />
        </footer>

      </main>
    );
  }
}

export default connect(YALogin.mapStateToProps, YALogin.mapDispatchToProps)(withRouter(Radium(YALogin)));