// Load dependencies
import Color from 'color';

// Sane defaults for general items
export const defaults = {
  textColor:         '#666',
  white:             '#fff',
  black:             '#231f20',
  gutter:            30,
  borderRadius:      4,
  borderRadiusSmall: 3,
  borderRadiusLarge: 6
};

defaults.borderColor = Color(defaults.black).lighten(5.5).hex();

// Media Queries
export const mediaQueries = {
  screenXs: 360,
  screenSm: 640,
  screenMd: 960,
  screenLg: 1260
};

// Default brand colours
export const brand = {
  primary: {
    base:    Color('#B31849').hex(),
    darken:  Color('#B31849').darken(0.35).hex(),
    lighten: Color('#B31849').lighten(0.35).hex()
  },
  secondary: {
    base:    Color('#FBB03B').hex(),
    darken:  Color('#FBB03B').darken(0.35).hex(),
    lighten: Color('#FBB03B').lighten(0.35).hex()
  },
  black: {
    base:    Color(defaults.black).hex(),
    darken:  Color(defaults.black).darken(1).hex(),
    lighten: Color(defaults.black).lighten(0.35).hex()
  },
  info: {
    base:    Color('#5bc0de').hex(),
    darken:  Color('#5bc0de').darken(0.35).hex(),
    lighten: Color('#5bc0de').lighten(0.35).hex()
  },
  warning: {
    base:    Color('#f0ad4e').hex(),
    darken:  Color('#f0ad4e').darken(0.35).hex(),
    lighten: Color('#f0ad4e').lighten(0.35).hex()
  },
  success: {
    base:    Color('#5cb85c').hex(),
    darken:  Color('#5cb85c').darken(0.35).hex(),
    lighten: Color('#5cb85c').lighten(0.35).hex()
  },
  danger: {
    base:    Color('#d9534f').hex(),
    darken:  Color('#d9534f').darken(0.35).hex(),
    lighten: Color('#d9534f').lighten(0.35).hex()
  },
  dropzone: '#adadad',
  feed: {
    A: '#db3069',
    D: '#8661c1',
    T: '#0071bc',
    M: '#39b54a',
    P: '#222222',
    R: '#52bf9d',
    E: '#fbb03a'
  }
};

brand.feed.Y = brand.primary.base;

// Default font styles
export const font = {
  family: {
    serif:     '"Times New Roman", georgia, times, sans-serif',
    sansSerif: '"Open Sans", helvetica, arial, sans-serif'
  },
  size: {
    base:  '14px',
    small: '12px',
    tiny:  '10px',
    large: '18px'
  },
  weight: {
    normal: 400,
    bold:   700
  }
};

// Default button styles
export const button = {
  base: {
    padding:      (defaults.gutter / 3),
    borderRadius: defaults.borderRadiusSmall
  },
  small: {
    fontSize:     font.size.small,
    padding:      (defaults.gutter / 4)
  },
  tiny: {
    fontSize:     font.size.tiny,
    padding:      (defaults.gutter / 4)
  }
};

// Default input styles
export const input = {
  padding:      (defaults.gutter / 3),
  borderRadius: defaults.borderRadiusSmall,
  bg:           '#fff',
  color:        defaults.textColor,
  heightBase:   (defaults.gutter * 1.25)
};

// Default alert styles
export const alert = {
  padding:      (defaults.gutter / 2),
  borderRadius: defaults.borderRadiusSmall,
  bg:           '#fff',
  color:        defaults.textColor,
  fontSize:     font.size.small,
  fontFamily:   font.family.sansSerif
};

// Default modal styles
export const modal = {
  bg:           '#fff',
  color:        defaults.textColor,
  fontSize:     font.size.base,
  fontFamily:   font.family.sansSerif,
  backdrop:     'rgba(0, 0, 0, .75)',
  header: {
    bg:           Color(defaults.black).lighten(6.25).hex(),
    fontSize:     font.size.large,
    color:        brand.primary.base,
    borderColor:  Color(defaults.black).lighten(6.25).hex()
  },
  footer: {
    fontSize:     '16px',
    bg:           Color(defaults.black).lighten(6.25).hex()
  },
  close: {
    position: {
      x:          (defaults.gutter / 2),
      y:          (defaults.gutter / 2)
    },
    color:        defaults.borderColor,
    fontWeight:   700
  }
};

// Default popover styles
export const popover = {
  padding:      (defaults.gutter / 2),
  borderRadius: defaults.borderRadiusSmall,
  bg:           brand.black.base,
  color:        Color(defaults.black).lighten(5).hex(),
  hoverColor:   defaults.white,
  fontSize:     font.size.large,
  fontFamily:   font.family.sansSerif,
  borderColor:  Color(defaults.black).lighten(1.5).hex(),
  baseSize:     24,
  baseWidth:    240
};

// Default loading spinner styles
export const loading = {
  bg:     'rgba(255, 255, 255, .85)',
  width:  32,
  height: 32
};

// Default feed item meal plan styles
export const mealplan = {
  bg:           Color(defaults.black).lighten(6.25).hex(),
  fontSize:     font.size.small,
  padding:      (defaults.gutter / 3),
  borderRadius: defaults.borderRadiusSmall,
  fontFamily:   font.family.sansSerif,
  borderColor:  Color(defaults.black).lighten(5).hex()
};