// Load dependencies
import Immutable, { Map } from 'immutable';
import * as actions from './../actions/authenticator';

// Set the initial state
const initialState = Map({
  authToken: null,
  loggedIn: false,
  // profile: null, TODO: temp

  login: Map({
    pending: false,
    error: null
  }),

  // TODO: test
  profile: Map({
    id: 1,
    fullName: 'Test Name'
  }),

  getProfile: Map({
    pending: false,
    error: null
  }),

  forgotPassword: Map({
    pending: false,
    params: Map({
      emailAddress: null
    }),
    error: null
  }),

  resetPassword: Map({
    pending: false,
    error: null
  })
});

/**
* Handles the management of the state for all authentication related actions
* such as logging in and out.  Stores the current authentication token and
* whether or not the user is logged in.
*
* @param {object} state
* @param {object} action
*
* @return {object} New state after applying the action
*/
export default function authenticator(state = initialState, action) {
  switch (action.type) {

    // Login request is coming in, set the pending flag
    case actions.LOGIN_REQUEST:
      return state
        .setIn(['login', 'pending'], true)
        .setIn(['login', 'error'], null);

    // Login request succeeded, set the authentication token and logged in flag
    case actions.LOGIN_SUCCESS:
      return state
        .set('authToken', action.authToken)
        .set('loggedIn', true)
        .setIn(['login', 'pending'], false);

    // Login request failed, set the reason why it failed
    case actions.LOGIN_FAILURE:
      return state
        .setIn(['login', 'pending'], false)
        .setIn(['login', 'error'], action.error);

    // Logout request has come in, clear out the authentication details
    case actions.LOGOUT:
      return state
        .set('authToken', null)
        .set('loggedIn', false)
        .set('profile', null);

    // Set whether or not the user is logged in
    case actions.SET_LOGGED_IN:
      return state.set('loggedIn', action.loggedIn);

    // Get profile request coming in, set the pending flag
    case actions.GET_PROFILE_REQUEST:
      return state
        .setIn(['getProfile', 'pending'], true)
        .setIn(['getProfile', 'error'], null);

    // Get profile request succeeded, set the profile
    case actions.GET_PROFILE_SUCCESS:
      return state
        .set('profile', Immutable.fromJS(action.profile))
        .setIn(['getProfile', 'pending'], false);

    // Get profile request failed, set the reason why it failed
    case actions.GET_PROFILE_FAILURE:
      return state
        .setIn(['getProfile', 'pending'], false)
        .setIn(['getProfile', 'error'], action.error);

    // Request has come in for user forgot password, store the parameters
    case actions.FORGOT_PASSWORD_REQUEST:
      return state
        .setIn(['forgotPassword', 'pending'], true)
        .setIn(['forgotPassword', 'params'], Immutable.fromJS(action.params))
        .setIn(['forgotPassword', 'error'], null);

    // Successfully sent a request for forgot password, reset the parameters
    case actions.FORGOT_PASSWORD_SUCCESS:
      return state
        .setIn(['forgotPassword', 'pending'], false)
        .setIn(['forgotPassword', 'params'], initialState.getIn(['forgotPassword', 'params']));

    // Unable to set the password to forgotten, store the error message
    case actions.FORGOT_PASSWORD_FAILURE:
      return state
        .setIn(['forgotPassword', 'pending'], false)
        .setIn(['forgotPassword', 'params'], initialState.getIn(['forgotPassword', 'params']))
        .setIn(['forgotPassword', 'error'], action.error);

    // Request has come in for user reset password, store the parameters
    case actions.RESET_PASSWORD_REQUEST:
      return state
        .setIn(['resetPassword', 'pending'], true)
        .setIn(['resetPassword', 'error'], null);

    // Successfully sent a request for reset password, reset the parameters
    case actions.RESET_PASSWORD_SUCCESS:
      return state.setIn(['resetPassword', 'pending'], false);

    // Unable to reset the password on the server, store the error message
    case actions.RESET_PASSWORD_FAILURE:
      return state
        .setIn(['resetPassword', 'pending'], false)
        .setIn(['resetPassword', 'error'], action.error);

    // Set the authentication token to the store
    case actions.SET_TOKEN:
      return state.set('authToken', action.authToken);

    default:
      return state;
  }
}