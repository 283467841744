// Load dependencies
import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmail } from 'validator';
import { FORGOT_PASSWORD, IS_LOGGED_IN } from './../../actions/authenticator';

// Load components
import YAAlert from './../../components/form/alert';
import YAInput from './../../components/form/input';
import queryString from 'query-string';

class YAForgotPassword extends Component {

  // Properties that are passed to the forgot password page
  static propTypes = {
    emailAddress: PropTypes.string,
    forgotPassword: PropTypes.func.isRequired,
    forgotPasswordError: PropTypes.string,
    location: PropTypes.object
  };

  /**
  * Checks if the user is logged in before displaying the contents of the forgot password
  * page.  If the user is logged in then we send them to the main page.  Otherwise
  * we let the user continue.
  *
  * @param {object} store - Redux store passed from the router
  */
  static onEnter(store) {
    return (nextState, replaceState, callback) => {
      store.dispatch({
        type: IS_LOGGED_IN,
        onLoggedIn: () => {
          replaceState('/');
          callback();
        },
        onNotLoggedIn: callback
      });
    };
  }

  /**
  * Determines which items the container needs from the store for rendering this
  * page.  These items are injected into the properties of the page.
  */
  static mapStateToProps(state) {
    return {
      forgotPasswordError: state.authenticator.getIn(['forgotPassword', 'error'])
    };
  }

  /**
  * Determines which actions this page requires and those action dispatchers will
  * be injected into the properties of the page.
  */
  static mapDispatchToProps(dispatch) {
    return {
      forgotPassword: (emailAddress, options) => dispatch({ type: FORGOT_PASSWORD, emailAddress, ...options })
    };
  }

  // Initial state for the forgot password page
  state = {
    done: false,
    emailAddress: ''
  };

  /**
   * Handle the logic that should run before the component is completely mounted.
   */
  componentWillMount() {

    // Set the email address to the state if one is passed from the previous page

    const emailAddress = queryString.parse(this.props.location.search).emailAddress;
    if (typeof emailAddress === 'string' && emailAddress.length) {
      this.setState({ emailAddress: decodeURIComponent(emailAddress) });
    }
  }

  /**
   * Returns whether or not the email address that the user has typed in is a valid
   * email address.
   *
   * @return {boolean} Whether or not the email address provided is valid
   */
  isFormValid() {
    return isEmail(this.state.emailAddress) && !this.state.done;
  }

  /**
  * Sends a request to the server that the user has forgotten their password.  If
  * the request is successful then we disable the form so that the user does not
  * send multiple requests.
  */
  handleSendForgotPasswordRequest(event) {

    // Prevent the page from unloading from the form submit
    event.preventDefault();

    // Don't do anything if the email address is not valid
    if (!this.isFormValid()) {
      return;
    }

    // Don't make the request if the user is already done
    if (!this.state.done) {
      this.props.forgotPassword(this.state.emailAddress, {
        onSuccess: () => {
          ReactGA.event({
            category: 'Authentication',
            action: 'forgot password',
            label: 'From Forgot Password'
          });

          this.setState({
            done: true
          });
        }
      });
    }
  }

  /**
  * Given an event, set the state of the specified property to the value provided
  * from the event.
  *
  * @param {string} property
  * @param {object} event
  */
  onChange(property, event) {
    this.setState({
      [property]: event.target.value
    });
  }

  /**
   * Render the component based on the properties and state provided.
   *
   * @return {object} Node that should be rendered
   */
  render() {
    return (
      <main
        className="splash"
      >
        <span />

        <form
          className="stripped"
          onSubmit={this.handleSendForgotPasswordRequest.bind(this)}
        >

          {/* Render the application logo */}
          <Link to="/login">
            <img
              src={`${window.__configuration.s3}/logos/logo.png`}
              className="login-logo"
              alt="Point B - You are here."
            />
          </Link>

          <fieldset>

            {
              // Render an alert if the request has failed for any reason
              this.props.forgotPasswordError !== null ?
                <YAAlert type="E">
                  <span>{this.props.forgotPasswordError}</span>
                </YAAlert> :
                null
            }

            {
              // Render an alert if the user has successfully sent the request
              this.state.done ?
                <YAAlert type="S">
                  Check your inbox, we have sent you a link to reset your password.
                </YAAlert> :
                null
            }

            {/* Collect the email address from the user */}

            <YAInput
              type="email"
              className="stripped-input"
              noBaseStyles
              onChange={this.onChange.bind(this, 'emailAddress')}
              placeholder="Your Email Address"
              value={this.state.emailAddress}
            />

            {/*  Button which submits the forgot password request to the server */}
            <YAInput
              type="submit"
              className={classNames('btn btn-default btn-block', { disabled: !this.isFormValid() })}
              // noBaseStyles
              style={{ lineHeight: 1 }}
              value="Submit"
            />
          </fieldset>
        </form>

        {/* Render footer logo */}
        <footer className="brand-bar login">
          <img
            src={`${window.__configuration.s3}/logos/ymca-logo.png`}
            className="footer-logo"
            alt="YMCA of Hamilton | Burlington | Brantford"
          />
        </footer>
      </main>
    );
  }
}

export default connect(YAForgotPassword.mapStateToProps, YAForgotPassword.mapDispatchToProps)(YAForgotPassword);