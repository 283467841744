/* global __NOOP__ */

// Load dependencies
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Radium from 'radium';
import { defaults, modal } from './../styles';

/**
* Base skeleton for a modal which takes a header text, contents node and footer
* node and renders each inside of a modal.
*/
class YAModal extends Component {

  // Property types for the modal component
  static propTypes = {
    children: PropTypes.node.isRequired,
    footer: PropTypes.node,
    header: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func,
    show: PropTypes.bool,
    style: PropTypes.object,
  };

  // Default property values for the modal component
  static defaultProps = {
    onCloseClick: __NOOP__,
    show: false
  };

  // Default styles for the modal component
  static styles = {
    base: {
      boxSizing: 'border-box',
      position: 'relative',
      display: 'block',
      fontFamily: modal.fontFamily,
      fontSize: modal.fontSize,
      backgroundColor: modal.bg,
      color: modal.color,
      width: '60%',
      maxWidth: 500,
      margin: `${(defaults.gutter * 2)}px auto`,
    },
    backdrop: {
      boxSizing: 'border-box',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: modal.backdrop,
      overflowY: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      boxSizing: 'border-box',
      padding: `${defaults.gutter / 1.5}px ${defaults.gutter}px`,
      fontSize: modal.header.fontSize,
      color: 'white',
      borderBottom: `1px solid ${modal.header.borderColor}`
    },
    body: {
      padding: `${defaults.gutter}px`,
      minHeight: '100px',
      maxHeight: '300px',
      overflow: 'auto'
    },
    footer: {
      padding: `${defaults.gutter / 1.5}px ${defaults.gutter}px`,
      backgroundColor: modal.footer.bg,
      fontSize: modal.footer.fontSize,
      textAlign: 'right'
    },
    close: {
      position: 'absolute',
      top: modal.close.position.y,
      right: modal.close.position.x,
      lineHeight: 1,
      textDecoration: 'none',
      fontWeight: modal.close.fontWeight,
      color: modal.close.color
    }
  };

  /**
  * We need to lock the #content/.canvas div when the modal is open
  * otherwise users are able to scroll the modal outside of the viewport.
  *
  * @return {void}
  */
  componentWillReceiveProps(newProps) {
    // Get our dom element
    let canvas = document.getElementById('content');

    if (this.props.show !== newProps.show) {
      if (newProps.show === true) {
        if (canvas && canvas.classList && !canvas.classList.contains('locked')) {
          canvas.classList.add('locked');
        }
      } else if (newProps.show === false) {
        if (canvas && canvas.classList && canvas.classList.contains('locked')) {
          canvas.classList.remove('locked');
        }
      }
    }
  }

  /**
  * Scrolls to the top of the modal body.  This would be used mostly in mobile
  * environments where the height of the modal exceeds the maximum allowable modal
  * height.
  *
  * @return {void}
  */
  scrollToTop() {
    const modalBody = ReactDOM.findDOMNode(this.refs.body);
    modalBody.scrollTop = 0;
  }

  render() {

    // Don't render the modal if show is set to `false`
    if (!this.props.show) {
      return null;
    }

    return (
      <div
        className="modal-backdrop"
        style={YAModal.styles.backdrop}
      >
        <div style={[YAModal.styles.base, this.props.style]}>
          <header style={YAModal.styles.header}>
            {this.props.header}

            {/* Render the button to close the modal */}
            <a
              onClick={this.props.onCloseClick}
              style={YAModal.styles.close}
            >
              <svg width="20" height="21" xmlns="http://www.w3.org/2000/svg">
                <g stroke="#FFF" fill="none" fillRule="evenodd" strokeLinecap="square">
                  <path d="M.21446586.9491161l19.4343472 19.43434719M.21446584 20.35660172L19.64881298.92225458" />
                </g>
              </svg>
            </a>
          </header>

          {/* Render the contents of the modal */}


          <div
            ref="body"
            style={YAModal.styles.body}
            className="modal-body"
          >
            {this.props.children}
          </div>


          {/* Render the contents of the footer */}
          <div style={YAModal.styles.footer}>
            {this.props.footer}
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(YAModal);