// Load dependencies
import Immutable, { List, Map } from 'immutable';
import * as actions from './../actions/user';
import { LOGOUT } from './../actions/authenticator';
import moment from 'moment';

// Set the initial state
const initialState = Map({
  users: Map({
    meta: null,
    results: List()
  }),

  workers: Map({
    meta: null,
    results: List()
  }),

  getWorkers: Map({
    pending: false,
    params: Map({
      onboarded: null,
      includeAdmins: null,
      all: null,
      limit: null,
      page: null
    }),
    error: null
  }),

  getUser: Map({
    pending: false,
    id: null,
    error: null
  }),

  getUsers: Map({
    pending: false,
    params: Map({
      activityId: null,
      activityStatus: null,
      userType: null,
      onboarded: true,
      deleted: false,
      worker: null,
      limit: null,
      name: null,
      page: null,
      orderBy: null,
      includeAdmins: false
    }),
    error: null,
  }),

  createUser: Map({
    pending: false,
    params: Map({
      userType: null,
      phoneNumber: null,
      emailAddress: null
    }),
    error: null
  }),

  updateUser: Map({
    pending: false,
    id: null,
    params: Map({
      birthDate: null,
      firstName: null,
      lastName: null,
      onboarded: null,
      isDefaultAdmin: null,
      notes: null,
      worker: null,
      emailAddress: null,
      phoneNumber: null,
      webAccess: null
    }),
    error: null
  }),

  deleteUser: Map({
    id: null,
    pending: false,
    error: null
  }),

  activateUser: Map({
    id: null,
    pending: false,
    error: null
  }),

  updateUserProfilePicture: Map({
    id: null,
    pending: false,
    params: Map({
      picture: null
    }),
    error: null
  }),

  updateUserOnboarded: Map({
    id: null,
    pending: false,
    onboarded: false,
    error: null
  }),
});

/**
* Handles the management of the state for all user related actions such as
* getting users, adding users, removing users and updating users.
* Also handles setting permissions, workers and the user's profile picture.
*
* @param {object} state
* @param {object} action
*
* @return {object} New state after applying the action
*/
export default function user(state = initialState, action) {
  switch (action.type) {
    // Get users request has come in, set the parameters to the store
    case actions.GET_USERS_REQUEST:
      return state
        .setIn(['getUsers', 'pending'], true)
        .setIn(['getUsers', 'params'], Map(action.params))
        .setIn(['getUsers', 'error'], null);

    // Get users request has succeeded, save the users to the store
    case actions.GET_USERS_SUCCESS:
      return state
        .setIn(['users', 'meta'], Map(action.meta))
        .setIn(['users', 'results'], Immutable.fromJS(action.payload))
        .setIn(['getUsers', 'pending'], false);

    // Get users request has failed, save the error to the store
    case actions.GET_USERS_FAILURE:
      return state
        .setIn(['getUsers', 'pending'], false)
        .setIn(['getUsers', 'error'], action.error);

    // Get workers request has come in, set the parameters to the store
    case actions.GET_WORKERS_REQUEST:
      return state
        .setIn(['getWorkers', 'pending'], true)
        .setIn(['getWorkers', 'params'], Map(action.params))
        .setIn(['getWorkers', 'error'], null);

    // Get workers request has succeeded, save the users to the store
    case actions.GET_WORKERS_SUCCESS:
      return state
        .setIn(['workers', 'meta'], Map(action.meta))
        .setIn(['workers', 'results'], Immutable.fromJS(action.payload))
        .setIn(['getWorkers', 'pending'], false);

    // Get workers request has failed, save the error to the store
    case actions.GET_WORKERS_FAILURE:
      return state
        .setIn(['getWorkers', 'pending'], false)
        .setIn(['getWorkers', 'error'], action.error);
    // Get workers request has failed, save the error to the store       

    // Get user request has come in, set the parameter to the store
    case actions.GET_USER_REQUEST:
      return state
        .setIn(['getUser', 'pending'], true)
        .setIn(['getUser', 'id'], action.id)
        .setIn(['getUser', 'error'], null);

    // Get user request has succeeded, save the user to the store
    case actions.GET_USER_SUCCESS:
      return state
        .set('user', Immutable.fromJS(action.user))
        .setIn(['getUser', 'pending'], false);

    // Get user request has failed, save the error to the store
    case actions.GET_USER_FAILURE:
      return state
        .setIn(['getUser', 'pending'], false)
        .setIn(['getUser', 'error'], action.error);

    // Clear errors from creating user
    case actions.CLEAR_USER_ERROR_REQUEST:
      return state
        .setIn(['createUser', 'error'], null);

    // Request to create a new user has come in, store the parameters
    case actions.CREATE_USER_REQUEST:
      return state
        .setIn(['createUser', 'pending'], true)
        .setIn(['createUser', 'params'], Map(action.params))
        .setIn(['createUser', 'error'], null);

    // User was created successfully, add the user to the list of users
    case actions.CREATE_USER_SUCCESS:
      return state
        .setIn(
          ['users', 'results'],
          state.getIn(['users', 'results']).unshift(
            Immutable.fromJS({
              ...action.user,
              workers: []
            })
          )
        )
        .setIn(['createUser', 'pending'], false);

    // Request to create a post has succeeded, set the flags back to normal

    // User creation has failed, store the error message in the store
    case actions.CREATE_USER_FAILURE:
      return state
        .setIn(['createUser', 'pending'], false)
        .setIn(['createUser', 'error'], action.error);

    // Request to update a user has come in, store the request parameters
    case actions.UPDATE_USER_REQUEST:
      return state
        .setIn(['updateUser', 'pending'], true)
        .setIn(['updateUser', 'id'], action.id)
        .setIn(['updateUser', 'params'], Map(action.params))
        .setIn(['updateUser', 'error'], null);

    // User was updated successfully, store the updated values
    case actions.UPDATE_USER_SUCCESS:
      return state
        .set('user', Immutable.fromJS(action.user))
        .setIn(['updateUser', 'pending'], false);

    // Updating the user has failed, store the error message
    case actions.UPDATE_USER_FAILURE:
      return state
        .setIn(['updateUser', 'pending'], false)
        .setIn(['updateUser', 'error'], action.error);

    // Action has come in to start the process of removing a user, store the parameters
    case actions.DELETE_USER_REQUEST:
      return state
        .setIn(['deleteUser', 'pending'], true)
        .setIn(['deleteUser', 'id'], action.id)
        .setIn(['deleteUser', 'error'], null);

    // Successfully deleted the user, remove the user from any lists
    case actions.DELETE_USER_SUCCESS:
      return state
        .updateIn(['users', 'results'], users => {
          // Users list is empty, just do nothing
          if (!users || !users.size) {
            return users;
          }

          // Check to see if the userd is inside of the list of userds
          const id = state.getIn(['deleteUser', 'id']);
          const index = users.findIndex(user => user.get('id') === id);

          // User was found in the list, return the list without that user
          if (index !== -1) {
            return users.update(index, user =>
              user.set('deletedAt', moment())
            );
          }

          // Return the list since it does not contain the user
          return users;
        })
        .update('user', user => {
          // There is currently no user selected, just return nothing
          if (!user) {
            return user;
          }

          // The user being deleted is the one selected, clear the user out
          const id = state.getIn(['deleteUser', 'id']);
          if (user.get('id') === id) {
            return null;
          }

          // User is not the user who was deleted, leave them be
          return user;
        })
        .setIn(['deleteUser', 'pending'], false)
        .setIn(['deleteUser', 'id'], null);

    // Unable to delete the user, store the error message in the store
    case actions.DELETE_USER_FAILURE:
      return state
        .setIn(['deleteUser', 'pending'], false)
        .setIn(['deleteUser', 'id'], null)
        .setIn(['deleteUser', 'error'], action.error);

    // Request to activate a user has come in, store the parameters
    case actions.ACTIVATE_USER_REQUEST:
      return state
        .setIn(['activateUser', 'pending'], true)
        .setIn(['activateUser', 'id'], action.id)
        .setIn(['activateUser', 'error'], null);

    // Successfully removed a user, remove it from the user if it's there
    case actions.ACTIVATE_USER_SUCCESS:
      return state
        .updateIn(['users', 'results'], users => {
          // User doesn't exist or is empty, short circuit and exit
          if (!users || !users.size) {
            return users;
          }

          // Search for the deleted user in the list of users
          const id = state.getIn(['activateUser', 'id']);
          const index = users.findIndex(user => user.get('id') === id);

          // User was found in the list, remove soft delete
          if (index !== -1) {
            return users.update(index, user =>
              user.set('deletedAt', null).set('onboarded', true)
            );
          }

          // User was not found in the user, do nothing
          return users;
        })
        .setIn(['activateUser', 'pending'], false)
        .setIn(['activateUser', 'id'], null);

    // Unable to delete the user, store the error message from the server
    case actions.ACTIVATE_USER_FAILURE:
      return state
        .setIn(['activateUser', 'pending'], false)
        .setIn(['activateUser', 'id'], null)
        .setIn(['activateUser', 'error'], action.error);

    case actions.UPDATE_USER_ONBOARDED_REQUEST:
      return state
        .setIn(['updateUserOnboarded', 'pending'], true)
        .setIn(['updateUserOnboarded', 'id'], action.id)
        .setIn(['updateUserOnboarded', 'error'], null);

    case actions.UPDATE_USER_ONBOARDED_SUCCESS:
      return state
        .updateIn(['users', 'results'], users => {
          // User doesn't exist or is empty, short circuit and exit
          if (!users || !users.size) {
            return users;
          }

          const id = state.getIn(['updateUserOnboarded', 'id']);
          const index = users.findIndex(user => user.get('id') === id);

          // User was found in the list, remove soft delete
          if (index !== -1) {
            return users.update(index, user =>
              user.set('onboarded', action.user.onboarded)
            );
          }

          // User was not found in the user, do nothing
          return users;
        })
        .setIn(['updateUserOnboarded', 'pending'], false)
        .setIn(['updateUserOnboarded', 'id'], null);

    case actions.UPDATE_USER_ONBOARDED_FAILURE:
      return state
        .setIn(['updateUserOnboarded', 'pending'], false)
        .setIn(['updateUserOnboarded', 'id'], null)
        .setIn(['updateUserOnboarded', 'error'], action.error);

    // User's profile picture was updated successfully, update the link for the user
    case actions.UPDATE_USER_PROFILE_PICTURE_SUCCESS:
      return state
        .update('user', user => {
          // Current user is not set, don't do anything
          if (!user || !Map.isMap(user)) {
            return user;
          }

          // Current user is not the one being updated, just return their instance
          if (user.get('id') !== action.user.id) {
            return user;
          }

          return user.set(
            'profilePicture',
            Immutable.fromJS(action.user.profilePicture)
          );
        })
        .setIn(['updateUserProfilePicture', 'pending'], false);

    // Could not update the profile picture, store the error as to why not
    case actions.UPDATE_USER_PROFILE_PICTURE_FAILURE:
      return state
        .setIn(['updateUserProfilePicture', 'pending'], false)
        .setIn(['updateUserProfilePicture', 'error'], action.error);

    // Reset the state when the user logs out
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
