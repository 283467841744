/* global __NOOP__ */

// Load dependencies
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Radium from 'radium';
import moment from 'moment';
// Load styles
import { defaults, modal } from './../../components/styles';

// Load components
import YAAlert from './../../components/form/alert';
import YAButton from './../../components/form/button';
import YAInput from './../../components/form/input';
import YAModal from './../../components/modal/modal';

/**
* Renders a modal for entering a date
*/
class YADateSelector extends Component {

  // Properties that are available for the parent modal
  static propTypes = {
    ..._.pick(YAModal.propTypes, ['header', 'onCloseClick', 'show']),
    error: PropTypes.string,
    onChange: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    start: PropTypes.object,
    end: PropTypes.object,
    showDelete: PropTypes.bool
  };

  // Default property values of the parent modal
  static defaultProps = {
    ..._.pick(YAModal.defaultProps, ['onCloseClick', 'show']),
    onChange: __NOOP__,
    onDeleteClick: __NOOP__,
    onSaveClick: __NOOP__,
    showDelete: false
  };

  // Default styles for the parent modal component
  static styles = {
    input: {
      backgroundColor: modal.footer.bg
    },
    row: {
      marginTop: `${defaults.gutter / 2}px`
    }
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.setState({
      start: this.props.start,
      end: this.props.end
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.start !== this.state.start) {
      this.setState({
        start: newProps.start
      });
    }
    if (newProps.end !== this.state.end) {
      this.setState({
        end: newProps.end
      });
    }
  }

  /**
  * Call the handler of the parameter change.  Sends the value that changed and
  * the property to store that value in (email address, first name, last name, etc.)
  *
  * @param {string} property
  * @param {object} event
  */

  getFieldValue(property) {
    return this.state[property];
  }

  handleChange(property, event) {
    // Fixes case when switch current language triggers this function
    if (!event) {
      return;
    }


    this.setState({
      [property]:
        typeof event === 'string'
          ? event
          : moment.isDate(event) ? moment(event) : (event.target && event.target.value)
    });
  }

  /**
  * Renders the footer of the parent modal which consists of the controls to
  * close the modal and to save the changes.
  */

  renderFooter() {
    return (
      <div>

        {/* Render the button to cancel and close the modal */}
        <YAButton
          onClick={this.props.onCloseClick}
          type="cancel"
        >
          Cancel
        </YAButton>

        {
          // Render the button to delete the parent and close the modal
          this.props.showDelete ?
            <YAButton
              onClick={this.props.onDeleteClick}
              size="small"
              type="text"
            >
              Delete
            </YAButton> :
            null
        }

        {/* Render the button to save the changes */}
        <YAButton
          onClick={() => this.props.onSaveClick(this.state.start, this.state.end)}
          type="primary"
        >
          Save
        </YAButton>
      </div>
    );
  }

  render() {
    return (
      <YAModal
        ref="modal"
        header={this.props.header}
        footer={this.renderFooter()}
        onCloseClick={this.props.onCloseClick}
        show={this.props.show}
      >

        {/* Render the error message if there is one */}
        <div>
          {
            this.props.error ?
              <YAAlert type="E">
                {this.props.error}
              </YAAlert> :
              null
          }
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Start Date:</label>

              <YAInput
                type="date"
                filterDate={(date) => {
                  return moment() > date;
                }}
                onChange={this.handleChange.bind(this, 'start')}
                className="form-control"
                value={this.getFieldValue('start')}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>End Date:</label>

              <YAInput
                type="date"
                filterDate={(date) => {
                  return moment() > date;
                }}
                onChange={this.handleChange.bind(this, 'end')}
                className="form-control"
                value={this.getFieldValue('end')}
              />
            </div>
          </div>
        </div>
      </YAModal>
    );
  }
}

export default Radium(YADateSelector);