// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

// Load containers
import YAUsers from './../users/users';
import YAResources from './../resources/resources';
import YANews from './../news/news';
import YAPost from './../post/post';
import YAActivity from './../activities/activity';
import YAForgotPassword from './../login/forgot-password';
import YALogin from './../login/login';
import YAMain from './../main/main';
import YADashboard from './../dashboard/dashboard';
import YAResetPassword from './../login/reset-password';
import YANoMatch from './../nomatch/nomatch';
import YAMaintenance from './../maintenance/maintenance';
import YAActivities from './../activities/activities';
import YAPrivacyPolicy from './../login/privacy-policy';

export default class YARouter extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  };

  /**
   * Handle the logic that should run before the component is completely mounted.
   */
  componentWillMount() {
    // Start Google Analytics if a code is provided in the configuration
    if (window.__configuration.ga) {
      ReactGA.initialize(window.__configuration.ga);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>

          {/* Login page handles the user logging into the admin portal */}
          <Route
            path="/login"
            render={(props) => <YALogin {...props} />}
          />

          {/* Forgot password page handles the user resetting their password */}
          <Route
            path="/forgot"
            render={(props) => <YAForgotPassword {...props} />}
            onEnter={YAForgotPassword.onEnter(this.props.store)}
          />

          {/* Reset password page handles the user setting a new password */}
          <Route
            path="/reset"
            render={(props) => <YAResetPassword {...props} />}
            onEnter={YAResetPassword.onEnter(this.props.store)}
          />

          {/* RStatic Privacy Policy Page */}
          <Route path="/privacy" render={(props) => <YAPrivacyPolicy {...props} />} />

          <Route path="/maintenance" render={(props) => <YAMaintenance {...props} />} />

          {/* Handles the main logic and renders all but one of the pages */}
          <Route
            path="/"
            render={(props) => <YAMain {...props}>
              <Switch>
                {/* TODO: what to load up first? */}
                <Route
                  exact path="/"
                  render={(props) => <YAUsers {...props} />}
                />
                {/* Render the detailed overview of a specific user (youth or worker) */}
                <Route
                  path="/user/:userId"
                  render={(props) => <YADashboard {...props} />}
                />
                {/* Route grouping for outreach workers (table listing and overview page) */}
                <Route path="/users/:userType" render={(props) => <YAUsers {...props} />} />
                {/* Route grouping for admins (table listing and overview page) */}
                {
                  // <Route path="admin">
                  //   {/* Route for the list of admins */}
                  //   <IndexRoute render={(props) => <YAAdmins {...props} />} />
                  // </Route>
                  /* Route grouping for news posts (table listing, post details and add post page) */
                }
                <Route path="/news">
                  {/* News page displays the available list of news posts */}
                  <Route path="/" render={(props) => <YANews {...props} />} />
                </Route>

                {/* Route grouping for resource posts (table listing, post details and add post page) */}
                <Route path="/resource">
                  {/* News page displays the available list of resource posts */}
                  <Route path="/" render={(props) => <YAResources {...props} />} />
                </Route>

                {/* Create a new news post */}
                <Route path="/post/news" render={(props) => <YAPost {...props} />} />

                <Route path="/tag" render={(props) => <YAActivity {...props} />} />
                <Route path="/tags" render={(props) => <YAActivities {...props} />} />

                {/* Create a new resource post */}
                <Route path="/post/resource" render={(props) => <YAPost {...props} />} />

                <Route path="*" render={(props) => <YANoMatch {...props} />} />
              </Switch>

            </YAMain>}
          />

          {/* Render a 404 Page */}
          <Route path="*" render={(props) => <YANoMatch {...props} />} />
        </Switch>
      </BrowserRouter>
    );
    return (
      <Router history={browserHistory}>
        {/* Handles the main logic and renders all but one of the pages */}
        <Route
          path="/"
          render={(props) => <YAMain {...props} />}
          onEnter={YAMain.onEnter(this.props.store)}
        >
          {/* TODO: what to load up first? */}
          <IndexRoute onEnter={YAUsers.onEnter(this.props.store)} render={(props) => <YAUsers {...props} />} />
          {/* Render the detailed overview of a specific user (youth or worker) */}
          <Route
            path="user/:userId"
            render={(props) => <YADashboard {...props} />}
            onEnter={YADashboard.onEnter(this.props.store)}
          />
          {/* Route grouping for outreach workers (table listing and overview page) */}
          <Route path="users/:userType" render={(props) => <YAUsers {...props} />} onEnter={YAUsers.onEnter(this.props.store)} />
          {/* Route grouping for admins (table listing and overview page) */}
          {
            // <Route path="admin">
            //   {/* Route for the list of admins */}
            //   <IndexRoute render={(props) => <YAAdmins {...props} />} />
            // </Route>
            /* Route grouping for news posts (table listing, post details and add post page) */
          }
          <Route path="news">
            {/* News page displays the available list of news posts */}
            <IndexRoute render={(props) => <YANews {...props} />} />
          </Route>

          {/* Route grouping for resource posts (table listing, post details and add post page) */}
          <Route path="resource">
            {/* News page displays the available list of resource posts */}
            <IndexRoute render={(props) => <YAResources {...props} />} />
          </Route>

          {/* Create a new news post */}
          <Route path="post/news" render={(props) => <YAPost {...props} />} />

          <Route path="tag" render={(props) => <YAActivity {...props} />} />
          <Route path="tags" render={(props) => <YAActivities {...props} />} />

          {/* Create a new resource post */}
          <Route path="post/resource" render={(props) => <YAPost {...props} />} />
        </Route>

        {/* Login page handles the user logging into the admin portal */}
        <Route
          path="/login"
          render={(props) => <YALogin {...props} />}
          onEnter={YALogin.onEnter(this.props.store)}
        />

        {/* Forgot password page handles the user resetting their password */}
        <Route
          path="/forgot"
          render={(props) => <YAForgotPassword {...props} />}
          onEnter={YAForgotPassword.onEnter(this.props.store)}
        />

        {/* Reset password page handles the user setting a new password */}
        <Route
          path="/reset"
          render={(props) => <YAResetPassword {...props} />}
          onEnter={YAResetPassword.onEnter(this.props.store)}
        />

        {/* RStatic Privacy Policy Page */}
        <Route path="/privacy" render={(props) => <YAPrivacyPolicy {...props} />} />

        <Route path="/maintenance" render={(props) => <YAMaintenance {...props} />} />

        {/* Render a 404 Page */}
        <Route path="*" render={(props) => <YANoMatch {...props} />} />
      </Router>
    );
  }
}
