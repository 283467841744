/* global __NOOP__ */

// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import { brand, defaults, font } from './../styles';

// Load components
import Dropzone from 'react-dropzone';

/**
* Renders a dropzone which accepts a pdf, video or image file as an input and returns the
* file that is dropped or selected.
*/
class YAAttachmentDropzone extends Component {

  // Properties that are passed to the attachment dropzone component
  static propTypes = {
    ...Dropzone.propTypes,
    fileName: PropTypes.string,
    onDeleteClick: PropTypes.func,
    showDeleteButton: PropTypes.bool,
    success: PropTypes.bool
  };

  // Default property values of the attachment dropzone component
  static defaultProps = {
    ...Dropzone.defaultProps,
    onDeleteClick: __NOOP__,
    showDeleteButton: false,
    success: false
  };

  // Styles for the attachment dropzone component
  static styles = {
    base: {
      display: 'block',
      height: '250px',
      minWidth: '100%',
      position: 'relative',
      border: 'dashed #CCC 3px',
      transition: 'background-color 0.25s linear',
      backgroundColor: '#DDD',
      margin: 'auto',
      flexShrink: 0
    },
    error: {
      backgroundColor: brand.primary.lighten
    },
    fileName: {
      color: defaults.white,
      fontSize: font.size.large,
      left: `${defaults.gutter / 2}px`,
      right: `${defaults.gutter / 2}px`,
      overflow: 'hidden',
      position: 'absolute',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      top: 'calc(50% + 36px)',
      whiteSpace: 'nowrap',
      width: `calc(100% - ${defaults.gutter}px)`
    },
    interior: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%'
    },
    placeholder: {
      display: 'block',
      flex: 1,
      height: 'auto',
      margin: `0 auto`,
      width: '20%'
    },
    success: {
      backgroundColor: brand.success.lighten
    },
    close: {
      background: brand.primary.base,
      position: 'absolute',
      width: 22,
      height: 22,
      padding: 0,
      top: -10,
      right: -10,
      borderRadius: '50%',
      textAlign: 'center',
      color: '#fff',
      lineHeight: 1.5,
      fontWeight: 700,
      fontSize: 14,
      cursor: 'pointer'
    }
  };

  // Get the placeholder image links
  static uploadPlaceholder = `${window.__configuration.s3}/icons/document.svg`;
  static successPlaceholder = `${window.__configuration.s3}/icons/document-success.svg`;

  /**
   * Handles the delete button click, stops propagation to opening the file selector.
   *
   * @param {object} event
   */
  handleDeleteClick(event) {
    event.stopPropagation();
    this.props.onDeleteClick();
  }

  /**
  * When a file is dropped it is sent to the onDrop function.  We use this function
  * to bypass the dropped files being returned as an array.
  */
  handleDrop(files) {
    return this.props.onDrop(files[0], files);
  }

  render() {
    return (
      <Dropzone
        accept={"application/pdf, image/*, video/*"}
        activeStyle={{ ...YAAttachmentDropzone.styles.base, ...YAAttachmentDropzone.styles.success }}
        key="dropzone"
        onDropAccepted={this.handleDrop.bind(this)}
        rejectStyle={{ ...YAAttachmentDropzone.styles.base, ...YAAttachmentDropzone.styles.error }}
        style={_.assign({}, YAAttachmentDropzone.styles.base, this.props.style)}
      >
        <div style={YAAttachmentDropzone.styles.interior}>

          {
            // Close circle after adding a doc
            this.props.showDeleteButton ?
              <span
                onClick={this.handleDeleteClick.bind(this)}
                style={YAAttachmentDropzone.styles.close}
              >
                &times;
              </span> :
              null
          }

          <img
            src={
              this.props.success ?
                YAAttachmentDropzone.successPlaceholder :
                YAAttachmentDropzone.uploadPlaceholder
            }
            style={YAAttachmentDropzone.styles.placeholder}
          />

          {
            // Render the name of the file selected if there is one
            this.props.fileName ?
              <div style={YAAttachmentDropzone.styles.fileName}>
                {this.props.fileName}
              </div> :
              null
          }
        </div>
      </Dropzone>
    );
  }
}

// Export component, connected to Radium
export default Radium(YAAttachmentDropzone);