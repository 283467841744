/* global __NOOP__ */

// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';
import { brand, defaults, font, alert } from './../styles';

/**
* Renders an alert box depending on the type provided.  These types correspond
* to the typical alert types (info, success, warning, etc.)
*/
class YAAlert extends Component {

  // Properties for the alert component
  static propTypes = {
    children: PropTypes.node.isRequired,
    onCloseClick: PropTypes.func,
    type: PropTypes.oneOf(['E', 'I', 'S', 'W']).isRequired
  };

  // Default property values for the alert component
  static defaultProps = {
    onCloseClick: __NOOP__
  };

  // Styles for the different alert boxes
  static styles = {
    base: {
      position: 'relative',
      display: 'block',
      fontFamily: font.family.sansSerif,
      fontSize: alert.fontSize,
      color: alert.color,
      backgroundColor: alert.bg,
      padding: alert.padding,
      borderRadius: alert.borderRadius,
      textAlign: 'center'
    },
    close: {
      color: defaults.black,
      cursor: 'pointer',
      height: '20px',
      position: 'absolute',
      right: '0px',
      textDecoration: 'none',
      top: '0px',
      width: '20px'
    },
    strong: {
      textTransform: 'uppercase',
      display: 'inline-block',
      marginRight: '5px',
      error: {
        color: brand.danger.base
      },
      info: {
        color: brand.info.base
      },
      warning: {
        color: brand.warning.base
      },
      success: {
        color: brand.success.base
      }
    },
    tip: {
      boxSizing: 'border-box',
      position: 'absolute',
      width: '16px',
      height: '16px',
      left: '50%',
      marginLeft: '-8px',
      borderTop: `8px solid ${alert.bg}`,
      borderRight: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderLeft: '8px solid transparent',
      bottom: '-16px'
    }
  };

  // Valid alert types for the alert component
  static alertTypes = {
    E: 'Error',
    I: 'Info',
    S: 'Success',
    W: 'Warning'
  };

  /**
  * Returns the relevant message for the alert based on it's currently selected
  * type.
  *
  * @return {string} Alert type that should be displayed inline with the message
  */
  getMessage() {
    return YAAlert.alertTypes[this.props.type];
  }

  render() {
    return (
      <div>
        <div style={YAAlert.styles.base}>

          {
            // Render a close button if a close click function is provided
            this.props.onCloseClick.toString() !== __NOOP__.toString() ?
              <a
                onClick={this.props.onCloseClick}
                style={YAAlert.styles.close}
              >
                x
              </a> :
              null
          }

          {/* Render the type of alert */}
          <strong
            style={[
              YAAlert.styles.strong,
              YAAlert.styles.strong[this.getMessage().toLowerCase()]
            ]}
          >
            {this.getMessage()}:
          </strong>

          {/* Render the contents of the alert */}
          {this.props.children}

          {/* Render a bottom center triangle tick */}
          <span style={YAAlert.styles.tip} />
        </div>
      </div>
    );
  }
}

// Export component, connected to Radium
export default Radium(YAAlert);