// Load dependencies
import request from 'axios';
import { call, put } from 'redux-saga/effects';

// Export the actions that are available for the application
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_LANGUAGES_REQUEST = 'GET_LANGUAGES_REQUEST';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_FAILURE = 'GET_LANGUAGES_FAILURE';

/**
 * Sends a request to the server to retrieve the site languages and translations.
 */
export function* getLanguages() {
  try {
    // Make the request to the server for getting languages
    yield put({ type: GET_LANGUAGES_REQUEST });
    const { data } = yield call(request.get, `${window.__configuration.api}/language`);

    // Getting information was successful, add it to the store
    yield put({ type: GET_LANGUAGES_SUCCESS, payload: data.results });
    return data.results;
  } catch ({ response: { data } }) {
    // Getting the regions has failed, set the error as to why
    yield put({ type: GET_LANGUAGES_FAILURE, error: data.message });
    throw data.message;
  }
}
