/* global __CONTENT__ */

// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, Map } from 'immutable';
import { join } from 'lodash';
import moment from 'moment';
import Radium from 'radium';
import { font, defaults } from './../../components/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
// Load custom components
import YAButton from './../../components/form/button';
import YATable from './../../components/table/table';
import YAInput from './../../components/form/input';
import YALoader from './../../components/loader/loader';

// Load actions
import { GET_RESOURCES, UPDATE_POST, DELETE_POST, UNDELETE_POST } from './../../actions/post';
import { GET_CATEGORIES } from './../../actions/category';

// Load selectors
import { getPosts, arePostsReady, getPostsPending } from './../../selectors/post';
import { getCategories } from './../../selectors/category';

// Load utilities
import { getProfile } from './../../selectors/authenticator';
/**
* Renders the main resources table for staff members to view information
* about the resources.
*/
class YAResources extends Component {
  // Properties that are passed to the resources page
  static propTypes = {
    getResources: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    ready: PropTypes.bool,
    updatePost: PropTypes.func.isRequired,
    deletePost: PropTypes.func.isRequired,
    undeletePost: PropTypes.func.isRequired,
    resources: PropTypes.instanceOf(Map).isRequired,
    categories: PropTypes.instanceOf(Map).isRequired,
    getCategories: PropTypes.func.isRequired,
    profile: PropTypes.instanceOf(Map).isRequired,
  };

  // Styles for the resources container
  static styles = {
    absentButton: {
      marginRight: `${defaults.gutter / 2}px`
    },
    center: {
      textAlign: 'center'
    },
    dropzone: {
      border: 'none',
      borderRadius: '0px',
      display: 'inline-block',
      height: '36px',
      width: '36px'
    },
    nameColumn: {
      fontSize: font.size.large
    },
    search: {},
    searchIcon: {},
    right: {
      textAlign: 'right'
    },
    name: {
      display: 'inline-block',
      paddingLeft: '46px'
    },
    wrapper: {
      position: 'relative'
    },
  };

  static POST_TYPE = 'R';

  /**
  * Determines which items the container needs from the store for rendering this
  * page.  These items are injected into the properties of the page.
  */
  static mapStateToProps(state) {
    return {
      resources: getPosts(state),
      categories: getCategories(state),
      ready: arePostsReady(state),
      resourcesPending: getPostsPending(state),
      profile: getProfile(state)
    };
  }

  /**
  * Determines which actions this page requires and those action dispatchers will
  * be injected into the properties of the page.
  */
  static mapDispatchToProps(dispatch) {
    return {
      getResources: options => dispatch({ type: GET_RESOURCES, ...options }),
      getCategories: options => dispatch({ type: GET_CATEGORIES, ...options }),
      updatePost: options => dispatch({ type: UPDATE_POST, postType: YAResources.POST_TYPE, ...options }),
      deletePost: options => dispatch({ type: DELETE_POST, ...options }),
      undeletePost: options => dispatch({ type: UNDELETE_POST, ...options })
    };
  }

  // Initial state of theresourcelistings page
  state = {
    search: '',
    queryOptions: { invalidate: true },
    filter: 'all',
    featuredPostId: null,
    clickFilter: false,
    sort: false,
    sortBy: null,
    page: 0
  };

  /**
  * Loads in the initial list of resources. Redirects to dashboard if request fails.
  */
  async componentWillMount() {

    await this.props.getCategories({ invalidate: true });

    await this.props.getResources({ invalidate: true, orderBy: "title ASC" });

    // Store the featured post in state to render featured start indicator for this post
    const featuredPost = this.props.resources.get('results').find(post => post.get('featured'));
    this.setState({
      featuredPostId: featuredPost && featuredPost.get('id')
    });
  }

  /**
  * Store the featured post in state to render featured start indicator for this post
  * TODO: scroll to top see weemarkable feed
  */
  componentWillReceiveProps(props) {
    const featuredPost = props.resources.get('results').find(post => post.get('featured'));
    this.setState({
      featuredPostId: featuredPost && featuredPost.get('id')
    });
  }

  /**
  * Sets the given property to the state with the value that is passed from the
  * input box change event.
  *
  * @param {string} property
  * @param {object} event
  */
  handleChange(property, event) {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      // If running on an iOS device we will have to make sure that the input is
      // refocused.  For some reason iOS browsers have a bug with persisting changes
      // to the actual input values.
      event.persist();
    }
    this.setState(
      {
        [property]: event.target.value
      },
      () => {
        if (iOS) {
          event.target.focus();
        }
      }
    );
  }

  handleAddResourceClick() {
    this.props.history.push(`/post/resource`);
  }

  async handleItemStateChange(postId, event) {
    if (!event.target) {
      return;
    }
    switch (event.target.value) {
      case 'active':
        await this.props.undeletePost({ postId: postId });
        break;
      case 'inactive':
        await this.props.deletePost({ postId: postId });
        break;
    }
  }

  /**
  * Paginates the list of resources with the page passed from the pagination
  * widget.  Reloads the list of resources.
  */
  paginate({ selected }) {
    this.setState({
      page: selected
    });
    // Calculate the page selected
    const page = selected + 1;
    // Set the filters
    const options = this.state.queryOptions || {};
    if (this.state.search) {
      options.search = this.state.search;
    }
    if (this.state.sort && this.state.sortBy) {
      options.orderBy = this.state.sortBy + " DESC";
    }
    if (!this.state.sort && this.state.sortBy) {
      options.orderBy = this.state.sortBy + " ASC";
    }
    return this.props.getResources({
      ...options,
      page
    });
  }

  handleSortClick(event, order) {

    this.setState({
      sort: !this.state.sort,
      sortBy: order,
      page: 0
    });
    let options = this.state.sort ? { orderBy: order + " ASC", page: 1 } : { orderBy: order + " DESC", page: 1 };
    if (this.state.filter === 'active') {
      options.deleted = false;
      //options.category = this.state.filter;
    }
    if (this.state.filter === 'inactive') {
      options.deleted = true;
    }
    if (Number.isInteger(this.state.filter)) {
      options.category = this.state.filter;
    }

    this.props.getResources(options);

  }
  /*
  * List the categories that a post belongs to in a readible way.
  *
  * @param {object} post
  * @return {string} comma deliminated string listing the post's categories
  */
  listCategories(post) {
    const categories = post.get('categories').toJS() || [];
    return join(categories.map(category => category.name), ', ');
  }

  /**
   * Handles selecting of a filter by re-fetching items accordingly
   * @param {string} filter
   */
  async handleFilterSelect(filter) {
    this.setState({
      filter
    });

    const options = {
      invalidate: true,
    };

    // Add the search filter
    if (this.state.search) {
      options.search = this.state.search;
    }

    switch (filter) {
      case 'all':
        break;
      case 'active':
        options.deleted = false;
        break;
      case 'inactive':
        options.deleted = true;
        break;
      default: // Category has been selected
        options.category = filter;
        break;
    }

    // Save the query options for further filtering (i.e filtering by worker after filtering by state)
    this.setState({
      queryOptions: options,
      ready: false
    });

    await this.props.getResources(options);

  }

  /**
  * Link to the post currently stored at the given index in the list of posts
  * loaded in the properties.
  *
  * @param {number} index
  */
  handleUpdatePostClick(index) {
    if (window.innerWidth > 959) {
      let pId = this.props.resources.get('results').toJS()[index].id;
      this.props.history.push(`/post/resource?updating=${pId}`);
    }
    else {
      this.props.history.push(`/post/resource?updating=${index}`);
    }
  }

  handleFeaturedClick(resource, event) {
    event.cancelBubble = true;
    event.stopPropagation();
    if (resource.get('tags').size === 0) {
      this.setState({
        featuredPostId: resource.get('id')
      });
      this.featurePost(resource.get('id'));
    }
    else {
      window.alert("You cannot feature a post if it has tags")
    }
  }

  /**
   * Submits a request to set a post as featured
   */
  async featurePost(postId) {
    // Make sure the form is valid before proceeding
    await this.props.updatePost({
      featured: true,
      postId
    });
  }

  /**
  * Returns the header list that should be passed to the table component.
  */
  getHeader() {
    return List([
      // Render the header column to create a new parent
      <span key={1} onClick={() => this.handleSortClick(this, "title")} >Title
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretUp : faCaretDown}
          style={{ fontSize: 16, marginRight: 5 }} />
      </span>,

      <span key={2}>Categories</span>,

      <span key={3} onClick={() => this.handleSortClick(this, "publishDate")} >Publish Date
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretUp : faCaretDown}
          style={{ fontSize: 16, marginRight: 5 }} />
      </span>,

      <span key={4}>Publisher</span>,

      <span key={5} onClick={() => this.handleSortClick(this, "featured")} >Featured
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretUp : faCaretDown}
          style={{ fontSize: 16, marginRight: 5 }} /></span>,
      (this.props.profile.get("type") === 'SA' ? <span key={6} onClick={() => this.handleSortClick(this, "deletedAt")} >State
        <FontAwesomeIcon
          icon={this.state.sort ? faCaretUp : faCaretDown}
          style={{ fontSize: 16, marginRight: 5 }} />
      </span> : null)

    ]);
  }

  /**
  * Gets the body of the table by parsing the currently loaded resources and
  * mapping them to a list of column contents to pass through.
  */
  getBody() {
    return this.props.resources.get('results').map(resource => [
      // Render the name of the resource
      <span key={1}>{resource.get('title')}</span>,
      <span className="nowrap" key={2}>{this.listCategories(resource)}</span>,
      <span key={3}>
        {resource.get('publishDate') ? moment(resource.get('publishDate')).format('YYYY-MM-DD') : moment(resource.get('createdAt')).format('YYYY-MM-DD')}
      </span>,
      <span key={4}>{resource.get('publisher') && resource.get('publisher').get('emailAddress')}</span>,
      <div key={5} className="text-center">
        <FontAwesomeIcon
          onClick={(event) => this.handleFeaturedClick(resource, event)}
          icon={faStar}
          color={resource.get('id') === this.state.featuredPostId ? "gold" : "grey"}
          style={{ fontSize: 16, marginRight: 5 }}
        />
      </div>,
      (this.props.profile.get("type") === 'SA' ?
        <div key={6}>
          <YAInput value={resource.get('deletedAt') ? 'inactive' : 'active'} onClick={(event) => event.stopPropagation()} type="select" onChange={(event) => this.handleItemStateChange(resource.get('id'), event)}>
            <option value='active'>Active</option>
            <option value='inactive'>Inactive</option>
          </YAInput>
        </div> : null)

    ]);
  }

  /**
  * Refetches items based on the search input after more than 3 characters have been entered
  */
  handleSearch(event = { target: {} }) {
    this.setState({ search: event.target.value });

    const options = this.state.queryOptions || {
      invalidate: true
    };

    // Search term has been cleared, refetch
    if (!event.target.value) {
      this.props.getResources(options);
      return;
    }

    if (event.target.value.length >= 3) {
      const searchOptions = { ...options, search: event.target.value };
      this.props.getResources(searchOptions);
    }
  }

  /**
  * Gets the keys that should be passed to each row so that the renderer can index
  * the rows for better performance.
  */
  getKeys() {
    return this.props.resources
      .get('results')
      .map(resource => resource.get('id'));
  }

  renderSearch() {
    const searchBar = (
      <div className="search">
        <input
          type="text"
          onChange={this.handleSearch.bind(this)}
          className="form-control"
          placeholder="Search"
        />

        <FontAwesomeIcon
          icon={faSearch}
          fixedWidth
          className="form-control-feedback"
        />
      </div>
    );

    let search;
    window.innerWidth > 959 ? (
      search = searchBar
    ) : (
      search = (
        <div className="mobile-search-filter">
          {searchBar}
          <button onClick={() => { this.setState({ clickFilter: !this.state.clickFilter }); }}>
            Filter
            <FontAwesomeIcon
              icon={this.state.clickFilter ? faCaretDown : faCaretUp}
              style={{ fontSize: 16, marginRight: 5 }}
            />
          </button>
        </div>
      )
    );
    return search;
  }

  render() {
    // Get the required items to pass to the table component
    const header = this.getHeader();
    const body = this.getBody();
    const keys = this.getKeys();
    const search = this.renderSearch();

    let addNewBtn;
    window.innerWidth > 959 ?
      addNewBtn = (
        <YAButton onClick={() => this.handleAddResourceClick()} type="primary">
          Add Resource Post +
        </YAButton>
      ) : (
        addNewBtn = (
          <button
            className="mobile-add-btn"
            onClick={() => this.handleAddResourceClick()}
          >
            <img src={`${window.__configuration.s3}/icons/plus.svg`} />
          </button>
        )
      );

    let resources;
    window.innerWidth > 959 ?
      resources = (
        <YATable
          ref="table"
          body={body}
          header={header}
          keys={keys}
          initialPage={this.props.resources.getIn(['meta', 'page'])}
          onPaginationClick={this.paginate.bind(this)}
          forcePage={this.state.page}
          onRowClick={this.handleUpdatePostClick.bind(this)}
          pageCount={this.props.resources.getIn(['meta', 'pageCount'])}
          showPagination={
            this.props.resources.getIn(['meta', 'pageCount']) > 1
          }
        />
      ) : (
        resources = (
          <div className="resources-mobile">
            {this.props.resources.get('results').map((post) => [
              // Render the name of the resource
              // eslint-disable-next-line react/jsx-key
              <div className="resources-mobile-header" onClick={this.handleUpdatePostClick.bind(this, post.get('id'))}>
                <span>{post.get('title')}</span>

                <FontAwesomeIcon
                  onClick={event => this.handleFeaturedClick(post, event)}
                  icon={faStar}
                  color={post.get('id') === this.state.featuredPostId ? 'gold' : 'grey'}
                  style={{ fontSize: 16, marginRight: 5 }}
                />
              </div>,
              // eslint-disable-next-line react/jsx-key
              <div className="resources-mobile-footer">
                <span><label>Publisher:</label> {post.get('publisher') && post.get('publisher').get('emailAddress')}</span>
                <YAInput
                  value={post.get('deletedAt') ? 'inactive' : 'active'}
                  onClick={event => event.stopPropagation()}
                  type="select"
                  onChange={event => this.handleItemStateChange(post.get('id'), event)}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </YAInput>
              </div>,
            ])}
          </div>
        )
      )

    return (
      <div className="main resources">
        <aside>
          {/* Render Add New button */}
          {addNewBtn}

          {search}

          {/* Render filters */}
          <ul className={this.state.clickFilter ? "filters" : "filters hidden-mobile"}>
            <li onClick={this.handleFilterSelect.bind(this, 'all')} className={this.state.filter === 'all' ? 'active' : ''}> All </li>
            <li onClick={this.handleFilterSelect.bind(this, 'active')} className={this.state.filter === 'active' ? 'active' : ''}> Active </li>
            <li onClick={this.handleFilterSelect.bind(this, 'inactive')} className={this.state.filter === 'inactive' ? 'active' : ''}> Inactive </li>
            {
              this.props.categories ?
                this.props.categories.get('results').map((category, index) => (
                  <li onClick={this.handleFilterSelect.bind(this, category.get('id'))} className={this.state.filter === category.get('id') ? 'active' : ''} key={index} > {category.get('name')} </li>
                )) : null
            }
          </ul>
        </aside>

        <section className="column">

          <YALoader
            alternate="Loading..."
            image={`${window.__configuration.s3}/icons/loader.gif`}
            show={
              this.props.resourcesPending
            }
          />
          {/* Render the table for displaying the list of resources */}
          {resources}

        </section>
      </div>
    );
  }
}

export default connect(
  YAResources.mapStateToProps,
  YAResources.mapDispatchToProps
)(withRouter(Radium(YAResources)));
