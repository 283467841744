// Load dependencies
import { call, fork, take, all } from 'redux-saga/effects';
import * as actions from './../actions/log';

/**
* Gets a list of logs from the server, paginated by the page that is passed
* through the action.  Sets the logs that are found to the state with meta
* information about how to access more logs.
*/
function* getLogs() {
  while (true) {

    // Get the request parameters from the caller
    const parameters = yield take(actions.GET_LOGS);

    try {

      // Send a request to get the logs from the server
      yield call(actions.getLogs, parameters);

      // Call the success handler if one is provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {

      // Call the failure handler if one is provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
* Gets a list of post access logs from the server, paginated by the page that is passed
* through the action.  Sets the logs that are found to the state with meta
* information about how to access more logs.
*/
function* getAccessedPosts() {
  while (true) {

    // Get the request parameters from the caller
    const parameters = yield take(actions.GET_ACCESSED_POSTS);

    try {

      // Send a request to get the logs from the server
      yield call(actions.getAccessedPosts, parameters);

      // Call the success handler if one is provided
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {

      // Call the failure handler if one is provided
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
* Start all of the sagas in this module.
*/
export default function* root() {
  yield all([
    fork(getLogs),
    fork(getAccessedPosts)
  ]);
}
