// Load dependencies
import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IS_LOGGED_IN, LOGOUT, RESET_PASSWORD } from './../../actions/authenticator';

// Load components
import YAAlert from './../../components/form/alert';
import YAInput from './../../components/form/input';
import queryString from 'query-string';

class YAResetPassword extends Component {

  // Properties that are passed to the reset password page
  static propTypes = {
    location: PropTypes.object.isRequired,
    resetPassword: PropTypes.func.isRequired,
    resetPasswordError: PropTypes.string,
    history: PropTypes.object.isRequired
  };

  /**
  * Checks if the user is logged in before displaying the contents of the reset password
  * page.  If the user is logged in then we send them to the main page.  Otherwise
  * we let the user continue.
  *
  * @param {object} store - Redux store passed from the router
  */
  static onEnter(store) {
    return (nextState, replaceState, callback) => {
      store.dispatch({
        type: IS_LOGGED_IN,
        onLoggedIn: () => {
          store.dispatch({ type: LOGOUT });
          callback();
        },
        onNotLoggedIn: callback
      });
    };
  }

  /**
  * Determines which items the container needs from the store for rendering this
  * page.  These items are injected into the properties of the page.
  */
  static mapStateToProps(state) {
    return {
      resetPasswordError: state.authenticator.getIn(['resetPassword', 'error'])
    };
  }

  /**
  * Determines which actions this page requires and those action dispatchers will
  * be injected into the properties of the page.
  */
  static mapDispatchToProps(dispatch) {
    return {
      resetPassword: options => dispatch({ type: RESET_PASSWORD, ...options })
    };
  }

  // Initial state for the reset password page
  state = {
    password: '',
    passwordConfirmed: '',
    token: null
  };

  /**
   * Handle the logic that should run before the component is completely mounted.
   */
  componentWillMount() {
    this.setState({
      token: queryString.parse(this.props.location.search).token
    });
  }

  /**
   * Returns whether or not the reset password form is valid.  The reset password
   * form should have both passwords not empty and equal to each other.
   */
  isFormValid() {
    return this.state.password === this.state.passwordConfirmed && this.state.password.length;
  }

  /**
  * Sends a request to the server to reset the password of the user with the
  * specified token if and only if the password has been filled out by the user
  * to avoid consuming the token.
  */
  handleSendResetPasswordRequest(event) {

    // Prevent the page from unloading from the form submit
    event.preventDefault();

    // Only make the request to the server if the password has a value
    if (this.state.password.length) {
      this.props.resetPassword({
        password: this.state.password,
        token: this.state.token,
        onSuccess: () => {
          // ReactGA.event({
          //   category: 'Authentication',
          //   action: 'reset password',
          //   label: 'From Reset Password'
          // });

          this.props.history.push('/login');
        }
      });
    }
  }

  /**
  * Given an event, set the state of the specified property to the value provided
  * from the event.
  *
  * @param {string} property
  * @param {object} event
  */
  onChange(property, event) {
    this.setState({
      [property]: event.target.value
    });
  }

  /**
   * Render the component based on the properties and state provided.
   *
   * @return {object} Node that should be rendered
   */
  render() {
    return (
      <main
        className="splash"
      // style={{
      //   backgroundImage: `url(${window.__configuration.s3}/splash/splash.jpg)`
      // }}
      >
        <form
          className="stripped"
          onSubmit={this.handleSendResetPasswordRequest.bind(this)}
        >

          {/* Render the application logo */}
          <Link to="/login">
            <img
              src={`${window.__configuration.s3}/logos/logo.png`}
              className="login-logo"
              alt="Plan B - You are here."
            />
          </Link>

          <fieldset>

            {
              // Render an alert if the request has failed for any reason
              this.props.resetPasswordError !== null ?
                <YAAlert type="E">
                  <span>{this.props.resetPasswordError}</span>
                </YAAlert> :
                null
            }

            {/* Collect the new password from the user */}
            <YAInput
              type="password"
              className="stripped-input"
              noBaseStyles
              onChange={this.onChange.bind(this, 'password')}
              placeholder="Your New Password"
              value={this.state.password}
            />

            {/* Collect the confirmed password from the user */}
            <YAInput
              type="password"
              className="stripped-input"
              noBaseStyles
              onChange={this.onChange.bind(this, 'passwordConfirmed')}
              placeholder="Confirm Your Password"
              value={this.state.passwordConfirmed}
            />

            {/*  Button which submits the reset password request to the server */}
            <YAInput
              type="submit"
              style={{ lineHeight: 1 }}
              className={classNames('btn btn-default btn-block', { disabled: !this.isFormValid() })}
              // noBaseStyles
              value="Submit"
            />

          </fieldset>
        </form>

        {/* Render footer logo */}
        <footer className="brand-bar login">
          <img
            src={`${window.__configuration.s3}/logos/ymca-logo.png`}
            className="footer-logo"
            alt="YMCA of Hamilton | Burlington | Brantford"
          />
        </footer>

      </main>
    );
  }
}

export default connect(YAResetPassword.mapStateToProps, YAResetPassword.mapDispatchToProps)(withRouter(YAResetPassword));