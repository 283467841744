// Select the current list of logs with meta information
export function getLogs(state) {
  return state.log.get('logs');
}

export function getLogsPending(state){
  return state.log.getIn(['getLogs','pending']);
}

// Select the current list of accessed post logs with meta information
export function getAccessedPosts(state) {
  return state.log.get('accessedPosts');
}

export function getAccessedPostsPending(state){
  return state.log.getIn(['getAccessedPosts','pending']);
}