/**
 * Retrieves the list of languages from the state
 *
 * @export
 * @param {Object} state - The current application state.
 * @returns {Object} The languages that are currently loaded.
 */
export function getLanguages(state) {
  return state.language
    .get('languages');
}

/**
 * Retrieves whether or not the request to get the languages has failed
 * on the last attempt.
 *
 * @export
 * @param {Object} state - The current application state.
 * @returns {boolean} Whether or not the request has failed.
 */
export function hasGetLanguagesFailed(state) {
  return state.translation.getIn(['getLanguages', 'error']) !== null;
}

/**
 * Returns whether or not the application is currently loading languages.
 *
 * @export
 * @param {Object} state - The current application state.
 * @returns {boolean} Whether or not the request is pending.
 */
export function isGetLanguagesPending(state) {
  return state.translation.getIn(['getLanguages', 'pending']);
}