// Load dependencies
import request from 'axios';
import { call, put } from 'redux-saga/effects';

// Export the actions that are available for the application
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';

/**
 * Sends a request to the server to retrieve the site settings.
 */
export function* getSettings() {
  try {
    // Make the request to the server for getting settings
    yield put({ type: GET_SETTINGS_REQUEST });
    const { data } = yield call(request.get, `${window.__configuration.api}/settings`);

    // Getting information was successful, add it to the store
    yield put({ type: GET_SETTINGS_SUCCESS, payload: data.results });
    return data.results;
  } catch ({ response: { data } }) {
    // Getting the regions has failed, set the error as to why
    yield put({ type: GET_SETTINGS_FAILURE, error: data.message });
    throw data.message;
  }
}
