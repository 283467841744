// Load dependencies
import { call, fork, take, all } from 'redux-saga/effects';
import * as actions from './../actions/conversation';

/**
* Gets conversations from the server, paginated by the page that is passed
* through the action. A conversation is a list of messages between a worker and a youth.
* Sets the conversations that are found to the state with meta
* information about how to access more conversations.
*/
function* getConversations() {
  while (true) {
    // Get the request parameters from the caller
    const {
      userId,
      invalidate,
      all,
      limit,
      page,
      onFailure,
      onSuccess
    } = yield take(actions.GET_CONVERSATIONS);

    try {
      // Send a request to get the conversations from the server
      yield call(actions.getConversations, userId, {
        invalidate,
        all,
        limit,
        page
      });

      // Call the success handler if one is provided
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      // Call the failure handler if one is provided
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
  }
}

/**
* Get messages in a conversation
*/
function* getMessages() {
  while (true) {
    // Get the request parameters from the caller
    const {
      conversationId,
      invalidate,
      startDate,
      endDate,
      includeTranslations,
      all,
      limit,
      page,
      onFailure,
      onSuccess
    } = yield take(actions.GET_MESSAGES);

    try {
      // Send a request to get the conversations from the server
      yield call(actions.getMessages, conversationId, {
        includeTranslations,
        startDate,
        endDate,
        invalidate,
        all,
        limit,
        page
      });

      // Call the success handler if one is provided
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      // Call the failure handler if one is provided
      if (typeof onFailure === 'function') {
        onFailure();
      }
    }
  }
}

/**
* Start all of the sagas in this module.
*/
export default function* root() {
  yield all([fork(getConversations), fork(getMessages)]);
}
