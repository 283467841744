// Load dependencies
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';
import Radium from 'radium';
import Color from 'color';
import { keys } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';

// Load styles
import { defaults, font, modal } from './../styles';

const MESSAGE_TYPES = {
  N: 'Normal',
  I: 'Important',
  L: 'Link',
  T: 'Translation'
};

/**
* Renders a message box directed to the user to or from a parent.  Renders the
* message contents and when the message was sent.
*/
class MessageBubble extends Component {
  // Properties for the message bubble component
  static propTypes = {
    // message, isSender
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(keys(MESSAGE_TYPES)).isRequired,
    timestamp: PropTypes.string,
    isSender: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired
  };

  // Default property values for the message bubble component
  static defaultProps = {
    message: '',
    type: 'N',
    isSender: true,
    name: ''
  };

  // Styles for the message bubble component
  static styles = {
    base: {
      boxSizing: 'border-box',
      position: 'relative',
      display: 'block',
      borderTop: `1px solid ${defaults.borderColor}`,
      borderRight: `1px solid ${defaults.borderColor}`,
      borderBottom: `5px solid ${defaults.borderColor}`,
      borderLeft: `1px solid ${defaults.borderColor}`,
      fontFamily: font.family.sansSerif,
      fontSize: font.size.small,
      color: defaults.white,
      marginBottom: `${defaults.gutter / 2}px`
    },
    container: {
      display: 'flex',
      width: '100%'
    },
    senderContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    recipientContainer: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end'
    },
    body: {
      display: 'block',
      padding: `12px 15px`,
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
      maxWidth: '90%'
    },
    senderBubble: {
      float: 'left',
      borderTopRightRadius: 0,
      borderTopLeftRadius: 15,
      color: 'white'
    },
    recipientBubble: {
      float: 'right',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 15,
      color: 'white'
    },
    senderPointer: {
      top: 0,
      left: 0,
      display: 'inline-block',
      width: 10,
      height: 20,
      borderWidth: '0px 0px 20px 10px',
      borderStyle: 'solid',
      borderColor: '#FAFAFA',
      borderLeftColor: 'transparent',
      borderRight: 0,
      borderTop: 0
    },
    recipientPointer: {
      top: 0,
      right: 0,
      display: 'inline-block',
      width: 10,
      height: 20,
      borderWidth: '0px 10px 20px 0px',
      borderStyle: 'solid',
      borderColor: '#FAFAFA',
      borderRightColor: 'transparent',
      borderLeft: 0,
      borderTop: 0
    },
    timestamp: {
      fontSize: 10,
      color: '#888',
      fontWeight: 500,
      marginRight: 10,
      marginLeft: 10,
      marginTop: 2
    },
    senderTimestamp: {
      float: 'right'
    },
    recipientTimestamp: {
      float: 'left'
    },
    postHeading: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 14,
      marginBottom: 5
    },
    postSubheading: {
      color: '#CCC',
      fontSize: 12,
      marginBottom: 5
    },
    postTextContainer: {
      paddingRight: 5
    },
    postContainer: {
      flexDirection: 'row',
      alignSelf: 'stretch',
      justifyContent: 'space-between'
    }
  };

  /**
  * Gets the colour that pertains to the message bubble type
  *
  * @return {string} the colour for the type
  */
  getTypeColour() {
    const type = this.props.type;
    switch (type) {
      case 'N':
        return '#8C5674';
      case 'I':
        return '#EE3640';
      case 'L':
        return '#333333';
      case 'T':
        return 'purple';
      default:
        return 'gray';
    }
  }

  renderLink() {
    return (
      <div style={MessageBubble.styles.postTextContainer}>
        <div style={MessageBubble.styles.postTextContainer}>
          <p style={MessageBubble.styles.postHeading}>{this.props.linkTitle}</p>
          <TextTruncate
            line={3}
            truncateText="…"
            style={MessageBubble.styles.postSubheading}
            text={this.props.linkDescription}
          />
        </div>

        <FontAwesomeIcon
          onClick={this.props.onPress}
          icon={faArrowCircleRight}
          color={'white'}
          style={{ fontSize: 16, marginRight: 5, cursor: 'pointer' }}
        />
      </div>
    );
  }

  renderMessage(type) {
    if (type == 'T') {
      return (
        <div>
          {this.props.message}
          <br />
          <em style={{ color: 'white' }}>
            Translated to {this.props.language}
          </em>
        </div>
      );
    } else {
      if (this.props.document) {
        return <a style={{ color: 'white' }} href={this.props.document} target="_blank">Click here to view sent document</a>;
      }
      if (this.props.picture) {
        return <img src={this.props.picture} />;
      }
      return <div>{this.props.message}</div>;
    }
  }

  render() {
    const backgroundColour = this.getTypeColour();
    // Lighten received message bubbles
    const typeStyle = {
      backgroundColor: this.props.isSender
        ? backgroundColour
        : Color(backgroundColour)
          .lighten(0.4)
          .hex()
    };
    return (
      <div>
        <span
          style={[
            MessageBubble.styles.timestamp,
            this.props.isSender
              ? MessageBubble.styles.senderTimestamp
              : MessageBubble.styles.recipientTimestamp
          ]}
        >
          {this.props.isSender
            ? this.props.currentUser
            : this.props.chatParticipant}
        </span>

        <div
          style={[
            MessageBubble.styles.container,
            this.props.isSender
              ? MessageBubble.styles.senderContainer
              : MessageBubble.styles.recipientContainer
          ]}
        >
          <div
            style={[
              MessageBubble.styles.body,
              this.props.isSender
                ? MessageBubble.styles.senderBubble
                : MessageBubble.styles.recipientBubble,
              typeStyle
            ]}
          >
            {/* Render the contents of the message */}
            {this.props.type === 'L'
              ? this.renderLink()
              : this.renderMessage(this.props.type)}
          </div>

          <div
            style={[
              this.props.isSender
                ? MessageBubble.styles.senderPointer
                : MessageBubble.styles.recipientPointer,
              typeStyle
            ]}
          />
        </div>

        <span
          style={[
            MessageBubble.styles.timestamp,
            this.props.isSender
              ? MessageBubble.styles.senderTimestamp
              : MessageBubble.styles.recipientTimestamp
          ]}
        >
          {this.props.timestamp}
        </span>
      </div>
    );
  }
}

// Export component, connected to Radium
export default Radium(MessageBubble);
