// Load dependencies
import { Map, List } from 'immutable';

/**
* Returns data formatted for CSV. TODO: only accepts 1D fields i.e wont getIn('language','name')
*
* @param {List()} data - The data as provided by the store
* @param {[string]} fields - The fields to render as columns
*
* @return {[objects]} The array of csv formatted data
*/
export function formatForCSV(data = List(), fields = []) {
   const formattedData = data.map( (entry = Map()) => {
     const row = {};
      fields.forEach(field => {
        row[field] = entry.get(field);
      });
      return row;
   });
   return formattedData.toJS();
}
