// Load dependencies
import { call, fork, put, select, take, all } from 'redux-saga/effects';

// Load actions
import * as actions from './../actions/authenticator';

// Load selectors
import {
  isLoggedIn as _isLoggedIn,
  getProfile as _getProfile
} from './../selectors/authenticator';

/**
* Saga for user login flow into the application.  Watches for a login request to
* come in and makes the request to the server to log the user into the application.
* On success, also loads the user's profile from the server.
*/
function* login() {
  while (true) {
    const {
      emailAddress,
      password,
      onLoginSuccess,
      onLoginFailure
    } = yield take(actions.LOGIN);

    try {
      // Make the request to login only if the user is not currently logged in
      const isLoggedIn = yield select(_isLoggedIn);
      if (!isLoggedIn) {
        yield call(actions.login, emailAddress, password);
        yield put({ type: actions.GET_PROFILE });

        // Call the success callback if one is provided
        if (onLoginSuccess) {
          onLoginSuccess();
        }
      }
    } catch (error) {
      // Call the failure callback if one is provided
      if (onLoginFailure) {
        onLoginFailure(error);
      }
    }
  }
}

/**
* Submits a request to the store to clear out the user's authentication token
* and other session information.
*/
function* logout() {
  while (true) {
    yield take(actions.LOGOUT);
    yield call(actions.logout);
  }
}

/**
* Checks whether or not the user is logged in.  If the user is logged in then we
* grab the profile of the user from the server if we don't already have it
*/
function* isLoggedIn() {
  while (true) {
    try {
      const { onFailure, onLoggedIn, onNotLoggedIn } = yield take(
        actions.IS_LOGGED_IN
      );
      const loggedIn = yield call(actions.isLoggedIn);

      // If the user is logged in, grab the profile if not yet retrieved
      if (loggedIn) {
        try {
          yield call(actions.getProfile);
        } catch (error) {
          // Logout and redirect to login if request failed. Token may have expired
          yield put({ type: actions.LOGOUT });
          if (typeof onFailure == 'function') {
            onFailure();
          }
        }
        // Call the `onLoggedIn` callback if provided
        if (typeof onLoggedIn === 'function') {
          onLoggedIn();
        }
      } else {
        // Call the `onNotLoggedIn` callback if provided
        if (typeof onNotLoggedIn === 'function') {
          onNotLoggedIn();
        }
      }
    } catch (error) {
      throw error;
    }
  }
}

/**
* Saga for retrieving a user's profile from the server.  Listens for a request to
* retrieve the profile and tells the action creator to go to the server to get
* the profile.
*/
function* getProfile() {
  while (true) {
    try {
      yield take(actions.GET_PROFILE);

      // Grab the profile only if no profile is currently set
      const profile = yield select(_getProfile);
      if (profile === null) {
        yield call(actions.getProfile);
      }
    } catch (error) { }
  }
}

/**
* Saga for sending a request to the server to send an email to the staff member
* in order for that staff member to reset their password.
*/
function* forgotPassword() {
  while (true) {
    // Get the parameters for the request from the action
    const parameters = yield take(actions.FORGOT_PASSWORD);

    try {
      // Send the request to the server to send a forgot password email to the staff member
      yield call(actions.forgotPassword, parameters.emailAddress);

      // Request was successful, call the success handler if there is one
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      // Request was not successful, call the failure handler if there is one
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
* Saga for sending a request to the server to reset the password of the staff member
* after sending a request for a forgotten password.
*/
function* resetPassword() {
  while (true) {
    // Get the parameters for the request from the action
    const parameters = yield take(actions.RESET_PASSWORD);

    try {
      // Send the request to the server to reset the password of the staff member
      yield call(actions.resetPassword, parameters.token, parameters.password);

      // Request was successful, call the success handler if there is one
      if (typeof parameters.onSuccess === 'function') {
        parameters.onSuccess();
      }
    } catch (error) {
      // Request was not successful, call the failure handler if there is one
      if (typeof parameters.onFailure === 'function') {
        parameters.onFailure();
      }
    }
  }
}

/**
* Start all of the sagas in this module.
*/
export default function* root() {
  yield all([
    fork(login),
    fork(logout),
    fork(isLoggedIn),
    fork(getProfile),
    fork(forgotPassword),
    fork(resetPassword)
  ]);
}
