/* global __CONTENT__ */

// Load dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, Map } from 'immutable';
import moment from 'moment';
import Radium from 'radium';

import { defaults } from './../../components/styles';

// Load custom components
import YAButton from './../../components/form/button';
import YAInput from './../../components/form/input';
import YALoader from './../../components/loader/loader';
import YAAlert from './../../components/form/alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import {
  GET_ACTIVITIES,
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY,
} from './../../actions/activity';

import {
  CREATE_TAG,
  DELETE_TAG,
} from './../../actions/activity';


import {
  GET_USERS,
  getUserType
} from './../../actions/user';

// Load selectors
import { getActivities, getCreateActivityError, getUpdateActivityError, getActivity } from './../../selectors/activity';
import {
  getUsers,
  getUsersPending,
  getCreateUserError,
  getUser
} from './../../selectors/user';
import {
  getCreateTagError
} from './../../selectors/activity';
// Load utilities
import { setState, setStateDeep } from './../../util/state';

import { getProfile } from './../../selectors/authenticator';
import queryString from 'query-string';
/**
* Renders the main activities table for staff members to view information
* about the activities.
*/
class YAActivity extends Component {
  static propTypes = {
    createTag: PropTypes.func.isRequired,
    getCreateTagError: PropTypes.string,
    history: PropTypes.object.isRequired,
    getCreateActivityError: PropTypes.string,
    getUpdateActivityError: PropTypes.string,
    getUsers: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    getActivities: PropTypes.func.isRequired,
    activities: PropTypes.instanceOf(Map).isRequired,
    activity: PropTypes.instanceOf(Map).isRequired,
    createActivity: PropTypes.func.isRequired,
    updateActivity: PropTypes.func.isRequired,
    deleteActivity: PropTypes.func.isRequired,
    profile: PropTypes.instanceOf(Map).isRequired,
    users: PropTypes.instanceOf(Map).isRequired,
  };

  static styles = {
    columnBody: {
      cursor: 'pointer',
      padding: 25,
      borderRadius: 8,
      border: '2px solid 	#F0F0F0',
      borderWidth: 1,
      height: 160,
      overflowY: 'auto',
      flex: 1,
      paddingTop: 10,
      paddingBottom: 10
    },
    cancelButton: {
      color: defaults.white
    },
    hidden: {
      display: 'none'
    },
    editor: {
      backgroundColor: defaults.white,
      height: '250px'
    },
    row: {
      marginTop: `${defaults.gutter / 2}px`
    },
  };
  /**
  * Determines which items the container needs from the store for rendering this
  * page.  These items are injected into the properties of the page.
  */
  static mapStateToProps(state) {
    return {
      getCreateTagError: getCreateTagError(state),
      activities: getActivities(state),
      activity: getActivity(state),
      getCreateActivityError: getCreateActivityError(state),
      getUpdateActivityError: getUpdateActivityError(state),
      profile: getProfile(state),
      users: getUsers(state),
    };
  }

  /**
  * Determines which actions this page requires and those action dispatchers will
  * be injected into the properties of the page.
  */
  static mapDispatchToProps(dispatch) {
    return {
      deleteActivity: options =>
        dispatch({ type: DELETE_ACTIVITY, ...options }),
      createActivity: options => dispatch({ type: CREATE_ACTIVITY, ...options }),
      updateActivity: options => dispatch({ type: UPDATE_ACTIVITY, ...options }),
      getActivities: options => dispatch({ type: GET_ACTIVITIES, ...options }),
      getUsers: options => dispatch({ type: GET_USERS, ...options }),
      createTag: options => dispatch({ type: CREATE_TAG, ...options }),
    };
  }

  // Initial state of theresourcelistings page
  state = {
    name: '',
    type: 'all',
    description: '',
    deleted: false,
    error: null,
    ready: false,
    search: '',
    users: '',
    activityStatus: null,
    tagsToAdd: [],
    tagsToDelete: []
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activities !== this.props.activities) {
      this.storeInState();
    }

    if (prevProps.users !== this.props.users) {
      this.setState({
        users: this.props.users.get('results').toJS()
      });
    }
  }

  /**
  * Loads in the initial list of activity. Redirects to dashboard if request fails.
  */
  componentWillMount() {
    this.props.getActivities(
      {
        id: queryString.parse(this.props.location.search).updating,
      });

    this.props.getUsers({
      userType: this.state.type,
    });

    this.setState({
      ready: true
    });

  }

  /**
  * Store the featured post in state to render featured start indicator for this post
  * TODO: scroll to top see weemarkable feed
  */
  componentWillReceiveProps(props) {

  }

  cancel() {
    this.props.history.push(`/tags`);
  }

  deleteActivity() {
    const r = window.confirm("Are you sure you want to update this tag?");
    if (r == true) {
      this.props.deleteActivity({ id: queryString.parse(this.props.location.search).updating });
      this.cancel();
    }
  }

  createActivity() {
    let error = null;
    if (this.state.name.length < 4) {
      error =
        'Tag name must be at least 5 chatacters';
    }
    this.setState({
      error
    });
    if (!error) {
      this.setState({
        ready: false
      });
      let params = { name: this.state.name };
      this.state.description ? params.description = this.state.description : null;
      this.props.createActivity({
        ...params, onFailure: () => {
          this.setState({
            error: this.props.getCreateActivityError,
            ready: true

          });
        },
        onSuccess: () => {
          const id = this.props.activity.get('id')
          this.props.history.push(`/tag?updating=${id}`);
          window.location.reload(false);
        }
      });
    }
  }

  storeInState() {
    // TODO: use getActivity instead of getActivities
    if (queryString.parse(this.props.location.search).updating) {
      this.props.activities.get('results').map((activity) => (
        this.setState({
          name: activity.get('name'),
          deleted: activity.get('deletedAt') === null ? false : true,
          description: activity.get('description')
        })
      ));
    }
  }

  updateActivity() {
    let params = { id: queryString.parse(this.props.location.search).updating };
    this.state.name ? params.name = this.state.name : null;
    this.state.description ? params.description = this.state.description : null;
    params.tagsToDelete = this.state.tagsToDelete;
    this.props.updateActivity(
      {
        ...params,
        onFailure: () => {
          this.setState({
            error: this.props.getUpdateActivityError
          });
        },
        onSuccess: () => {
          this.cancel.call(this);
        }
      });
  }

  handleSubmit() {
    if (queryString.parse(this.props.location.search).updating) {
      this.props.createTag({
        tags: this.state.tagsToAdd,
        activityId: queryString.parse(this.props.location.search).updating
      });
      // TODO: integrate create  tag into update activity
      return this.updateActivity();
    }
    return this.createActivity();
  }
  /**
  * Sets the given property to the state with the value that is passed from the
  * input box change event.
  *
  * @param {string} property
  * @param {object} event
  */
  handleChange(property, event) {

    this.setState({
      [property]:
        typeof event === 'string'
          ? event
          : moment.isDate(event) ? moment(event) : (event.target && event.target.value)
    });
  }

  handleSearch(property, event) {
    this.handleChange(property, event)
    let options = {
      name: this.state.search,
      userType: this.state.type,
      onSuccess: () => {
        this.setState({
          users: this.props.users.get('results').toJS()
        })
      }
    }
    if (this.state.activityStatus) {
      options.activityId = parseInt(queryString.parse(this.props.location.search).updating);
      options.activityStatus = true;
    }
    if (this.state.activityStatus === false) {
      options.activityId = parseInt(queryString.parse(this.props.location.search).updating);
      options.activityStatus = false;
    }

    this.props.getUsers(options)
  }

  handleSelectUserType(event) {
    if (!event.target) {
      return;
    }
    const type = event.target.value === "inactive" ||
      event.target.value === "active" ? 'all' : event.target.value;

    this.setState({
      type: type,
      activityStatus: null
    });

    let options = {
      userType: type,
      onSuccess: () => {
        this.setState({
          // hide deleted users, SA shows all
          users: this.props.users.get('results').toJS().filter(x => x.deletedAt == null),
        })
      }
    }
    if (this.state.search) {
      options.name = this.state.search
    }
    if (event.target.value === "active") {
      this.setState({
        activityStatus: true
      });
      options.activityId = parseInt(queryString.parse(this.props.location.search).updating);
      options.activityStatus = true;
    }
    if (event.target.value === "inactive") {
      this.setState({
        activityStatus: false
      });
      options.activityId = parseInt(queryString.parse(this.props.location.search).updating);
      options.activityStatus = false;
    }
    this.props.getUsers(options)
  }

  renderList(user) {
    const name = user.firstName ? user.fullName : user.emailAddress;
    let className = "status-dot outline";
    const tagId = parseInt(queryString.parse(this.props.location.search).updating);
    if (this.state.tagsToAdd.findIndex(x => x === user.id) > -1) {
      className = "status-dot"
    }
    const index = user.tags.findIndex(x => x.activityId === tagId)
    if (index > -1) {
      className = "status-dot"
      const toBeDeletedTag = user.tags[index].id;
      if (this.state.tagsToDelete.findIndex(x => x === toBeDeletedTag) > -1) {
        className = "status-dot outline";
      }
    }
    return (
      <div>
        {
          <p><span className={className} />{name}</p>
        }
      </div>)
  }

  handleSelectUser(user) {
    let tags = user.tags;
    const tagId = parseInt(queryString.parse(this.props.location.search).updating);
    const tagsIndex = tags.findIndex(x => x.activityId === tagId);
    let options = {
      userType: this.state.type
    }
    if (this.state.search) {
      options.name = this.state.search;
    }
    if (tagsIndex === -1) {

      if (this.state.tagsToAdd.indexOf(user.id) === -1) {
        this.setState({
          tagsToAdd: this.state.tagsToAdd.concat(user.id)
        });
      } else {
        this.setState({
          tagsToAdd: this.state.tagsToAdd.filter(x => x !== user.id)
        });
      }


    } else {
      if (this.state.tagsToDelete.indexOf(tags[tagsIndex].id) === -1) {
        this.setState({
          tagsToDelete: this.state.tagsToDelete.concat(tags[tagsIndex].id)
        });
      }
      else {
        this.setState({
          tagsToDelete: this.state.tagsToDelete.filter(x => x !== tags[tagsIndex].id)
        });
      }
    }
  }


  render() {
    let btnName = this.state.deleted ? <span>Save & Activate </span> : <span>Save</span>;
    // Get the required items to pass to the table component
    return (
      <div className="main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5">
              <br />
              <div className="form-group">
                <label>Name</label>

                <YAInput
                  onChange={this.handleChange.bind(
                    this,
                    'name'
                  )}
                  type="text"
                  value={this.state.name}
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Description</label>

                <YAInput
                  onChange={this.handleChange.bind(
                    this,
                    'description'
                  )}
                  type="textarea"
                  value={this.state.description || ''}
                  className="form-control"
                />
              </div>

              {// Show the current error message for creating or updating a post if there is one
                this.state.error ? (
                  <div style={{ marginBottom: `${defaults.gutter / 2}px` }}>
                    <YAAlert type="E">{this.state.error}</YAAlert>
                  </div>
                ) : null
              }
              {queryString.parse(this.props.location.search).updating && !this.state.deleted ? <div className="form-group">

                <label>Apply Tag to Users</label>

                <div className="search">
                  <input
                    style={{
                      width: '78%',
                      borderRadius: 20,
                      borderStyle: 'solid',
                      borderWidth: 1,
                      paddingLeft: 10,
                      paddingTop: 8,
                      paddingBottom: 8,
                      borderColor: '#F0F0F0',
                      marginBottom: 10
                    }}
                    type="text"
                    onChange={
                      this.handleSearch.bind(
                        this,
                        'search'
                      )}
                    placeholder="Search"
                    type="text"
                    value={this.state.search}
                  />

                  <FontAwesomeIcon
                    icon={faSearch}
                    fixedWidth
                    className="form-control-feedback2"
                  />
                  <YAInput type='select'

                    style={{
                      borderRadius: 8,
                      width: '20%',
                      padding: 8,
                      borderColor: '#F0F0F0',
                      marginBottom: 10,
                      marginLeft: 6
                    }}
                    onChange={this.handleSelectUserType.bind(this)}
                  >
                    <option value="all">All</option>
                    {this.props.profile.get('type') === 'SA' && <option value="A">Admins</option>}
                    <option value="W">Workers</option>
                    <option value="Y">Youth</option>
                    <option value="C">Adults</option>
                    <option value="inactive">Inactive</option>
                    <option value="active">Active</option>
                  </YAInput>
                </div>

                <div style={YAActivity.styles.columnBody}>


                  {this.state.users && this.state.users.map((user) => (
                    <div onClick={() => this.handleSelectUser(user)}>
                      {
                        <div>
                          {this.renderList(user)}</div>

                      }
                    </div>
                  ))
                  }

                </div>

              </div> :
                <div className="form-group">
                  <label>Apply Tag to Users</label>
                  <p>Note: Tag must be active in order to apply to users.</p>
                </div>}
              <br />
              <YAButton onClick={this.handleSubmit.bind(this)} type="primary">

                {btnName}
              </YAButton>
              <YAButton onClick={this.cancel.bind(this)} type="cancel">
                Cancel
              </YAButton>
              {queryString.parse(this.props.location.search).updating ?

                <span>
                  {this.state.deleted ? null :
                    <span><YAButton onClick={this.deleteActivity.bind(this)} type="danger">
                      Deactivate
                    </YAButton>
                    </span>
                  }
                  <br /> <br />
                  <p>
                    Note: Deactivating a tag will disable its use, but not delete
                    it. The association remains in place, but no new users or
                    posts can be tagged, and notifications are not enabled. Tags
                    can be reactivated.
                  </p>
                  {this.state.deleted ? <p>To unarchive this tag, press save</p> : null}</span> : null}
              <YALoader
                alternate="Loading..."
                image={`${window.__configuration.s3}/icons/loader.gif`}
                show={!this.state.ready}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(YAActivity.mapStateToProps, YAActivity.mapDispatchToProps)(
  withRouter(Radium(YAActivity))
);
