// Select the authentication token of the current user
export function getToken(state) {
  return state.authenticator.get('authToken');
}

// Select whether or not the user is currently logged in
export function isLoggedIn(state) {
  return state.authenticator.get('loggedIn');
}

// Select the current login error
export function getLoginError(state) {
  return state.authenticator.getIn(['login', 'error']);
}

// Select the profile of the current user
export function getProfile(state) {
  return state.authenticator.get('profile') || null;
}

//Select if the login process is pending
export function getLoggedInPending(state) {
  return state.authenticator.getIn(['login', 'pending']);
}