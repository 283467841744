// Load dependencies
import Immutable, { List, Map } from 'immutable';
import * as actions from './../actions/translation';
import { LOGOUT } from './../actions/authenticator';

// Set the initial state
const initialState = Map({
  createTranslation: Map({
    pending: false,
    itemType: null,
    itemId: null,
    params: Map({
      language: null,
      translations: {},
    }),
    error: null
  }),
  updateTranslation: Map({
    pending: false,
    translationId: null,
    params: Map({
      language: null,
      translations: {},
    }),
    error: null
  }),
});

/**
* Handles the management of data relating to creating and updating translation translations
*
* @param {object} state
* @param {object} action
*
* @return {object} New state after applying the action
*/
export default function translation(state = initialState, action) {
  switch (action.type) {
    // Request to create a translation has come in, store the parameters
    case actions.CREATE_TRANSLATION_REQUEST:
      return state
        .setIn(['createTranslation', 'pending'], true)
        .setIn(['createTranslation', 'itemId'], action.itemId)
        .setIn(['createTranslation', 'itemType'], action.itemType)
        .setIn(['createTranslation', 'params'], Immutable.fromJS(action.params))
        .setIn(['createTranslation', 'error'], null);

    // Request to create a translation has succeeded, set the flags back to normal
    case actions.CREATE_TRANSLATION_SUCCESS:
      return state
        .setIn(['createTranslation', 'pending'], false);

    // Request to create a translation has failed, set the error to the store
    case actions.CREATE_TRANSLATION_FAILURE:
      return state
        .setIn(['createTranslation', 'pending'], false)
        .setIn(['createTranslation', 'itemId'], null)
        .setIn(['createTranslation', 'itemType'], null)
        .setIn(['createTranslation', 'error'], action.error);

    // Request to update a translation has come in, store the parameters
    case actions.UPDATE_TRANSLATION_REQUEST:
      return state
        .setIn(['updateTranslation', 'pending'], true)
        .setIn(['updateTranslation', 'itemId'], action.itemId)
        .setIn(['updateTranslation', 'itemType'], action.itemType)
        .setIn(['updateTranslation', 'params'], Immutable.fromJS(action.params))
        .setIn(['updateTranslation', 'error'], null);

    // Request to update a translation was successful, clear the flags
    case actions.UPDATE_TRANSLATION_SUCCESS:
      return state
        .setIn(['updateTranslation', 'pending'], false)
        .setIn(['updateTranslation', 'translationId'], null)
        .setIn(
          ['updateTranslation', 'params'],
          initialState.getIn(['updateTranslation', 'params'])
        );

    // Request to update a translation has failed, store the error message
    case actions.UPDATE_TRANSLATION_FAILURE:
      return state
        .setIn(['updateTranslation', 'pending'], false)
        .setIn(['updateTranslation', 'translationId'], null)
        .setIn(
          ['updateTranslation', 'params'],
          initialState.getIn(['updateTranslation', 'params'])
        )
        .setIn(['updateTranslation', 'error'], action.error);

    // Reset the state when the user logs out
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
