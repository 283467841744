/* global __DEV__ */

// Load dependencies
import './../scss/app.scss';
import 'react-widgets/scss/styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tagsinput/react-tagsinput.css';
import 'quill/dist/quill.snow.css';
import React from 'react';
import ReactDOM from "react-dom/client";
import setupStore from './store/configure-store';

// Load components
import Root from './containers/root/root';

// Load Bugherd script if not in development
if (!__DEV__ && window.__configuration.bugherd) {
  (function (d, t) {
    var bh = d.createElement(t), s = d.getElementsByTagName(t)[0];
    bh.type = 'text/javascript';
    bh.src = 'https://www.bugherd.com/sidebarv2.js?apikey=puhcxqprbwpdwty9bvtplw';
    s.parentNode.insertBefore(bh, s);
  })(document, 'script');
}

// Load Usersnap if not in development
// if (!__DEV__ && window.__configuration.usersnap) { TODO:
if (window.__configuration.usersnap) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = window.__configuration.usersnap;
  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(script, firstScript);
}

// Show a warning if the user is coming to the non-production website
if (window.__configuration.warning) {
  const confirm = window.confirm(
    'You have navigated to the development version of Weemarkable.  You may not ' +
    'intend to be here.  Would you like to go to the live version of the site?'
  );

  // If the user meant go to the live site, take them there
  if (confirm) {
    window.location.href = window.__configuration.liveSite;
  }
}

// Load the store
const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the main application component
root.render(
  <Root store={store} />
);
// render(
//   <Root store={store} />,
//   document.getElementById('root')
// );